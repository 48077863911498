import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useLanguageDeleteClient = () => {
    const japaneseContent = {
        title: 'クライアントの削除',
        description1: 'クライアント',
        description2: 'の削除を実行しようとしています。削除すると全てのデータが消去されます。実行しますか？',
        description3: '削除のために、以下に',
        description4: 'クライアント名を入力してください。',
        clientNameLabel: 'クライアント名',
        confirmLabel1: '削除のために、OKボタンを押して実行してください。',
        confirmLabel2: '確認のために、クライアント名を入力してください。',
        confirmButtonLabel: '削除',
    };
    const englishContent = {
        title: 'Deleting Client',
        description1: 'You are deleting client',
        description2: 'This process is not retrievable. Are you sure?',
        description3: 'To proceed, please type client name',
        description4: 'below to confim.',
        clientNameLabel: 'Client name',
        confirmLabel1: 'To proceed, click OK below',
        confirmLabel2: 'Please type client name to confirm deletion',
        confirmButtonLabel: 'OK',
    };
    const [content, setContent] = useState(japaneseContent);
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        if (customization.language == 'japanese') {
            setContent(japaneseContent);
        } else if (customization.language == 'english') {
            setContent(englishContent);
        }
    }, [customization]);

    return content;
};

export { useLanguageDeleteClient };
