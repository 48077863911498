import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useLanguageLeftSidebar = () => {
    const japaneseContent = {
        linearFreeTierBarTitle: '今月の情報提供リクエスト数',
        freeTierTitle: '無料枠'
    };
    const englishContent = {
        linearFreeTierBarTitle: 'Agreement requests in this month',
        freeTierTitle: 'Your Free Tier'
    };
    const [content, setContent] = useState(japaneseContent);
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        if (customization.language == 'japanese') {
            setContent(japaneseContent);
        } else if (customization.language == 'english') {
            setContent(englishContent);
        }
    }, [customization]);

    return content;
};

export { useLanguageLeftSidebar };
