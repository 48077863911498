import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Divider, Box, Grid, List, ListItem, Stack, Typography, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';

// project imports
import { gridSpacing } from 'store/constant';
import { useDispatch, useSelector } from 'react-redux';
import { basicsTemplate, redirectUriTemplate, agreementMethodTemplate, requestingInfoTemplate } from 'utils/label_utils';
import FormWrapper from 'views/create-client/FormWrapper';
import AuthCardWrapper from 'views/pages/authentication/AuthCardWrapper';
import { api } from 'utils/axios';

// ==============================|| DEFAULT DASHBOARD ||============================== //
// styles
import MainCard from 'ui-component/cards/MainCard';
import { REQUESTING_INFO_STATE } from 'utils/label_utils';
import RequestingInfoForm from 'views/create-client/RequestingInfoForm';
import { SET_LOADING, SET_NOT_LOADING, SET_CLIENT } from 'store/actions';
import { useLanguageCreateClient } from 'language/create_client';
import { useLanguageEditClient } from 'language/editClient';
const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${theme.palette.primary[200]} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${theme.palette.primary[200]} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

// ==============================|| DASHBOARD - TOTAL INCOME DARK CARD ||============================== //

const TitleCard = () => {
    const theme = useTheme();
    const client = useSelector((state) => state.client);

    const languageEditClient = useLanguageEditClient();

    return (
        <CardWrapper border={false} content={false}>
            <Box sx={{ p: 2 }}>
                <List sx={{ py: 0 }}>
                    <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                        <Grid container direction="column" justifyContent="center">
                            <Grid item sx={{ mb: 1.25, mt: 1 }}>
                                <Typography variant="h2">{languageEditClient.editRequestingInfoTitle}</Typography>
                            </Grid>
                            <Grid container justifyContent={'space-between'} direction="row" sx={{ mb: 1.25, mt: 1, pr: 2.5 }}>
                                <Grid item>
                                    <Typography
                                        sx={{
                                            fontSize: '0.85rem',
                                            fontWeight: 500,
                                            color: theme.palette.grey[500]
                                        }}
                                    >
                                        {languageEditClient.clientIdLabel}
                                    </Typography>
                                </Grid>
                                <Grid item ml={2}>
                                    <Typography
                                        sx={{
                                            fontSize: '0.85rem',
                                            fontWeight: 400,
                                            color: theme.palette.grey[500]
                                        }}
                                    >
                                        {client.client.client_id}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItem>
                </List>
            </Box>
        </CardWrapper>
    );
};

const EditRequestingInfo = () => {
    const [isLoading, setLoading] = useState(true);
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const client = useSelector((state) => state.client);
    const tenant = useSelector((state) => state.tenant);
    const [requestingInfo, setRequestingInfo] = useState(REQUESTING_INFO_STATE);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const languageEditClient = useLanguageEditClient();

    useEffect(() => {
        var res = {};
        for (let i = 0; i < requestingInfoTemplate.length; i++) {
            const template = requestingInfoTemplate[i];
            if (client.client.requesting_info[template['id_str']]) {
                res[template['id']] = true;
            } else {
                res[template['id']] = false;
            }
        }
        setRequestingInfo(res);
    }, [client]);

    useEffect(() => {
        setLoading(false);
    }, []);

    const handleSubmit = async () => {
        const send_requesting_info = {
            name: requestingInfo[0],
            sex: requestingInfo[1],
            address: requestingInfo[2],
            birthday: requestingInfo[3],
            age: requestingInfo[4],
            phone: requestingInfo[5],
            email: requestingInfo[6],
            picture: requestingInfo[7],
            driver_license: requestingInfo[8],
            location: requestingInfo[9]
        };

        const params = {
            tenant_id: tenant.tenant.id,
            client_id: client.client.client_id,
            requesting_info: send_requesting_info
        };
        dispatch({ type: SET_LOADING });
        const res = await api.post('/update_client/requesting_info', params);
        dispatch({ type: SET_CLIENT, client: res.result });
        dispatch({ type: SET_NOT_LOADING });
        navigate('/dashboard');
    };

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item>
                <TitleCard></TitleCard>
            </Grid>
            <Grid container spacing={gridSpacing} direction="row" justifyContent="center" alignItems="center" mt={0}>
                <FormWrapper>
                    <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '40vh' }}>
                        <AuthCardWrapper>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        direction={matchDownSM ? 'column-reverse' : 'row'}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item>
                                            <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                <Typography
                                                    variant="caption"
                                                    fontSize="14px"
                                                    textAlign={matchDownSM ? 'center' : 'inherit'}
                                                >
                                                    {languageEditClient.currentSettingTitle}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <RequestingInfoForm
                                        requestingInfoTemplate={requestingInfoTemplate}
                                        requestingInfo={requestingInfo}
                                        setRequestingInfo={setRequestingInfo}
                                        isFixed={true}
                                        isShowLabel={false}
                                    />
                                </Grid>
                            </Grid>
                        </AuthCardWrapper>
                    </Grid>
                </FormWrapper>
                <FormWrapper>
                    <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '40vh' }}>
                        <AuthCardWrapper>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        direction={matchDownSM ? 'column-reverse' : 'row'}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item>
                                            <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                <Typography
                                                    variant="caption"
                                                    fontSize="14px"
                                                    textAlign={matchDownSM ? 'center' : 'inherit'}
                                                >
                                                    {languageEditClient.changedSettingTitle}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <RequestingInfoForm
                                        requestingInfoTemplate={requestingInfoTemplate}
                                        requestingInfo={requestingInfo}
                                        setRequestingInfo={setRequestingInfo}
                                        isFixed={false}
                                        isShowLabel={false}
                                    />
                                </Grid>
                            </Grid>
                        </AuthCardWrapper>
                    </Grid>
                </FormWrapper>
            </Grid>
            <Grid container direction="column" alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        {languageEditClient.buttonLabel}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EditRequestingInfo;
