import { Fragment } from 'react';
import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import User1 from 'assets/images/users/user-round.svg';
import PaidIcon from '@mui/icons-material/Paid';

// assets
import { IconLogout, IconSearch, IconSettings, IconUser } from '@tabler/icons';
import TenantCard from './TenantCard';

import { api } from 'utils/axios';
import { SET_TENANT, SET_USER_RESET } from 'store/actions';

import { GoogleAuthProvider, signInWithPopup, signOut, getAuth } from 'firebase/auth';
import { useLanguageProfile } from 'language/profile';
import { useLanguageHeader } from 'language/header';

// ==============================|| PROFILE MENU ||============================== //

const BoxWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    //color: theme.palette.primary.main,
    border: '1px solid ',
    borderColor: theme.palette.primary.main,
    borderRadius: '10%',
    boxShadow: 2,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10
}));

const ButtonWrapper = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    //color: theme.palette.primary.main,
    border: '1px solid ',
    borderColor: theme.palette.primary.main,
    borderRadius: '10%',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10
}));

const TypographyWrapper = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    '&:hover': {
        color: 'white'
    }
}));

const checkIfCurrentUserIsOwner = (user, members) => {
    var flag = false;
    members.forEach((member) => {
        if (member.role == 'owner') {
            if (member.email == user.email) {
                flag = true;
            }
        }
    });
    return flag;
};

const TenantNameSection = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);

    const [open, setOpen] = useState(false);
    const tenant = useSelector((state) => state.tenant);
    const dispatch = useDispatch();

    const [deleteTenantConfirmationOpen, setDeleteTenantConfirmationOpen] = useState(false);
    const handleDeleteTenantConfirmationClose = () => setDeleteTenantConfirmationOpen(false);
    const deleteTenantClicked = () => {
        setDeleteTenantConfirmationOpen(true);
    };

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        navigate('/tenant');
    };

    return (
        <Chip
            sx={{
                paddingX: 2,
                mx: 1,
                height: '48px',
                alignItems: 'center',
                borderRadius: '27px',
                transition: 'all .2s ease-in-out',
                borderColor: theme.palette.primary.light,
                backgroundColor: theme.palette.primary.light,
                '&[aria-controls="menu-list-grow"], &:hover': {
                    borderColor: theme.palette.primary.main,
                    background: `${theme.palette.primary.main}!important`,
                    color: theme.palette.primary.light,
                    '& svg': {
                        stroke: theme.palette.primary.light
                    }
                },
                '& .MuiChip-label': {
                    lineHeight: 0
                }
            }}
            icon={
                <AccountBalanceIcon />
            }
            //label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
            label={<Typography>{tenant?.tenant.name}</Typography>}
            variant="outlined"
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="primary"
        />
    );
};

export default TenantNameSection;
