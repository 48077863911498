import { createStore } from 'redux';
import persistedReducer from './reducer';
import { persistReducer, persistStore } from 'redux-persist';

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(persistedReducer);
//const persister = 'Free';
const persistor = persistStore(store);

export { store, persistor };
