import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ClientName from './ClientName';
import AgreementMethod from './AgreementMethod';
import RequestingInfo from './RequestingInfo';
import CustomInfo from './CustomInfo';

// assets
import CreateClientSummaryWrapper from './CreateClientSummaryWrapper';
import RedirectUri from './RedirectUri';
import { api } from 'utils/axios';
import {
    basicsTemplate,
    redirectUriTemplate,
    agreementMethodTemplate,
    requestingInfoTemplate,
    contentTemplate,
    customInfoTemplate,
    BASICS_STATE,
    AGREEMENT_METHODS_STATE,
    REQUESTING_INFO_STATE,
    REDIRECT_URI_STATE,
    CONTENT_STATE,
    CUSTOM_INFO_STATE
} from 'utils/label_utils';
import Content from './Content';
import { SET_LOADING, SET_NOTIFICATIONS, SET_NOT_LOADING } from 'store/actions';
import { useLanguageCreateClient } from 'language/create_client';

const StepContent = ({ stepIndex, basicState, agreementMethodState, requestingInfoState, customInfoState, redirectUriState, contentState, nameError, setNameError, agreementMethodError, setAgreementMethodError, contentError, setContentError }) => {
    switch (stepIndex) {
        case 0:
            return <ClientName {...basicState} nameError={nameError} setNameError={setNameError} />;
        case 1:
            return <AgreementMethod {...agreementMethodState} agreementMethodError={agreementMethodError} setAgreementMethodError={setAgreementMethodError} />;
        case 2:
            return <RequestingInfo {...requestingInfoState} />;
        case 3:
            return <CustomInfo {...customInfoState} />;
        case 4:
            return <Content {...contentState} contentError={contentError} setContentError={setContentError} />;
        case 5:
            return <RedirectUri {...redirectUriState} />;
        default:
            return 'Unknown stepIndex';
    }
};

function CreateClient() {
    const [basics, setBasics] = useState(BASICS_STATE);
    const [agreementMethods, setAgreementMethods] = useState(AGREEMENT_METHODS_STATE);
    const [requestingInfo, setRequestingInfo] = useState(REQUESTING_INFO_STATE);
    const [redirectUris, setRedirectUris] = useState(REDIRECT_URI_STATE);
    const [content, setContent] = useState(CONTENT_STATE);
    const [nameError, setNameError] = useState('');
    const [contentError, setContentError] = useState('');
    const [agreementMethodError, setAgreementMethodError] = useState('');
    const [customInfo, setCustomInfo] = useState(CUSTOM_INFO_STATE)
    const tenant = useSelector((state) => state.tenant);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const languageCreateClient = useLanguageCreateClient();
    const getSteps = () => {
        return [
            languageCreateClient.stepTitle1,
            languageCreateClient.stepTitle2,
            languageCreateClient.stepTitle3,
            languageCreateClient.stepTitle4,
            languageCreateClient.stepTitle5,
            languageCreateClient.stepTitle6
        ];
    };

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
    };


    const handleSubmit = async () => {
        const send_agreement_method = {
            face_id: agreementMethods[0],
            // finger_print: agreementMethods[1],
            my_number_card: agreementMethods[1],
            signature: agreementMethods[2]
        };

        const send_requesting_info = {
            name: requestingInfo[0],
            sex: requestingInfo[1],
            address: requestingInfo[2],
            birthday: requestingInfo[3],
            age: requestingInfo[4],
            phone: requestingInfo[5],
            email: requestingInfo[6],
            picture: requestingInfo[7],
            driver_license: requestingInfo[8],
            location: requestingInfo[9]
        };

        const send_custom_info = {
            title: customInfo[0],
            detail: customInfo[1]
        }

        const send_content = { agreement: content[0] };

        const params = {
            tenant_id: tenant.tenant.id,
            client_name: basics[0],
            client_uri: basics[1],
            redirect_uris: redirectUris[0],
            agreement_method: send_agreement_method,
            requesting_info: send_requesting_info,
            custom_info: send_custom_info,
            content: send_content
        };
        console.log("params")
        console.log(params)
        dispatch({ type: SET_LOADING });
        const res = await api.post('/create_client_post', params);
        dispatch({ type: SET_NOT_LOADING });
        navigate('/tenant');
    };

    return (
        <Grid container>
            <Grid sm={2} />
            <Grid lg={8} sm={8} spacing={10} mt={6}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length ? (
                    <Grid mt={2}>
                        <CreateClientSummaryWrapper
                            basics={basics}
                            agreementMethods={agreementMethods}
                            requestingInfo={requestingInfo}
                            customInfo={customInfo}
                            redirectUris={redirectUris}
                            content={content}
                            basicsTemplate={basicsTemplate}
                            agreementMethodTemplate={agreementMethodTemplate}
                            requestingInfoTemplate={requestingInfoTemplate}
                            redirectUriTemplate={redirectUriTemplate}
                            contentTemplate={contentTemplate}
                            customInfoTemplate={customInfoTemplate}
                        />
                        <Grid container direction="column" alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <Button onClick={handleReset}>{languageCreateClient.modify}</Button>
                                <Button variant="contained" color="primary" onClick={handleSubmit}>
                                    {languageCreateClient.submit}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid mt={2}>
                        <StepContent
                            stepIndex={activeStep}
                            basicState={{ basicsTemplate, basics, setBasics }}
                            agreementMethodState={{ agreementMethodTemplate, agreementMethods, setAgreementMethods }}
                            requestingInfoState={{ requestingInfoTemplate, requestingInfo, setRequestingInfo }}
                            customInfoState={{ customInfoTemplate, customInfo, setCustomInfo }}
                            redirectUriState={{ redirectUriTemplate, redirectUris, setRedirectUris }}
                            contentState={{ contentTemplate, content, setContent }}
                            nameError={nameError}
                            setNameError={setNameError}
                            agreementMethodError={agreementMethodError}
                            setAgreementMethodError={setAgreementMethodError}
                            contentError={contentError}
                            setContentError={setContentError}
                        />
                        <Grid container direction="column" alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <Button disabled={activeStep === 0} onClick={handleBack}>
                                    {languageCreateClient.back}
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleNext} disabled={nameError != "" || agreementMethodError != ""}>
                                    {activeStep === steps.length ? languageCreateClient.submit : languageCreateClient.next}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
export default CreateClient;
