import { Fragment } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import * as React from 'react';

import PropTypes, { bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography,
    TextField
} from '@mui/material';

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PaidIcon from '@mui/icons-material/Paid';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { IconMenu2 } from '@tabler/icons';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Invitation from './Invitation';
import { api } from 'utils/axios';
import { capitalizeFirstLetter } from 'utils/label_utils';
import { SET_LOADING, SET_NOT_LOADING } from 'store/actions';
import { useLanguageHeader } from 'language/header';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { ButtonBase } from '@mui/material';
import MemberSection from './MemberSection';
import PlanSection from './PlanSection';
import TenantNameSection from './TenantNameSection';
import TenantManagementSection from './TenantManagementSection';
import ClientSection from './ClientSection';
import { plan_id_to_max_member_for_plan } from 'utils/plan_utils';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    borderRadius: '4%',
    border: 'none',
    boxShadow: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    p: 4
};

const BoxWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    //color: theme.palette.primary.main,
    border: '1px solid ',
    borderColor: theme.palette.primary.main,
    borderRadius: '10%',
    boxShadow: 2,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10
}));

const ButtonWrapper = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    //color: theme.palette.primary.main,
    border: '1px solid ',
    borderColor: theme.palette.primary.main,
    borderRadius: '10%',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10
}));

const TypographyWrapper = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    '&:hover': {
        color: 'white'
    }
}));

const checkIfCurrentUserIsOwner = (user, members) => {
    var flag = false;
    members.forEach((member) => {
        if (member.role == 'owner') {
            if (member.email == user.email) {
                flag = true;
            }
        }
    });
    return flag;
};

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle, isMenu, isSearchSection, isShowTenant }) => {
    const theme = useTheme();
    const tenant = useSelector((state) => state.tenant);
    const user = useSelector((state) => state.user);
    const [maxMemberForPlan, setMaxMemberForPlan] = useState(null);
    const [membersToShow, setMembersToShow] = useState([]);
    const [isMemberReachedMax, setIsMemberReachedMax] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [removeConfirmataionOpen, setRemoveConfirmationOpen] = useState(false);
    const [deleteTenantConfirmationOpen, setDeleteTenantConfirmationOpen] = useState(false);
    const [email, setEmail] = useState('');
    const handleClose = () => setOpen(false);
    const handleRemoveConfirmationClose = () => setRemoveConfirmationOpen(false);
    const handleDeleteTenantConfirmationClose = () => setDeleteTenantConfirmationOpen(false);
    const [tenantNameConfirmation, setTenantNameConfirmation] = useState('');
    const [isTenantNameConfirmationMatched, setIsTenantConfirmationMatched] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const languageHeader = useLanguageHeader();


    const handleInvitationSubmit = async () => {
        const params = {
            user_email: email,
            tenant_id: tenant.tenant.id
        };
        dispatch({ type: SET_LOADING });
        const res = await api.post('/create_invitation', params);
        dispatch({ type: SET_NOT_LOADING });
        handleClose();
    };

    const handleRemoveUserSubmit = async () => {
        const params = {
            user_id: user.id,
            tenant_id: tenant.tenant.id
        };
        dispatch({ type: SET_LOADING });
        const res = await api.post('/tenants/members/delete', params);
        dispatch({ type: SET_NOT_LOADING });
        handleRemoveConfirmationClose();
    };

    const handleDeleteTenantSubmit = async () => {
        const params = {
            tenant_id: tenant.tenant.id
        };
        dispatch({ type: SET_LOADING });
        const res = await api.post('/tenants/delete', params);
        dispatch({ type: SET_NOT_LOADING });
        setDeleteTenantConfirmationOpen(false);
    };

    const checkIfMemberExceedMax = () => {
        console.log("here**")
        console.log(tenant)
        if (tenant != undefined) {
        }
        if (tenant.members.length >= maxMemberForPlan) {
            setIsMemberReachedMax(true);
        }
    };

    useEffect(() => {
        if (tenantNameConfirmation == tenant.tenant.name) {
            setIsTenantConfirmationMatched(true);
        } else {
            setIsTenantConfirmationMatched(false);
        }
    }, [tenantNameConfirmation]);

    useEffect(() => {
        if (maxMemberForPlan != null) {
            checkIfMemberExceedMax();
        }
    }, [tenant.members, maxMemberForPlan]);

    useEffect(() => {
        if (tenant != null && tenant.tenant.id != "") {
            const max_member = plan_id_to_max_member_for_plan(tenant.tenant.plan)
            setMaxMemberForPlan(max_member)

            if (tenant.members) {
                setMembersToShow(tenant.members);
            }
        }
    }, [tenant]);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                {isMenu && (
                    <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            onClick={handleLeftDrawerToggle}
                            color="inherit"
                        >
                            <IconMenu2 stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ButtonBase>
                )}
            </Box>

            {/* header search */}
            {/* {isSearchSection && <SearchSection />} */}
            {isShowTenant && (
                <>
                    <TenantNameSection />
                    <PlanSection />
                    <TenantManagementSection />
                    <MemberSection />
                    <ClientSection />
                </>
            )}
            <Box sx={{ flexGrow: 1 }} />

            {/* notification & profile */}
            <NotificationSection />
            <ProfileSection />
            <Modal
                open={deleteTenantConfirmationOpen}
                onClose={handleDeleteTenantConfirmationClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500
                    }
                }}
            >
                <Fade in={deleteTenantConfirmationOpen}>
                    <Box sx={modalStyle}>
                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 330,
                                py: 0,
                                borderRadius: '10px',
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 300
                                },
                                '& .MuiListItemSecondaryAction-root': {
                                    top: 22
                                },
                                '& .MuiDivider-root': {
                                    my: 0
                                },
                                '& .list-container': {
                                    pl: 7
                                }
                            }}
                        >
                            <ListItemWrapper>
                                <ListItem alignItems="center">
                                    <ListItemAvatar>
                                        <Avatar
                                            sx={{
                                                color: theme.palette.primary.dark,
                                                backgroundColor: theme.palette.primary.light,
                                                border: 'none',
                                                borderColor: theme.palette.primary.main
                                            }}
                                        >
                                            <DeleteOutlineIcon stroke={1.5} size="1.3rem" />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={languageHeader.deletingTenant} />
                                </ListItem>
                                <Grid container direction="column" className="list-container">
                                    <Grid item xs={12} sx={{ pb: 2 }}>
                                        <Typography variant="subtitle2">{`${languageHeader.deletingTenantDetail1} ${tenant.tenant.name}. ${languageHeader.deletingTenantDetail2}`}</Typography>
                                        <Typography variant="subtitle2">{`${languageHeader.deletingTenantDetail3} ${tenant.tenant.name} ${languageHeader.deletingTenantDetail4}`}</Typography>
                                        <TextField
                                            fullWidth
                                            label={languageHeader.deletingTenantInputLabel}
                                            margin="normal"
                                            name="tenant_name"
                                            type="text"
                                            defaultValue=""
                                            placeholder={tenant.tenant.name}
                                            maxRows={4}
                                            value={tenantNameConfirmation}
                                            onChange={(e) => setTenantNameConfirmation(e.target.value)}
                                            sx={{ ...theme.typography.customInput, mt: 3 }}
                                            error={isTenantNameConfirmationMatched ? false : true}
                                            helperText={
                                                isTenantNameConfirmationMatched
                                                    ? languageHeader.deletingTenantConfirmation1
                                                    : languageHeader.deletingTenantConfirmation2
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </ListItemWrapper>
                            <Divider />
                        </List>
                        <Button sx={{ mt: 2 }} variant="contained" color="primary" onClick={handleDeleteTenantSubmit}>
                            {'OK'}
                        </Button>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500
                    }
                }}
            >
                <Fade in={open}>
                    <Box sx={modalStyle}>
                        <Invitation email={email} setEmail={setEmail} />
                        <Button sx={{ mt: 2 }} variant="contained" color="primary" onClick={handleInvitationSubmit}>
                            {languageHeader.inviteMemberSubmit}
                        </Button>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                open={removeConfirmataionOpen}
                onClose={handleRemoveConfirmationClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500
                    }
                }}
            >
                <Fade in={removeConfirmataionOpen}>
                    <Box sx={modalStyle}>
                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 330,
                                py: 0,
                                borderRadius: '10px',
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 300
                                },
                                '& .MuiListItemSecondaryAction-root': {
                                    top: 22
                                },
                                '& .MuiDivider-root': {
                                    my: 0
                                },
                                '& .list-container': {
                                    pl: 7
                                }
                            }}
                        >
                            <ListItemWrapper>
                                <ListItem alignItems="center">
                                    <ListItemAvatar>
                                        <Avatar
                                            sx={{
                                                color: theme.palette.primary.dark,
                                                backgroundColor: theme.palette.primary.light,
                                                border: 'none',
                                                borderColor: theme.palette.primary.main
                                            }}
                                        >
                                            <PersonRemoveIcon stroke={1.5} size="1.3rem" />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={languageHeader.removeUserItemText} />
                                </ListItem>
                                <Grid container direction="column" className="list-container">
                                    <Grid item xs={12} sx={{ pb: 2 }}>
                                        <Typography variant="subtitle2">{languageHeader.removeUserConfirmation}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemWrapper>
                            <Divider />
                        </List>
                        <Button sx={{ mt: 2 }} variant="contained" color="primary" onClick={() => { handleRemoveUserSubmit() }}>
                            {'OK'}
                        </Button>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func,
    isMenu: bool,
    isSearchSection: bool
};

export default Header;
