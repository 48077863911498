// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_LANGUAGE = '@customization/SET_LANGUAGE';
export const SET_POP_UP = '@customization/SET_POP_UP';
export const SET_NO_POP_UP = '@customization/SET_NO_POP_UP';

export const SET_UUID = '@user/SET_UUID';
export const SET_EMAIL = '@user/SET_EMAIL';
export const SET_NAME = '@user/SET_NAME';
export const SET_NOTIFICATIONS = '@user/SET_NOTIFICATIONS';
export const SET_USER_RESET = '@user/SET_USER_RESET';
export const SET_LOADING = '@loading/SET_LOADING';
export const SET_NOT_LOADING = '@loading/SET_NOT_LOADING';
export const SET_CLIENT = '@client/SET_CLIENT';
export const SET_CLIENTS = '@client/SET_CLIENTS';
export const SET_CLIENTS_RESET = '@client/SET_CLIENTS_RESET';
export const SET_CHART_DATA_TOTAL = '@chartData/SET_CHART_DATA_TOTAL';
export const SET_CHART_DATA_DETAIL_AGREEMENT = '@chartData/SET_CHART_DATA_DETAIL_AGREEMENT';
export const SET_CHART_DATA_DETAIL_SIGNUP = '@chartData/SET_CHART_DATA_DETAIL_SIGNUP';
export const SET_CHART_DATA_DETAIL_LOGIN = '@chartData/SET_CHART_DATA_DETAIL_LOGIN';
export const SET_TENANT = '@tenant/SET_TENANT';
export const SET_TENANT_RESET = '@tenant/SET_TENANT_RESET';
export const SET_TENANTS = '@tenant/SET_TENANTS';
export const SET_MEMBERS = '@tenant/SET_MEMBERS';
export const SET_LOG_ID = '@log/SET_LOG_ID';
export const SET_SHOW_LOG = '@log/SET_SHOW_LOG';
export const SET_NOT_SHOW_LOG = '@log/SET_NOT_SHOW_LOG';