// ==============================|| DEFAULT DASHBOARD ||============================== //
// styles
import MainCard from 'ui-component/cards/MainCard';
import ContentForm from 'views/create-client/ContentForm';
import { useLanguageApiManagementDashboard } from 'language/apiManagementDashboard';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Divider, Box, Grid, List, ListItem, Stack, Typography, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

// project imports
import { useDispatch, useSelector } from 'react-redux';
import { basicsTemplate, redirectUriTemplate, agreementMethodTemplate, requestingInfoTemplate, contentTemplate } from 'utils/label_utils';
import { CONTENT_STATE } from 'utils/label_utils';
import { api } from 'utils/axios';
import { SET_LOADING, SET_NOT_LOADING, SET_CLIENT } from 'store/actions';

// assets
import { IconTrash } from '@tabler/icons';
import { ConstructionOutlined } from '@mui/icons-material';

// constant
const icons = { IconTrash };

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    height: 400,
    bgcolor: 'background.paper',
    borderRadius: '4%',
    border: 'none',
    boxShadow: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    p: 4
};

const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${theme.palette.primary[200]} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${theme.palette.primary[200]} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

// ==============================|| DASHBOARD - TOTAL INCOME DARK CARD ||============================== //

const TitleCard = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const client = useSelector((state) => state.client);
    const tenant = useSelector((state) => state.tenant);

    const languageApiManagementDashboard = useLanguageApiManagementDashboard();

    return (
        <CardWrapper border={false} content={false}>
            <Box sx={{ p: 2 }}>
                <List sx={{ py: 0 }}>
                    <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                        <Grid container direction="column" justifyContent="center">
                            <Grid item sx={{ mb: 1.25, mt: 1 }}>
                                <Typography variant="h2">{languageApiManagementDashboard.title}</Typography>
                            </Grid>
                            <Grid container justifyContent={'space-between'} direction="row" sx={{ mb: 1.25, mt: 1, pr: 2.5 }}>
                                <Grid item>
                                    <Typography
                                        sx={{
                                            fontSize: '0.85rem',
                                            fontWeight: 500,
                                            color: theme.palette.grey[500]
                                        }}
                                    >
                                        {languageApiManagementDashboard.tenantIdLabel}
                                    </Typography>
                                </Grid>
                                <Grid item ml={2}>
                                    <Typography
                                        sx={{
                                            fontSize: '0.85rem',
                                            fontWeight: 400,
                                            color: theme.palette.grey[500]
                                        }}
                                    >
                                        {tenant.tenant.id}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={'space-between'} direction="row" sx={{ mb: 1.25, mt: 1, pr: 2.5 }}>
                                <Grid item>
                                    <Typography
                                        sx={{
                                            fontSize: '0.85rem',
                                            fontWeight: 500,
                                            color: theme.palette.grey[500]
                                        }}
                                    >
                                        {languageApiManagementDashboard.clientIdLabel}
                                    </Typography>
                                </Grid>
                                <Grid item ml={2}>
                                    <Typography
                                        sx={{
                                            fontSize: '0.85rem',
                                            fontWeight: 400,
                                            color: theme.palette.grey[500]
                                        }}
                                    >
                                        {client.client.client_id}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={'space-between'} direction="row" sx={{ mb: 1.25, mt: 1, pr: 2.5 }}>
                                <Grid item>
                                    <Typography
                                        sx={{
                                            fontSize: '0.85rem',
                                            fontWeight: 500,
                                            color: theme.palette.grey[500]
                                        }}
                                    >
                                        {languageApiManagementDashboard.apiUrlLabel}
                                    </Typography>
                                </Grid>
                                <Grid item ml={2}>
                                    <Typography
                                        sx={{
                                            fontSize: '0.85rem',
                                            fontWeight: 400,
                                            color: theme.palette.grey[500]
                                        }}
                                    >
                                        {process.env.REACT_APP_BACKEND_END_POINT}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItem>
                </List>
            </Box>
        </CardWrapper>
    );
};

const APIManagementDashboard = () => {
    const [isLoading, setLoading] = useState(true);
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const client = useSelector((state) => state.client);
    const tenant = useSelector((state) => state.tenant);
    const [currentToken, setCurrentToken] = useState(null);
    const [expirationDate, setExpirationDate] = useState(null);

    const [confirmataionOpen, setConfirmationOpen] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const languageApiManagementDashboard = useLanguageApiManagementDashboard();


    useEffect(() => {
        (async () => {
            dispatch({ type: SET_LOADING });
            const res = await api.get('/api/token');
            setCurrentToken(res.result?.api_token)
            var date = new Date(res.result.expiration_date * 1000)
            setExpirationDate(date.toString())
            dispatch({ type: SET_NOT_LOADING });
        })()
    }, []);

    const handleIssue = async () => {

        dispatch({ type: SET_LOADING });
        const res = await api.get('/api/create_token');
        setCurrentToken(res.result?.api_token)
        var date = new Date(res.result.expiration_date * 1000)
        setExpirationDate(date.toString())
        dispatch({ type: SET_NOT_LOADING });
    }

    const handleRemove = async () => {

        dispatch({ type: SET_LOADING });
        const res = await api.delete('/api/token');
        if (res.result.response == true) {
            setCurrentToken(null)
            setExpirationDate(null)
        } else {

        }
        dispatch({ type: SET_NOT_LOADING });
        setConfirmationOpen(false)
    }

    // const handleSubmit = async () => {
    //     const send_content = { agreement: content[0] };

    //     const params = {
    //         tenant_id: tenant.tenant.id,
    //         client_id: client.client.client_id,
    //         content: send_content
    //     };
    //     dispatch({ type: SET_LOADING });
    //     const res = await api.post('/update_client/content', params);
    //     dispatch({ type: SET_CLIENT, client: res.result });
    //     dispatch({ type: SET_NOT_LOADING });
    //     navigate('/dashboard');
    // };

    return (
        <>
            <Modal
                open={confirmataionOpen}
                onClose={() => { setConfirmationOpen(false) }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500
                    }
                }}
            >
                <Fade in={confirmataionOpen}>
                    <Box sx={modalStyle}>
                        <Typography variant="subtitle2">{languageApiManagementDashboard.removeConfirmation}</Typography>
                        <Button sx={{ mt: 2 }} variant="contained" color="primary" onClick={() => { handleRemove() }}>
                            {'OK'}
                        </Button>
                    </Box>
                </Fade>
            </Modal>

            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={6} sx={{ mt: 2 }}>
                    <TitleCard></TitleCard>
                </Grid>

                <Grid item xs={10} sx={{ mt: 4 }}>

                    <Stack alignItems="start" justifyContent="center" spacing={1}>
                        <Typography variant="h5"
                            fontSize="14px"
                            textAlign={matchDownSM ? 'center' : 'inherit'}
                        >
                            {languageApiManagementDashboard.currentTokenTitle}
                        </Typography>
                        <Grid container alignItems={"center"}>
                            <Typography
                                variant="h4"
                                fontSize="14px"
                                textAlign={matchDownSM ? 'center' : 'inherit'}
                            >
                                {currentToken ? currentToken : "Not issued"}
                            </Typography>

                        </Grid>
                    </Stack>
                    <Stack sx={{ mt: 2 }} alignItems="start" justifyContent="center" spacing={1}>
                        <Typography variant="h5"
                            fontSize="14px"
                            textAlign={matchDownSM ? 'center' : 'inherit'}
                        >
                            {languageApiManagementDashboard.tokenExpireDateTitle}
                        </Typography>
                        <Grid container alignItems={"center"}>
                            <Typography
                                variant="h4"
                                fontSize="14px"
                                textAlign={matchDownSM ? 'center' : 'inherit'}
                            >
                                {expirationDate ? expirationDate : "Not issued"}
                            </Typography>
                        </Grid>
                    </Stack>
                    <Stack sx={{ mt: 4 }} alignItems="start" justifyContent="center" spacing={1}>
                        <Typography
                            variant="caption"
                            fontSize="14px"
                            textAlign={matchDownSM ? 'center' : 'inherit'}
                        >
                            {languageApiManagementDashboard.apiDetail}
                        </Typography>
                    </Stack>
                </Grid>
                {!currentToken ?
                    <Grid item xs={4} sx={{ mt: 4 }}>
                        <Button variant="contained" color="primary" onClick={() => { handleIssue() }}>
                            {languageApiManagementDashboard.issueTitle}
                        </Button>
                    </Grid>
                    :
                    <Grid item xs={4} sx={{ mt: 4 }}>
                        <Button variant="contained" color="primary" onClick={() => { setConfirmationOpen(true) }}>
                            {languageApiManagementDashboard.deleteTitle}
                        </Button>
                    </Grid>
                }

                {/* <Grid item xs={10} sx={{ mt: 4 }}>
                <Stack sx={{ mt: 4 }} alignItems="start" justifyContent="center" spacing={1}>
                    <Typography
                        variant="caption"
                        fontSize="14px"
                        textAlign={matchDownSM ? 'center' : 'inherit'}
                    >
                        {languageEditClient.changedSettingTitle}
                    </Typography>
                </Stack>
                <ContentForm
                    contentTemplate={contentTemplate}
                    content={content}
                    setContent={setContent}
                    isFixed={false}
                    isShowLabel={false}
                    contentError={contentError}
                    setContentError={setContentError}
                />

            </Grid> */}

                {/* <Grid item xs={4} sx={{ mt: 4 }}>
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={contentError != ""}>
                    {languageEditClient.buttonLabel}
                </Button>
            </Grid> */}
            </Grid >
        </>
    );
};

export default APIManagementDashboard;
