import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, Grid, Menu, MenuItem, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonClientCard from 'ui-component/cards/Skeleton/ClientCard';

// assets
import EarningIcon from 'assets/images/icons/earning.svg';
import QrCodeIcon from '@mui/icons-material/QrCode';
import WcIcon from '@mui/icons-material/Wc';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CakeIcon from '@mui/icons-material/Cake';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

import FaceIcon from '@mui/icons-material/Face';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import BadgeIcon from '@mui/icons-material/Badge';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import GetAppTwoToneIcon from '@mui/icons-material/GetAppOutlined';
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyOutlined';
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveOutlined';

import {
    basicsTemplate,
    redirectUriTemplate,
    agreementMethodTemplate,
    requestingInfoTemplate,
    getAgreementMethodByIdStr,
    getRequestingInfoByIdStr,
    getLabel
} from 'utils/label_utils';
import { useEffect } from 'react';
import { useLanguageTenantTop } from 'language/tenantTop';
import { Language } from '@mui/icons-material';

const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: theme.palette.light,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: theme.palette.primary[800],
        borderRadius: '50%',
        top: -85,
        right: -95,
        opacity: 0.4,
        [theme.breakpoints.down('sm')]: {
            top: -105,
            right: -140
        }
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: theme.palette.primary[800],
        borderRadius: '50%',
        top: -125,
        right: -15,
        opacity: 0.2,
        [theme.breakpoints.down('sm')]: {
            top: -155,
            right: -70
        }
    }
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //
const ClientCard = ({ client, isLoading }) => {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);
    const language = useLanguageTenantTop();
    const customization = useSelector((state) => state.customization);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {isLoading ? (
                <SkeletonClientCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ p: 2.25 }}>
                        <Grid container direction="column">
                            <Grid item>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                ...theme.typography.commonAvatar,
                                                ...theme.typography.mediumAvatar,
                                                backgroundColor: theme.palette.secondary.dark,
                                                color: theme.palette.primary.light,
                                                zIndex: 1
                                            }}
                                            aria-controls="menu-earning-card"
                                            aria-haspopup="true"
                                            onClick={handleClick}
                                        >
                                            <QrCodeIcon fontSize="inherit" />
                                        </Avatar>
                                        <Menu
                                            id="menu-earning-card"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                            variant="selectedMenu"
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right'
                                            }}
                                        >
                                            <MenuItem onClick={handleClose}>
                                                <GetAppTwoToneIcon sx={{ mr: 1.75 }} /> Import Card
                                            </MenuItem>
                                            <MenuItem onClick={handleClose}>
                                                <FileCopyTwoToneIcon sx={{ mr: 1.75 }} /> Copy Data
                                            </MenuItem>
                                            <MenuItem onClick={handleClose}>
                                                <PictureAsPdfTwoToneIcon sx={{ mr: 1.75 }} /> Export
                                            </MenuItem>
                                            <MenuItem onClick={handleClose}>
                                                <ArchiveTwoToneIcon sx={{ mr: 1.75 }} /> Archive File
                                            </MenuItem>
                                        </Menu>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Typography
                                            sx={{
                                                fontSize: '1.125rem',
                                                fontWeight: 500,
                                                color: theme.palette.grey[900],
                                                mr: 1,
                                                mt: 2.75,
                                                mb: 0.75
                                            }}
                                        >
                                            {client.client_name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction="column">
                                <Grid container direction="column">
                                    <Grid item sx={{ mb: 1.25, mt: 4 }}>
                                        <Typography
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: 500,
                                                color: theme.palette.grey[500]
                                            }}
                                        >
                                            {language.clientCardContentTitle}
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{ mb: 1.25, mt: 1, ml: 1 }}>
                                        <Typography
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: 400,
                                                color: theme.palette.grey[500],
                                                maxHeight: '120px',
                                                overflow: 'scroll'
                                            }}
                                        >
                                            {client.content.agreement}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction="column">
                                    <Grid item sx={{ mb: 1.25, mt: 4 }}>
                                        <Typography
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: 500,
                                                color: theme.palette.grey[500]
                                            }}
                                        >
                                            {language.clientCardAgreementMethodTitle}
                                        </Typography>
                                    </Grid>
                                    {Object.keys(client.agreement_method).map((key) => {
                                        if (client.agreement_method[key]) {
                                            const tmp = getAgreementMethodByIdStr(key);
                                            return getLabel(theme, tmp, customization.language);
                                        }
                                    })}
                                </Grid>
                                {/* <Grid container direction="column">
                                    <Grid item sx={{ mb: 1.25, mt: 4 }}>
                                        <Typography
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: 500,
                                                color: theme.palette.grey[500]
                                            }}
                                        >
                                            Request Info
                                        </Typography>
                                    </Grid>
                                    {Object.keys(client.requesting_info).map((key) => {
                                        if (client.requesting_info[key]) {
                                            const tmp = getRequestingInfoByIdStr(key);
                                            return getLabel(theme, tmp.name, tmp.icon);
                                        }
                                    })}
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

ClientCard.propTypes = {
    isLoading: PropTypes.bool
};

export default ClientCard;
