import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useLanguageNotification = () => {
    const japaneseContent = {
        title: '全ての通知',
        deleteTitle: '通知の消去',
        invitationTitle: 'テナントへの招待',
        userJoinTitle: 'ユーザの参加',
        userDeclineTitle: 'ユーザの不参加',
        invitationAccept: '参加する',
        invitationDecline: '拒否する',
    };
    const englishContent = {
        title: 'All Notification',
        deleteTitle: 'Delete notifications',
        invitationTitle: 'Invitation',
        userJoinTitle: 'User joined',
        userDeclineTitle: 'User declined',
        invitationAccept: 'Accept',
        invitationDecline: 'Decline',
    };
    const [content, setContent] = useState(japaneseContent);
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        if (customization.language == 'japanese') {
            setContent(japaneseContent);
        } else if (customization.language == 'english') {
            setContent(englishContent);
        }
    }, [customization]);

    return content;
};

export { useLanguageNotification };
