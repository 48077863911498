import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third party
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import { useLanguageCreateClient } from 'language/create_client';
import SubCard from 'ui-component/cards/SubCard';
import MainCard from 'ui-component/cards/MainCard';
import { getLabel } from 'utils/label_utils';

const CreateClientSummary = ({
    basics,
    agreementMethods,
    requestingInfo,
    customInfo,
    redirectUris,
    content,
    basicsTemplate,
    agreementMethodTemplate,
    requestingInfoTemplate,
    redirectUriTemplate,
    contentTemplate,
    customInfoTemplate,
}) => {
    const theme = useTheme();
    const languageCreateClient = useLanguageCreateClient();

    const customization = useSelector((state) => state.customization);

    const getNameBasedOnLanguage = (element) => {
        if (customization.language == 'japanese') {
            return element.nameJp;
        } else if (customization.language == 'english') {
            return element.name;
        } else {

        }
    }

    return (
        <>
            <Grid container direction="column" justifyContent="center">
                <Grid item sx={{ mb: 1.25, ml: 1.25 }}>
                    {/* <Grid item sx={{ mb: 2 }}>
                        <Typography variant="h3" gutterBottom>
                            {languageCreateClient.summaryBasicInfoTitle}
                        </Typography>
                    </Grid> */}
                    <MainCard sx={{ my: 1 }} title={languageCreateClient.summaryBasicInfoTitle}>
                        {Object.keys(basics).map((basicsKey) => {
                            return (
                                <Grid container direction="column" justifyContent="center">
                                    <Grid item>
                                        <Grid container direction="row">
                                            {basicsTemplate.map((templateElement) => {
                                                if (templateElement.id == basicsKey) {
                                                    return (
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1">
                                                                {getNameBasedOnLanguage(templateElement)}
                                                            </Typography>
                                                        </Grid>
                                                    );
                                                }
                                            })}
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1">
                                                    {basics[basicsKey]}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </MainCard>
                    <MainCard sx={{ my: 1 }} title={languageCreateClient.summaryAgreementMethodTitle}>
                        {Object.keys(agreementMethods).map((key) => {
                            return (
                                <Grid container direction="column" justifyContent="center" spacing={2}>
                                    <Grid item>
                                        <Grid container direction="row" spacing={2}>
                                            {agreementMethodTemplate.map((templateElement) => {
                                                if (templateElement.id == key) {
                                                    if (agreementMethods[key]) {
                                                        return (
                                                            <Grid item xs={12}>
                                                                {getLabel(theme, templateElement, customization.language)}
                                                            </Grid>
                                                        );
                                                    }
                                                }
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </MainCard>

                    <MainCard sx={{ my: 1 }} title={languageCreateClient.summaryRequestingInfoTitle}>
                        {Object.keys(requestingInfo).map((key) => {
                            return (
                                <Grid container direction="column" justifyContent="center" spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" spacing={2}>
                                            {requestingInfoTemplate.map((templateElement) => {
                                                if (templateElement.id == key) {
                                                    if (requestingInfo[key]) {
                                                        return (
                                                            <Grid item xs={12}>
                                                                {getLabel(theme, templateElement, customization.language)}
                                                            </Grid>
                                                        );
                                                    }
                                                }
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </MainCard>

                    <MainCard sx={{ my: 1 }} title={languageCreateClient.summaryCustomInfoTitle}>
                        {Object.keys(customInfo).map((key) => {
                            return (
                                <Grid container direction="column" justifyContent="center">
                                    <Grid item>
                                        <Grid container direction="row">
                                            {customInfoTemplate.map((templateElement) => {
                                                if (templateElement.id == key) {
                                                    return (
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1">
                                                                {getNameBasedOnLanguage(templateElement)}
                                                            </Typography>
                                                        </Grid>
                                                    );
                                                }
                                            })}
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1">
                                                    {customInfo[key]}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </MainCard>

                    <MainCard sx={{ my: 1 }} title={languageCreateClient.summaryContentTitle}>
                        {Object.keys(content).map((key) => {
                            return (
                                <Grid container direction="column" justifyContent="center" spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" spacing={2}>
                                            {contentTemplate.map((templateElement) => {
                                                if (templateElement.id == key) {
                                                    return (
                                                        <Grid item xs={12}>
                                                            <Typography Typography variant="subtitle1">
                                                                {getNameBasedOnLanguage(templateElement)}
                                                            </Typography>
                                                        </Grid>
                                                    );
                                                }
                                            })}
                                            <Grid item xs={4}>
                                                <Typography Typography variant="subtitle1">
                                                    {content[key]}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </MainCard>

                    <MainCard title={languageCreateClient.summaryRedirectUriTitle}>
                        {Object.keys(redirectUris).map((key) => {
                            return (
                                <Grid container direction="column" justifyContent="center" spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" spacing={2}>
                                            {redirectUriTemplate.map((templateElement) => {
                                                if (templateElement.id == key) {
                                                    return (
                                                        <Grid item xs={12}>
                                                            <Typography Typography variant="subtitle1">
                                                                {getNameBasedOnLanguage(templateElement)}
                                                            </Typography>
                                                        </Grid>
                                                    );
                                                }
                                            })}
                                            <Grid item xs={4}>
                                                <Typography Typography variant="subtitle1">
                                                    {redirectUris[key]}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </MainCard>
                </Grid >
            </Grid >
        </>
    );
};

export default CreateClientSummary;
