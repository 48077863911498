// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';

var chartDataDetail = {
    type: 'area',
    height: 95,
    options: {
        chart: {
            id: 'support-chart',
            sparkline: {
                enabled: true
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 1
        },
        tooltip: {
            fixed: {
                enabled: false
            },
            x: {
                show: false
            },
            y: {
                title: 'Ticket '
            },
            marker: {
                show: false
            }
        }
    },
    series: [
        {
            data: [0]
        }
    ]
};

var chartDataTotal = {
    height: 480,
    type: 'bar',
    options: {
        chart: {
            id: 'bar-chart',
            stacked: true,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%'
            }
        },
        xaxis: {
            type: 'category',
            // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            categories: ['default']
        },
        legend: {
            show: true,
            fontSize: '14px',
            fontFamily: `'Roboto', sans-serif`,
            position: 'bottom',
            offsetX: 20,
            labels: {
                useSeriesColors: false
            },
            markers: {
                width: 16,
                height: 16,
                radius: 5
            },
            itemMargin: {
                horizontal: 15,
                vertical: 8
            }
        },
        fill: {
            type: 'solid'
        },
        dataLabels: {
            enabled: false
        },
        grid: {
            show: true
        }
    },
    series: [
        {
            name: 'Agreement',
            data: [0]
        }
        // {
        //     name: 'Signup',
        //     data: [0]
        // },
        // {
        //     name: 'Login',
        //     data: [0]
        // }
        // {
        //     name: 'Maintenance',
        //     data: [0, 0, 75, 0, 0, 115, 0, 0, 0, 0, 150, 0]
        // }
    ]
};

export const initialState = {
    chartDataTotal: chartDataTotal,
    chartDataDetailAgreement: chartDataDetail,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const chartDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CHART_DATA_TOTAL:
            return {
                ...state,
                chartDataTotal: action.chartDataTotal
            };
        case actionTypes.SET_CHART_DATA_DETAIL_AGREEMENT:
            return {
                ...state,
                chartDataDetailAgreement: action.chartDataDetailAgreement
            };
        default:
            return state;
    }
};

export default chartDataReducer;
