import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import AuthCardWrapper from '../pages/authentication/AuthCardWrapper';
import FormWrapper from './FormWrapper';
import TenantInfoForm from './TenantInfoForm';
import { useLanguageCreateTenant } from 'language/create_tenant';

const TenantInfo = ({ name, description, setName, setDescription, nameError, setNameError }) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const languageCreateTenant = useLanguageCreateTenant();

    return (
        <FormWrapper>
            <Grid container direction="column" justifyContent="flex-start" sx={{ minHeight: '40vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(40vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant={matchDownSM ? 'h3' : 'h2'}
                                                    >
                                                        {languageCreateTenant.nameTitle}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        fontSize="12px"
                                                        textAlign={matchDownSM ? 'center' : 'inherit'}
                                                    >
                                                        {languageCreateTenant.nameSubTitle}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TenantInfoForm
                                            name={name}
                                            description={description}
                                            setName={setName}
                                            setDescription={setDescription}
                                            nameError={nameError}
                                            setNameError={setNameError}
                                        />
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FormWrapper>
    );
};

export default TenantInfo;
