import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third party
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import { useLanguageCreateTenant } from 'language/create_tenant';

const CreateTenantSummary = ({ name, description, plan }) => {
    const theme = useTheme();

    const languageCreateTenant = useLanguageCreateTenant();

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item sx={{ mb: 1.25, ml: 1.25 }}>
                    <Grid item>
                        <Typography variant="h3" gutterBottom>
                            {languageCreateTenant.summaryTenantNameTitle}
                        </Typography>
                    </Grid>
                    <Grid container direction="column" justifyContent="center" spacing={2}>
                        <Grid item xs={12}>
                            <Typography Typography variant="subtitle1">
                                {name}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{ mb: 1.25, ml: 1.25 }}>
                    <Grid item>
                        <Typography variant="h3" gutterBottom>
                            {languageCreateTenant.summaryTenantDescriptionTitle}
                        </Typography>
                    </Grid>
                    <Grid container direction="column" justifyContent="center" spacing={2}>
                        <Grid item xs={12}>
                            <Typography Typography variant="subtitle1">
                                {description}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item sx={{ mb: 1.25, ml: 1.25 }}>
                    <Grid item>
                        <Typography variant="h3" gutterBottom>
                            {languageCreateTenant.summaryTenantPlanTitle}
                        </Typography>
                    </Grid>
                    <Grid container direction="column" justifyContent="center" spacing={2}>
                        <Grid item xs={12}>
                            <Typography Typography variant="subtitle1">
                                {plan}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>
        </>
    );
};

export default CreateTenantSummary;
