import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, Grid, Menu, MenuItem, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonClientCard from 'ui-component/cards/Skeleton/ClientCard';

// assets
import QrCodeIcon from '@mui/icons-material/QrCode';
import AddIcon from '@mui/icons-material/Add';
import GetAppTwoToneIcon from '@mui/icons-material/GetAppOutlined';
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyOutlined';
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveOutlined';

const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: theme.palette.light,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //
const AddClientCard = ({ isLoading, isDisable }) => {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {isLoading ? (
                <SkeletonClientCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ p: 2.25 }}>
                        <Grid container>
                            <Grid item>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        ...theme.typography.commonAvatar,
                                        ...theme.typography.mediumAvatar,
                                        backgroundColor: theme.palette.white,
                                        color: theme.palette.primary.light,
                                        zIndex: 1
                                    }}
                                    aria-controls="menu-earning-card"
                                    aria-haspopup="true"
                                >
                                    {isDisable ? (
                                        <AddIcon fontSize="inherit" style={{ cursor: 'not-allowed' }} />

                                    ) : (

                                        <AddIcon fontSize="inherit" />
                                    )}
                                </Avatar>
                            </Grid>
                        </Grid>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

AddClientCard.propTypes = {
    isLoading: PropTypes.bool
};

export default AddClientCard;
