// react
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled } from '@mui/material/styles';
import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import { api } from 'utils/axios';
import { SET_LOADING, SET_NOT_LOADING, SET_TENANT, SET_TENANTS } from 'store/actions';

// styles
const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.warning.light,
    marginTop: '16px',
    marginBottom: '16px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '19px solid ',
        borderColor: theme.palette.warning.main,
        borderRadius: '50%',
        top: '65px',
        right: '-150px'
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '3px solid ',
        borderColor: theme.palette.warning.main,
        borderRadius: '50%',
        top: '145px',
        right: '-70px'
    }
}));

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const TenantCard = ({ tenantId, tenantName, tenantPlan }) => {
    const tenant = useSelector((state) => state.tenant);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    return (
        <CardStyle>
            <CardContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Typography variant="h4">{tenantName}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography sx={{ ml: 2 }} variant="subtitle2">
                                    {tenantId}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle" color="grey.900" sx={{ opacity: 0.6 }}>
                            Current Plan
                        </Typography>
                        <Typography variant="subtitle2" color="grey.900" sx={{ opacity: 0.6 }}>
                            {tenantPlan}
                        </Typography>
                    </Grid>
                    {/* <Grid item>
                        <Stack direction="row">
                            <AnimateButton>
                                <Button onClick={() => callback()} variant="contained" color="warning" sx={{ boxShadow: 'none' }}>
                                    Go to tenant
                                </Button>
                            </AnimateButton>
                        </Stack>
                    </Grid> */}
                </Grid>
            </CardContent>
        </CardStyle>
    );
};

export default TenantCard;
