import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';

const RedirectUriForm = ({ redirectUriTemplate, redirectUris, setRedirectUris, isFixed = false }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const customization = useSelector((state) => state.customization);
    const [language, setLanguage] = useState(customization.language);

    const client = useSelector((state) => state.client);
    const [fixedClient, setFixedClient] = useState(client);

    useEffect(() => {
        setFixedClient(client);
    }, [client]);

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ alignItems: 'center', display: 'flex' }}></Box>
                </Grid>
            </Grid>

            <Formik initialValues={redirectUris}>
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={10} sm={10} md={10} ml={3}>
                                {redirectUriTemplate.map((option) => {
                                    return (
                                        <TextField
                                            fullWidth
                                            label={language == 'japanese' ? option.nameJp : option.name}
                                            margin="normal"
                                            name={option.name}
                                            type="text"
                                            defaultValue=""
                                            value={isFixed ? fixedClient.client.redirect_uris : redirectUris[option.id]}
                                            disabled={isFixed ? true : false}
                                            //value={values.clientName}
                                            // onChange={(e) => setClientName(e.target.value)}
                                            onChange={(e) =>
                                                setRedirectUris((state) => {
                                                    return {
                                                        ...state,
                                                        [option.id]: e.target.value
                                                    };
                                                })
                                            }
                                            sx={{ ...theme.typography.customInput }}
                                        />
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default RedirectUriForm;
