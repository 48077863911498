import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useLanguagePayment = () => {
    const japaneseContent = {
        noPlanSetWarning: 'テナントにプランが設定されていません。',
        noPlanSetWarning2: '機能をお使いいただくためにプランを設定してください。'
    };
    const englishContent = {
        noPlanSetWarning: 'No plan set for the tenant',
        noPlanSetWarning2: 'Please choose plan to proceed'
    };
    const [content, setContent] = useState(japaneseContent);
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        if (customization.language == 'japanese') {
            setContent(japaneseContent);
        } else if (customization.language == 'english') {
            setContent(englishContent);
        }
    }, [customization]);

    return content;
};

export { useLanguagePayment };
