import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import { api } from 'utils/axios';
import { SET_LOADING, SET_NOTIFICATIONS, SET_NOT_LOADING, SET_TENANT, SET_TENANTS } from 'store/actions';
import { useNavigate } from 'react-router';

import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from 'react-time-ago';

import ja from 'javascript-time-ago/locale/ja.json';
import { useLanguageNotification } from 'language/notification';

TimeAgo.addDefaultLocale(ja);
//TimeAgo.addLocale(ja);


// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = () => {
    const theme = useTheme();
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const languageNotification = useLanguageNotification();
    const customization = useSelector((state) => state.customization);

    const handleAccept = async (notification) => {
        const params = { notification_id: notification.id };
        dispatch({ type: SET_LOADING });
        const data = await api.post('/invitations/accept/notification', params);
        const res_notifications = await api.get('/notifications');
        const res_tenants = await api.get('/tenants/joined');
        dispatch({ type: SET_TENANTS, tenants: res_tenants.result });
        dispatch({ type: SET_NOTIFICATIONS, notifications: res_notifications.result });
        dispatch({ type: SET_NOT_LOADING });
        navigate('/top');
    };

    const handleDecline = async (notification) => {
        const params = { notification_id: notification.id };
        dispatch({ type: SET_LOADING });
        const data = await api.post('/invitations/decline/notification', params);
        const res_notifications = await api.get('/notifications');
        dispatch({ type: SET_NOTIFICATIONS, notifications: res_notifications.result });
        dispatch({ type: SET_NOT_LOADING });
        navigate('/top');
    };

    const chipSX = {
        height: 24,
        padding: '0 4px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light,
        marginRight: '5px'
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
        marginRight: '5px'
    };

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {user?.notifications.map((notification) => (
                <>
                    <ListItemWrapper>
                        <ListItem alignItems="center">
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        color: theme.palette.primary.dark,
                                        backgroundColor: theme.palette.primary.light,
                                        border: 'none',
                                        borderColor: theme.palette.primary.main
                                    }}
                                >
                                    <IconMailbox stroke={1.5} size="1.3rem" />
                                </Avatar>
                            </ListItemAvatar>
                            {(() => {
                                if (notification.type == 'invitation') {
                                    return (
                                        <ListItemText primary={languageNotification.invitationTitle} />
                                    )
                                } else if (notification.type == 'user_join') {
                                    return (
                                        <ListItemText primary={languageNotification.userJoinTitle} />
                                    )
                                } else if (notification.type == 'user_decline') {
                                    return (
                                        <ListItemText primary={languageNotification.userDeclineTitle} />
                                    )
                                }

                            })()}
                            <ListItemSecondaryAction>
                                <Grid container justifyContent="flex-end">
                                    <Grid item xs={12}>
                                        <Typography variant="caption" display="block" gutterBottom>
                                            {/* {notification.created_at} */}
                                            <ReactTimeAgo date={notification.created_at} locale="ja" />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Grid container direction="column" className="list-container">
                            {(() => {
                                if (customization.language == 'english') {
                                    return (
                                        <Grid item xs={12} sx={{ pb: 2 }}>
                                            <Typography variant="subtitle2">{notification.content_english}</Typography>
                                        </Grid>
                                    )
                                } else {
                                    return (
                                        <Grid item xs={12} sx={{ pb: 2 }}>
                                            <Typography variant="subtitle2">{notification.content_japanese}</Typography>
                                        </Grid>
                                    )

                                }
                            })()}
                            <Grid item xs={12}>
                                <Grid container>
                                    {/* {notification.status == 'unread' && (
                                        <Grid item>
                                            <Chip label="Unread" sx={chipErrorSX} />
                                        </Grid>
                                    )} */}
                                    {notification.type == 'invitation' && (
                                        <>
                                            <Grid item>
                                                <Chip label={languageNotification.invitationAccept} onClick={() => handleAccept(notification)} sx={chipSuccessSX} />
                                            </Grid>
                                            <Grid item>
                                                <Chip label={languageNotification.invitationDecline} onClick={() => handleDecline(notification)} sx={chipWarningSX} />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItemWrapper>
                    <Divider />
                </>
            ))}
            {/* <ListItemWrapper>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar
                            sx={{
                                color: theme.palette.success.dark,
                                backgroundColor: theme.palette.success.light,
                                border: 'none',
                                borderColor: theme.palette.success.main
                            }}
                        >
                            <IconBuildingStore stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">Store Verification Done</Typography>} />
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    2 min ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container">
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <Typography variant="subtitle2">We have successfully received your request.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Chip label="Unread" sx={chipErrorSX} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemWrapper>
            <Divider />
            <ListItemWrapper>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar
                            sx={{
                                color: theme.palette.primary.dark,
                                backgroundColor: theme.palette.primary.light,
                                border: 'none',
                                borderColor: theme.palette.primary.main
                            }}
                        >
                            <IconMailbox stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">Check Your Mail.</Typography>} />
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Typography variant="caption" display="block" gutterBottom>
                                    2 min ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container">
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <Typography variant="subtitle2">All done! Now check your inbox as you&apos;re in for a sweet treat!</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Button variant="contained" disableElevation endIcon={<IconBrandTelegram stroke={1.5} size="1.3rem" />}>
                                    Mail
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemWrapper>
            <Divider />
            <ListItemWrapper>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar alt="John Doe" src={User1} />
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">John Doe</Typography>} />
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    2 min ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container">
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <Typography component="span" variant="subtitle2">
                            Uploaded two file on &nbsp;
                            <Typography component="span" variant="h6">
                                21 Jan 2020
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Card
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light
                                    }}
                                >
                                    <CardContent>
                                        <Grid container direction="column">
                                            <Grid item xs={12}>
                                                <Stack direction="row" spacing={2}>
                                                    <IconPhoto stroke={1.5} size="1.3rem" />
                                                    <Typography variant="subtitle1">demo.jpg</Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemWrapper>
            <Divider />
            <ListItemWrapper>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar alt="John Doe" src={User1} />
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">John Doe</Typography>} />
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    2 min ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container">
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <Typography variant="subtitle2">It is a long established fact that a reader will be distracted</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Chip label="Confirmation of Account." sx={chipSuccessSX} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemWrapper> */}
        </List>
    );
};

export default NotificationList;
