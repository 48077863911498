import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useLanguageApiManagementDashboard = () => {
    const japaneseContent = {
        clientIdLabel: 'クライアントID',
        tenantIdLabel: 'テナント ID',
        apiUrlLabel: 'AuthBlue API Url',
        title: 'API管理',
        issueTokenTitle: 'APIトークンの発行',
        currentTokenTitle: '現在のトークン',
        tokenExpireDateTitle: 'トークンの期限',
        issueTitle: '発行',
        deleteTitle: '削除',
        removeConfirmation: 'トークンの削除を実行します',
        apiDetail: 'API利用の詳細はドキュメントをご覧ください。',
    };
    const englishContent = {
        clientIdLabel: 'Client ID',
        tenantIdLabel: 'Tenant ID',
        apiHostLabel: 'AuthBlue API Url',
        title: 'API Management',
        issueTokenTitle: 'Issue Token',
        currentTokenTitle: 'Current Issued Token',
        tokenExpireDateTitle: 'Expiring Date',
        issueTitle: 'Issue',
        deleteTitle: 'Delete',
        removeConfirmation: 'Token will be deleted.',
        apiDetail: 'Please refer the documentation for the API usage',
    };
    const [content, setContent] = useState(japaneseContent);
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        if (customization.language == 'japanese') {
            setContent(japaneseContent);
        } else if (customization.language == 'english') {
            setContent(englishContent);
        }
    }, [customization]);

    return content;
};

export { useLanguageApiManagementDashboard };
