import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third party
import { Formik } from 'formik';

// firebase
import { useLanguageCreateTenant } from 'language/create_tenant';

const TenantInfoForm = ({ name, description, setName, setDescription, nameError, setNameError }) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const language = useLanguageCreateTenant();

    useEffect(() => {
        if (name == '') {
            setNameError(language.nameMustNotBeEmpty);
        } else {
            setNameError('');
        }
    }, [name]);

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ alignItems: 'center', display: 'flex' }}></Box>
                </Grid>
            </Grid>

            <Formik
                initialValues={{
                    name: name,
                    description: description
                }}
            // validationSchema={Yup.object().shape({
            //     name: Yup.string().max(100).required('Name is required'),
            //     description: Yup.string().max(255)
            // })}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={10} sm={10} md={10} ml={3}>
                                <TextField
                                    fullWidth
                                    label={language.nameLabel}
                                    margin="normal"
                                    name={'name'}
                                    type="text"
                                    defaultValue=""
                                    value={name}
                                    error={nameError != ""}
                                    //helperText={nameError ? `${name} ${language.nameAlreadyTaken}` : ''}
                                    helperText={nameError}
                                    //value={values.clientName}
                                    // onChange={(e) => setClientName(e.target.value)}
                                    onChange={(e) => setName(e.target.value)}
                                    sx={{ ...theme.typography.customInput }}
                                />
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} ml={3}>
                                <TextField
                                    fullWidth
                                    label={language.descriptionLabel}
                                    margin="normal"
                                    name={'description'}
                                    type="text"
                                    defaultValue=""
                                    maxRows={4}
                                    value={description}
                                    //value={values.clientDescription}
                                    // onChange={(e) => setClientDescription(e.target.value)}
                                    onChange={(e) => setDescription(e.target.value)}
                                    sx={{ ...theme.typography.customInput }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default TenantInfoForm;
