// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    client: null,
    clients: []
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const clientReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.SET_CLIENT:
            return {
                ...state,
                client: action.client
            };
        case actionTypes.SET_CLIENTS:
            return {
                ...state,
                clients: action.clients
            };
        case actionTypes.SET_CLIENTS_RESET:
            return initialState;
        default:
            return state;
    }
};

export default clientReducer;
