import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Box, Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

import { api } from 'utils/axios';
import { SET_CLIENT, SET_CLIENTS, SET_LOADING, SET_NOT_LOADING } from 'store/actions';
import { useLanguageTenantTop } from 'language/tenantTop';

const BoxWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    //color: theme.palette.primary.main,
    border: '1px solid ',
    borderColor: theme.palette.primary.main,
    borderRadius: '10%',
    boxShadow: 2,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10
}));

const ButtonWrapper = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    //color: theme.palette.primary.main,
    border: '1px solid ',
    borderColor: theme.palette.primary.main,
    borderRadius: '10%',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10
}));

const Plan = () => {
    const theme = useTheme();
    const [isLoading, setLoading] = useState(true);
    const [clients, setClients] = useState([]);
    const tenant = useSelector((state) => state.tenant);
    const user = useSelector((state) => state.user);
    const client = useSelector((state) => state.client);
    const [tenantId, setTenantId] = useState(null);
    const [email, setEmail] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setTenantId(tenant?.tenant.id);
    }, [tenant]);

    useEffect(() => {
        setEmail(user.email)
    }, [user]);

    return (
        <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
            client-reference-id={tenantId}
            customer-email={email}
        ></stripe-pricing-table>
    );
};

export default Plan;
