import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { useLanguageCreateTenant } from 'language/create_tenant';

// styles
const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.warning.light,
    marginTop: '16px',
    marginBottom: '16px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '19px solid ',
        borderColor: theme.palette.warning.main,
        borderRadius: '50%',
        top: '65px',
        right: '-150px'
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '3px solid ',
        borderColor: theme.palette.warning.main,
        borderRadius: '50%',
        top: '145px',
        right: '-70px'
    }
}));

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const PlanCard = ({ plan }) => {
    const languageCreateTenant = useLanguageCreateTenant();

    return (
        <CardStyle>
            <CardContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <Typography variant="h4">{plan.name}</Typography>
                            </Grid>
                            <Grid item sx={{ mt: 1 }}>
                                <Typography variant="subtitle"> {plan.description}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" color="grey.900" sx={{ opacity: 0.6 }}>
                            {languageCreateTenant.priceTitle}
                        </Typography>
                        <Typography variant="h5" color="grey.600" sx={{ opacity: 0.6 }}>
                            {`${plan.price}`}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </CardStyle>
    );
};

export default PlanCard;
