import { Fragment } from 'react';
import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    TextField,
    Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import User1 from 'assets/images/users/user-round.svg';
import PaidIcon from '@mui/icons-material/Paid';

// assets
import { IconLogout, IconSearch, IconSettings, IconUser } from '@tabler/icons';
import TenantCard from './TenantCard';

import { api } from 'utils/axios';
import { SET_LOADING, SET_NOT_LOADING, SET_TENANT, SET_USER_RESET } from 'store/actions';

import { GoogleAuthProvider, signInWithPopup, signOut, getAuth } from 'firebase/auth';
import { useLanguageProfile } from 'language/profile';
import { useLanguageHeader } from 'language/header';


// ==============================|| PROFILE MENU ||============================== //

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    borderRadius: '4%',
    border: 'none',
    boxShadow: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    p: 4
};

const BoxWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    //color: theme.palette.primary.main,
    border: '1px solid ',
    borderColor: theme.palette.primary.main,
    borderRadius: '10%',
    boxShadow: 2,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10
}));

const ButtonWrapper = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    //color: theme.palette.primary.main,
    border: '1px solid ',
    borderColor: theme.palette.primary.main,
    borderRadius: '10%',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10
}));

const TypographyWrapper = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    '&:hover': {
        color: 'white'
    }
}));

const checkIfCurrentUserIsOwner = (user, members) => {
    var flag = false;
    members.forEach((member) => {
        if (member.role == 'owner') {
            if (member.email == user.email) {
                flag = true;
            }
        }
    });
    return flag;
};

const TenantManagementSection = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);

    const [membersToShow, setMembersToShow] = useState([]);

    const tenant = useSelector((state) => state.tenant);
    const dispatch = useDispatch();

    const deleteTenantClicked = () => {
        setDeleteTenantConfirmationOpen(true);
    };

    const languageHeader = useLanguageHeader();


    const [deleteTenantConfirmationOpen, setDeleteTenantConfirmationOpen] = useState(false);
    const handleDeleteTenantConfirmationClose = () => setDeleteTenantConfirmationOpen(false);
    const [tenantNameConfirmation, setTenantNameConfirmation] = useState('');
    const [isTenantNameConfirmationMatched, setIsTenantConfirmationMatched] = useState(false);

    const handleDeleteTenantSubmit = async () => {
        const params = {
            tenant_id: tenant.tenant.id
        };
        dispatch({ type: SET_LOADING });
        const res = await api.post('/tenants/delete', params);
        dispatch({ type: SET_NOT_LOADING });
        setDeleteTenantConfirmationOpen(false);
        navigate('/top');
    };


    useEffect(() => {
        if (tenantNameConfirmation == tenant.tenant.name) {
            setIsTenantConfirmationMatched(true);
        } else {
            setIsTenantConfirmationMatched(false);
        }
    }, [tenantNameConfirmation]);

    useEffect(() => {
        if (tenant != null) {
            if (tenant.members) {
                setMembersToShow(tenant.members);
            }
        }
    }, [tenant]);


    return (
        <>
            <Box sx={{ mx: 1 }}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <Fragment>
                            <Grid container justifyContent="center" alignItems="center"
                                {...bindTrigger(popupState)}
                                sx={{
                                    cursor: 'pointer',
                                    padding: '12px',
                                    paddingX: 2,
                                    height: '48px',
                                    borderRadius: '27px',
                                    transition: 'all .2s ease-in-out',
                                    borderColor: theme.palette.primary.light,
                                    backgroundColor: theme.palette.primary.light,
                                    '&[aria-controls="menu-list-grow"], &:hover': {
                                        borderColor: theme.palette.primary.main,
                                        background: `${theme.palette.primary.main}!important`,
                                        color: theme.palette.primary.light,
                                        '& svg': {
                                            stroke: theme.palette.primary.light
                                        }
                                    },
                                    '& .MuiChip-label': {
                                        lineHeight: 0
                                    }
                                }}
                            >
                                <TypographyWrapper variant="subtitle" sx={{ fontWeight: 'medium' }}>
                                    {languageHeader.tenantManagement}
                                </TypographyWrapper>
                            </Grid>
                            <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                    onClick={() => {
                                        deleteTenantClicked();
                                        popupState.close();
                                    }}
                                    disabled={checkIfCurrentUserIsOwner(user, membersToShow) ? false : true}
                                >
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Grid item>
                                            <DeleteOutlineIcon sx={{ ml: 0, mr: 1, mt: 0 }} />
                                        </Grid>
                                        {checkIfCurrentUserIsOwner(user, membersToShow) ? (
                                            <Grid item>{languageHeader.deleteTenant}</Grid>
                                        ) : (
                                            <Grid item>{languageHeader.onlyOwnerCanDeleteTenant}</Grid>
                                        )}
                                    </Grid>
                                </MenuItem>
                            </Menu>
                        </Fragment>
                    )}
                </PopupState>
            </Box>
            <Modal
                open={deleteTenantConfirmationOpen}
                onClose={handleDeleteTenantConfirmationClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500
                    }
                }}
            >
                <Fade in={deleteTenantConfirmationOpen}>
                    <Box sx={modalStyle}>
                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 330,
                                py: 0,
                                borderRadius: '10px',
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 300
                                },
                                '& .MuiListItemSecondaryAction-root': {
                                    top: 22
                                },
                                '& .MuiDivider-root': {
                                    my: 0
                                },
                                '& .list-container': {
                                    pl: 7
                                }
                            }}
                        >
                            <ListItemWrapper>
                                <ListItem alignItems="center">
                                    <ListItemAvatar>
                                        <Avatar
                                            sx={{
                                                color: theme.palette.primary.dark,
                                                backgroundColor: theme.palette.primary.light,
                                                border: 'none',
                                                borderColor: theme.palette.primary.main
                                            }}
                                        >
                                            <DeleteOutlineIcon stroke={1.5} size="1.3rem" />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={languageHeader.deletingTenant} />
                                </ListItem>
                                <Grid container direction="column" className="list-container">
                                    <Grid item xs={12} sx={{ pb: 2 }}>
                                        <Typography variant="subtitle2">{`${languageHeader.deletingTenantDetail1} ${tenant.tenant.name}. ${languageHeader.deletingTenantDetail2}`}</Typography>
                                        <Typography variant="subtitle2">{`${languageHeader.deletingTenantDetail3} ${tenant.tenant.name} ${languageHeader.deletingTenantDetail4}`}</Typography>
                                        <TextField
                                            fullWidth
                                            label={languageHeader.deletingTenantInputLabel}
                                            margin="normal"
                                            name="tenant_name"
                                            type="text"
                                            defaultValue=""
                                            placeholder={tenant.tenant.name}
                                            maxRows={4}
                                            value={tenantNameConfirmation}
                                            onChange={(e) => setTenantNameConfirmation(e.target.value)}
                                            sx={{ ...theme.typography.customInput, mt: 3 }}
                                            error={isTenantNameConfirmationMatched ? false : true}
                                            helperText={
                                                isTenantNameConfirmationMatched
                                                    ? languageHeader.deletingTenantConfirmation1
                                                    : languageHeader.deletingTenantConfirmation2
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </ListItemWrapper>
                            <Divider />
                        </List>
                        <Button sx={{ mt: 2 }} variant="contained" color="primary" onClick={handleDeleteTenantSubmit}>
                            {'OK'}
                        </Button>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default TenantManagementSection;
