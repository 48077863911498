import { Fragment } from 'react';
import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    TextField,
    Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import GroupIcon from '@mui/icons-material/Group';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import Invitation from '../Invitation';

// assets
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import { api } from 'utils/axios';
import { SET_LOADING, SET_NOT_LOADING, SET_TENANT, SET_USER_RESET } from 'store/actions';

import { useLanguageHeader } from 'language/header';
import { plan_id_to_max_client_for_plan } from 'utils/plan_utils';

// ==============================|| PROFILE MENU ||============================== //

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    borderRadius: '4%',
    border: 'none',
    boxShadow: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    p: 4
};

const BoxWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    //color: theme.palette.primary.main,
    border: '1px solid ',
    borderColor: theme.palette.primary.main,
    borderRadius: '10%',
    boxShadow: 2,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10
}));

const ButtonWrapper = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    //color: theme.palette.primary.main,
    border: '1px solid ',
    borderColor: theme.palette.primary.main,
    borderRadius: '10%',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10
}));

const TypographyWrapper = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    // '&:hover': {
    //     color: 'white'
    // }
}));

const checkIfCurrentUserIsOwner = (user, members) => {
    var flag = false;
    members.forEach((member) => {
        if (member.role == 'owner') {
            if (member.email == user.email) {
                flag = true;
            }
        }
    });
    return flag;
};

const ClientSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const client = useSelector((state) => state.client);

    const tenant = useSelector((state) => state.tenant);

    const [isClientsReachedMax, setIsClientsReachedMax] = useState(false);
    const [maxClientsForPlan, setMaxClientsForPlan] = useState(null);
    const [clientsToShow, setClientsToShow] = useState([]);

    const checkIfClientsExceedMax = () => {
        if (client != undefined) {
        }
        if (client.clients != undefined) {
            if (client.clients.length >= maxClientsForPlan) {
                setIsClientsReachedMax(true);
            }
        }
    };


    const languageHeader = useLanguageHeader();

    const chipErrorSX = {
        height: 24,
        padding: '0 4px',
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };

    useEffect(() => {
        if (maxClientsForPlan != null) {
            checkIfClientsExceedMax();
        }
        setClientsToShow(client.clients);
    }, [client.clients, maxClientsForPlan]);

    useEffect(() => {
        if (tenant != null && tenant.tenant != null) {
            const max_clients = plan_id_to_max_client_for_plan(tenant.tenant.plan)
            setMaxClientsForPlan(max_clients)

        }
    }, [tenant]);

    return (
        <Box sx={{ mx: 1 }}>
            <Grid container justifyContent="center" alignItems="center"
                sx={{
                    padding: '12px',
                    paddingX: 2,
                    height: '48px',
                    borderRadius: '27px',
                    // transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.light,
                    // '&[aria-controls="menu-list-grow"], &:hover': {
                    //     borderColor: theme.palette.primary.main,
                    //     background: `${theme.palette.primary.main}!important`,
                    //     color: theme.palette.primary.light,
                    //     '& svg': {
                    //         stroke: theme.palette.primary.light
                    //     }
                    // },
                    // '& .MuiChip-label': {
                    //     lineHeight: 0
                    // }
                }}
            >
                <TypographyWrapper variant="subtitle" sx={{ fontWeight: 'medium' }}>
                    {`${languageHeader.clientLabel} ${clientsToShow.length} / ${maxClientsForPlan}`}
                </TypographyWrapper>

            </Grid>
        </Box>
    );
};

export default ClientSection;
