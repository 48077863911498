import NotificationList from './NotificationList';
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Badge,
    Box,
    Button,
    ButtonBase,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconBell } from '@tabler/icons';
import { api } from 'utils/axios';
import { SET_NOTIFICATIONS } from 'store/actions';
import { useLanguageNotification } from 'language/notification';

const NotificationSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const user = useSelector((state) => state.user);
    const tenant = useSelector((state) => state.tenant);
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [notificationUnreadCount, setNotificationUnreadCount] = useState(0);

    const languageNotification = useLanguageNotification();

    const deleteNotificationClicked = async () => {
        const params = { tenant_id: tenant.tenant.id };
        const res = api.post('/notifications/delete/all', params);
        const res_notifications = await api.get('/notifications');
        dispatch({ type: SET_NOTIFICATIONS, notifications: res_notifications.result });
    };

    useEffect(() => {
        let count = 0;
        user?.notifications?.forEach((el) => {
            if (el.status == 'unread') {
                count += 1;
            }
        });
        setNotificationUnreadCount(count);
    }, [user]);

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = async () => {
        if (open == false) {
            setOpen((prevOpen) => !prevOpen);
            setNotificationUnreadCount(0);
            const params = { tenant_id: tenant.tenant.id };
            const data = await api.get('/notifications/make_read/all');
            const res_notifications = await api.get('/notifications');
            console.log("here=======")
            console.log(res_notifications.result)
            dispatch({ type: SET_NOTIFICATIONS, notifications: res_notifications.result });
        } else {
            setOpen((prevOpen) => !prevOpen);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Badge badgeContent={notificationUnreadCount} color="primary">
                    <ButtonBase sx={{ borderRadius: '12px' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&[aria-controls="menu-list-grow"],&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                            color="inherit"
                        >
                            <IconBell stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ButtonBase>
                </Badge>
            </Box>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                <Grid item>
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography variant="subtitle1">{languageNotification.title}</Typography>
                                                        <Chip
                                                            size="small"
                                                            label={notificationUnreadCount}
                                                            sx={{
                                                                color: theme.palette.background.default,
                                                                bgcolor: theme.palette.warning.dark
                                                            }}
                                                        />
                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        component={Button}
                                                        onClick={deleteNotificationClicked}
                                                        variant="subtitle2"
                                                        color="primary"
                                                    >
                                                        {languageNotification.deleteTitle}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <NotificationList />
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
