
const plan_id_to_max_member_for_plan = (planId) => {
    if (planId == undefined) {
        return 1
    }
    if (planId.toLowerCase() == (process.env.REACT_APP_PLAN_FREE).toLowerCase()) {
        return process.env.REACT_APP_MAXIMUM_MEMBERS_FOR_FREE_PLAN
    } else if (planId == process.env.REACT_APP_STRIPE_PRODUCT_HOBBY) {
        return process.env.REACT_APP_MAXIMUM_MEMBERS_FOR_HOBBY_PLAN
    } else if (planId == process.env.REACT_APP_STRIPE_PRODUCT_BASIC) {
        return process.env.REACT_APP_MAXIMUM_MEMBERS_FOR_BASIC_PLAN
    } else if (planId == process.env.REACT_APP_STRIPE_PRODUCT_PREMIUM) {
        return process.env.REACT_APP_MAXIMUM_MEMBERS_FOR_PREMIUM_PLAN
    } else {
        console.log(`plan_id_to_max_member_for_plan, unknown plan for ${planId}`)
        return 1
    }
}

const plan_id_to_max_client_for_plan = (planId) => {
    if (planId == undefined) {
        return 1
    }
    if (planId.toLowerCase() == (process.env.REACT_APP_PLAN_FREE).toLowerCase()) {
        return process.env.REACT_APP_MAXIMUM_CLIENT_FOR_FREE_PLAN
    } else if (planId == process.env.REACT_APP_STRIPE_PRODUCT_HOBBY) {
        return process.env.REACT_APP_MAXIMUM_CLIENT_FOR_HOBBY_PLAN
    } else if (planId == process.env.REACT_APP_STRIPE_PRODUCT_BASIC) {
        return process.env.REACT_APP_MAXIMUM_CLIENT_FOR_BASIC_PLAN
    } else if (planId == process.env.REACT_APP_STRIPE_PRODUCT_PREMIUM) {
        return process.env.REACT_APP_MAXIMUM_CLIENT_FOR_PREMIUM_PLAN
    } else {
        console.log(`plan_id_to_max_client_for_plan, unknown plan for ${planId}`)
        return 1
    }
}

const plan_id_to_free_agreement_for_plan = (planId) => {
    if (planId == undefined) {
        return 1
    }
    if (planId.toLowerCase() == (process.env.REACT_APP_PLAN_FREE).toLowerCase()) {
        return process.env.REACT_APP_MAXIMUM_FREE_AGREEMENT_FOR_FREE_PLAN
    } else if (planId == process.env.REACT_APP_STRIPE_PRODUCT_HOBBY) {
        return process.env.REACT_APP_MAXIMUM_FREE_AGREEMENT_FOR_HOBBY_PLAN
    } else if (planId == process.env.REACT_APP_STRIPE_PRODUCT_BASIC) {
        return process.env.REACT_APP_MAXIMUM_FREE_AGREEMENT_FOR_BASIC_PLAN
    } else if (planId == process.env.REACT_APP_STRIPE_PRODUCT_PREMIUM) {
        return process.env.REACT_APP_MAXIMUM_FREE_AGREEMENT_FOR_PREMIUM_PLAN
    } else {
        console.log(`plan_id_to_free_agreement_for_plan, unknown plan for ${planId}`)
        return 1
    }
}

const plan_id_to_name = (planId) => {
    if (planId == undefined) {
        return 1
    }
    if (planId.toLowerCase() == (process.env.REACT_APP_PLAN_FREE).toLowerCase()) {
        return process.env.REACT_APP_NAME_FOR_FREE_PLAN
    } else if (planId == process.env.REACT_APP_STRIPE_PRODUCT_HOBBY) {
        return process.env.REACT_APP_NAME_FOR_HOBBY_PLAN
    } else if (planId == process.env.REACT_APP_STRIPE_PRODUCT_BASIC) {
        return process.env.REACT_APP_NAME_FOR_BASIC_PLAN
    } else if (planId == process.env.REACT_APP_STRIPE_PRODUCT_PREMIUM) {
        return process.env.REACT_APP_NAME_FOR_PREMIUM_PLAN
    } else {
        console.log(`plan_id_to_name, unknown plan for ${planId}`)
        return ""
    }
}

export {
    plan_id_to_max_member_for_plan,
    plan_id_to_max_client_for_plan,
    plan_id_to_free_agreement_for_plan,
    plan_id_to_name,
}