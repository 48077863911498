import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useLanguageCreateTenant = () => {
    const japaneseContent = {
        next: '次へ',
        back: '戻る',
        nameTitle: 'テナント名と説明を登録しましょう。',
        nameSubTitle: 'テナント名は変更することができません。',
        nameLabel: 'テナント名',
        descriptionLabel: 'テナントの説明',
        nameAlreadyTaken: 'は既に使われています。',
        nameMustNotBeEmpty: '名前の設定は必須です。',
        loadAfterSetName: 'テナントを作成しています。',
        planTitle: 'プランの選択',
        planSubTitle1: 'プランの詳細はリンクから参照できます。',
        planSubTitle2: 'プランはいつでも変更可能です。',
        trialPlanTitle: 'トライアルプラン',
        trialPlanDescription: '手軽に機能をお試しいただけるプランです。招待できるメンバーや、情報提供を取得できる回数に制限があります。',
        priceTitle: '料金',
        trialPlanPrice: '月額無料',
        basicPlanTitle: 'ベーシックプラン',
        basicPlanDescription: 'テナントへのメンバーの招待、情報提供の取得回数など運用に適したプランです。',
        basicPlanPrice: '月額　5,000 円',
        enterprisePlanTitle: 'エンタープライズプラン',
        enterprisePlanDescription: '大規模な情報提供の管理や、スケーリングを考慮したプランです。料金体系は別途ご相談となる場合があります。',
        enterprisePlanPrice: '月額　30,000 円',
        submit: '送信',
        modify: '修正',
        summaryTitle: '登録の確認をしましょう。',
        summarySubTitle: '内容を確認して送信しましょう。',
        summaryTenantNameTitle: 'テナント名',
        summaryTenantDescriptionTitle: 'テナントの詳細',
        summaryTenantPlanTitle: 'テナントのプラン',
        stepTitle1: 'テナント情報の登録',
        stepTitle2: 'プランの選択'
    };
    const englishContent = {
        next: 'Next',
        back: 'Back',
        nameTitle: 'Set tenant name and description',
        nameSubTitle: 'Tenant name cannot be changed later',
        nameLabel: 'Tenant name',
        descriptionLabel: 'Tenant description',
        nameAlreadyTaken: 'is already taken',
        nameMustNotBeEmpty: 'Name must not be empty',
        loadAfterSetName: 'Creating tenant...',
        planTitle: 'Choose plan',
        planSubTitle1: 'Plan detail is available from the link',
        planSubTitle2: 'Plan can be modified any time',
        priceTitle: 'Price',
        trialPlanTitle: 'Trial plan',
        trialPlanDescription:
            'This is the plan which you can try out what you can do with this platform. The number of agreement you can take, and member in the tenant is limited.',
        trialPlanPrice: 'Free',
        basicPlanTitle: 'Basic plan',
        basicPlanDescription: 'The most reasonable plan for daily operation.',
        basicPlanPrice: '49.99 / month',
        enterprisePlanTitle: 'Enterprise plan',
        enterprisePlanDescription:
            'If you assume a big number of agreement you need to handle, choose this plan. Please be noted that price might be adjusted based on your operation requirements.',
        enterprisePlanPrice: '299.99 / month',
        submit: 'Submit',
        modify: 'Modify',
        summaryTitle: 'Confirmation of registration',
        summarySubTitle: 'Check the content and submit',
        summaryTenantNameTitle: 'Tenant name',
        summaryTenantDescriptionTitle: 'Tenant description',
        summaryTenantPlanTitle: 'Plan',
        stepTitle1: 'Tenant info',
        stepTitle2: 'Choose plan'
    };
    const [content, setContent] = useState(japaneseContent);
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        if (customization.language == 'japanese') {
            setContent(japaneseContent);
        } else if (customization.language == 'english') {
            setContent(englishContent);
        }
    }, [customization]);

    return content;
};

export { useLanguageCreateTenant };
