import { Fragment } from 'react';
import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    TextField,
    Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import GroupIcon from '@mui/icons-material/Group';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import Invitation from '../Invitation';

// assets
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import { api } from 'utils/axios';
import { SET_LOADING, SET_MEMBERS, SET_NOT_LOADING, SET_TENANT, SET_USER_RESET } from 'store/actions';

import { useLanguageHeader } from 'language/header';
import { plan_id_to_max_member_for_plan } from 'utils/plan_utils';

// ==============================|| PROFILE MENU ||============================== //

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    borderRadius: '4%',
    border: 'none',
    boxShadow: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    p: 4
};

const BoxWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    //color: theme.palette.primary.main,
    border: '1px solid ',
    borderColor: theme.palette.primary.main,
    borderRadius: '10%',
    boxShadow: 2,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10
}));

const ButtonWrapper = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    //color: theme.palette.primary.main,
    border: '1px solid ',
    borderColor: theme.palette.primary.main,
    borderRadius: '10%',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10
}));

const TypographyWrapper = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    '&:hover': {
        color: 'white'
    }
}));

const checkIfCurrentUserIsOwner = (user, members) => {
    var flag = false;
    members.forEach((member) => {
        if (member.role == 'owner') {
            if (member.email == user.email) {
                flag = true;
            }
        }
    });
    return flag;
};

const MemberSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const customization = useSelector((state) => state.customization);

    const [membersToShow, setMembersToShow] = useState([]);

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const tenant = useSelector((state) => state.tenant);
    const dispatch = useDispatch();

    const handleRemoveConfirmationClose = () => setRemoveConfirmationOpen(false);

    const [isMemberReachedMax, setIsMemberReachedMax] = useState(false);
    const [removeConfirmataionOpen, setRemoveConfirmationOpen] = useState(false);
    const [maxMemberForPlan, setMaxMemberForPlan] = useState(null);
    const [deleteTenantConfirmationOpen, setDeleteTenantConfirmationOpen] = useState(false);
    const [deleteMemberTarget, setDeleteMemberTarget] = useState(null);

    const removeUserClicked = async (member) => {
        setRemoveConfirmationOpen(true);
        setDeleteMemberTarget(member);
    };

    const inviteMemberClicked = () => {
        setOpen(true);
    };

    const checkIfMemberExceedMax = () => {
        if (tenant != undefined) {
        }
        if (tenant.members.length >= maxMemberForPlan) {
            setIsMemberReachedMax(true);
        }
    };

    const handleInvitationSubmit = async () => {
        const params = {
            user_email: email,
            tenant_id: tenant.tenant.id
        };
        dispatch({ type: SET_LOADING });
        const res = await api.post('/create_invitation', params);
        dispatch({ type: SET_NOT_LOADING });
        handleClose();
    };


    const languageHeader = useLanguageHeader();

    const chipErrorSX = {
        height: 24,
        padding: '0 4px',
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };


    const handleRemoveUserSubmit = async () => {
        const params = {
            user_id: deleteMemberTarget.id,
            tenant_id: tenant.tenant.id
        };
        dispatch({ type: SET_LOADING });
        const res = await api.post('/tenants/members/delete', params);
        const res_members = await api.post('/tenants/members', params);
        dispatch({ type: SET_MEMBERS, members: res_members.result });
        dispatch({ type: SET_NOT_LOADING });
        handleRemoveConfirmationClose();
    };


    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (maxMemberForPlan != null) {
            checkIfMemberExceedMax();
        }
    }, [tenant.members, maxMemberForPlan]);

    useEffect(() => {
        if (tenant != null && tenant.tenant != null) {
            const max_member = plan_id_to_max_member_for_plan(tenant.tenant.plan)
            setMaxMemberForPlan(max_member)

            if (tenant.members) {
                setMembersToShow(tenant.members);
            }
        }
    }, [tenant]);

    return (
        <>
            <Box sx={{ mx: 1 }}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <Fragment>
                            <Grid container justifyContent="center" alignItems="center"
                                {...bindTrigger(popupState)}
                                sx={{
                                    cursor: 'pointer',
                                    padding: '12px',
                                    paddingX: 2,
                                    height: '48px',
                                    borderRadius: '27px',
                                    transition: 'all .2s ease-in-out',
                                    borderColor: theme.palette.primary.light,
                                    backgroundColor: theme.palette.primary.light,
                                    '&[aria-controls="menu-list-grow"], &:hover': {
                                        borderColor: theme.palette.primary.main,
                                        background: `${theme.palette.primary.main}!important`,
                                        color: theme.palette.primary.light,
                                        '& svg': {
                                            stroke: theme.palette.primary.light
                                        }
                                    },
                                    '& .MuiChip-label': {
                                        lineHeight: 0
                                    }
                                }}
                            >

                                <TypographyWrapper variant="subtitle" sx={{ fontWeight: 'medium' }}>
                                    {`${languageHeader.memberLabel} ${membersToShow.length} / ${maxMemberForPlan}`}
                                </TypographyWrapper>

                            </Grid>
                            <Menu {...bindMenu(popupState)}>
                                {membersToShow.map((member) => (
                                    <MenuItem onClick={popupState.close}>
                                        <Grid container justifyContent="start" alignItems="center">
                                            <Grid item>
                                                <Avatar />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle" sx={{ ml: 1, color: theme.palette.primary.main }}>
                                                    {member.email}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                {(() => {
                                                    if (customization.language == 'english') {
                                                        return (
                                                            <Typography variant="subtitle" sx={{ ml: 1 }}>
                                                                {`${member.role}`}
                                                            </Typography>
                                                        )
                                                    } else {
                                                        if (member.role == 'owner') {
                                                            return (
                                                                <Typography variant="subtitle" sx={{ ml: 1 }}>
                                                                    オーナー
                                                                </Typography>
                                                            )
                                                        } else if (member.role == 'member') {
                                                            return (
                                                                <Typography variant="subtitle" sx={{ ml: 1 }}>
                                                                    ユーザ
                                                                </Typography>
                                                            )

                                                        }



                                                    }

                                                })()}
                                            </Grid>
                                            {(() => {
                                                if (checkIfCurrentUserIsOwner(user, membersToShow)) {
                                                    if (user.email != member.email) {
                                                        return (
                                                            <Grid item sx={{ ml: 1 }}>
                                                                <Chip
                                                                    label={languageHeader.removeClientLabel}
                                                                    sx={chipErrorSX}
                                                                    onClick={() => removeUserClicked(member)}
                                                                />
                                                            </Grid>
                                                        );
                                                    }
                                                }
                                            })()}
                                        </Grid>
                                    </MenuItem>
                                ))}
                                <MenuItem
                                    onClick={() => {
                                        inviteMemberClicked();
                                        popupState.close();
                                    }}
                                    disabled={isMemberReachedMax ? true : false}
                                >
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <AddIcon sx={{ ml: 0, mr: 1, mt: 1 }} />
                                        </Grid>
                                        {isMemberReachedMax ? (
                                            <Grid item>{languageHeader.needToUpgradeYourPlanToInviteMember}</Grid>
                                        ) : (
                                            <Grid item>{languageHeader.inviteMember}</Grid>
                                        )}
                                    </Grid>
                                </MenuItem>
                            </Menu>
                        </Fragment>
                    )}
                </PopupState>
            </Box>
            <Modal
                open={removeConfirmataionOpen}
                onClose={handleRemoveConfirmationClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500
                    }
                }}
            >
                <Fade in={removeConfirmataionOpen}>
                    <Box sx={modalStyle}>
                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 330,
                                py: 0,
                                borderRadius: '10px',
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 300
                                },
                                '& .MuiListItemSecondaryAction-root': {
                                    top: 22
                                },
                                '& .MuiDivider-root': {
                                    my: 0
                                },
                                '& .list-container': {
                                    pl: 7
                                }
                            }}
                        >
                            <ListItemWrapper>
                                <ListItem alignItems="center">
                                    <ListItemAvatar>
                                        <Avatar
                                            sx={{
                                                color: theme.palette.primary.dark,
                                                backgroundColor: theme.palette.primary.light,
                                                border: 'none',
                                                borderColor: theme.palette.primary.main
                                            }}
                                        >
                                            <PersonRemoveIcon stroke={1.5} size="1.3rem" />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={languageHeader.removeUserItemText} />
                                </ListItem>
                                <Grid container direction="column" className="list-container">
                                    <Grid item xs={12} sx={{ pb: 2 }}>
                                        <Typography variant="subtitle2">{languageHeader.removeUserConfirmation}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemWrapper>
                            <Divider />
                        </List>
                        <Button sx={{ mt: 2 }} variant="contained" color="primary" onClick={() => { handleRemoveUserSubmit() }}>
                            {'OK'}
                        </Button>
                    </Box>
                </Fade>
            </Modal>

            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500
                    }
                }}
            >
                <Fade in={open}>
                    <Box sx={modalStyle}>
                        <Invitation email={email} setEmail={setEmail} />
                        <Button sx={{ mt: 2 }} variant="contained" color="primary" onClick={handleInvitationSubmit}>
                            {languageHeader.inviteMemberSubmit}
                        </Button>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default MemberSection;
