import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// assets
import { api } from 'utils/axios';
import { SET_LANGUAGE, SET_LOADING, SET_NOT_LOADING, SET_TENANT } from 'store/actions';
import CreateTenantSummary from './CreateTenantSummary';
import CreateTenantSummaryWrapper from './CreateTenantSummaryWrapper';
import TenantPlan from './TenantPlan';
import TenantInfo from './TenantInfo';
import { useDispatch } from 'react-redux';
import { useLanguageCreateTenant } from 'language/create_tenant';

const StepContent = ({ stepIndex, name, description, plan, setName, setDescription, setPlan, nameError, setNameError, assignedTenantId }) => {
    switch (stepIndex) {
        case 0:
            return (
                <TenantInfo name={name} description={description} setName={setName} setDescription={setDescription} nameError={nameError} setNameError={setNameError} />
            );
        // case 1:
        //     return <TenantPlan plan={plan} setPlan={setPlan} assignedTenantId={assignedTenantId} />;
        default:
            return 'Unknown stepIndex';
    }
};

function CreateTenant() {
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [assignedTenantId, setAssignedTenantId] = useState('');
    const [description, setDescription] = useState('');
    const [plan, setPlan] = useState('trial');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const languageCreateTenant = useLanguageCreateTenant();

    const getSteps = () => {
        return [languageCreateTenant.stepTitle1]
    };

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    const handleTenantNameIsUnique = async () => {
        const params = { name: name };
        dispatch({ type: SET_LOADING, message: languageCreateTenant.loadAfterSetName });
        const res = await api.post('/tenants/check/name_unique', params);
        dispatch({ type: SET_NOT_LOADING });
        if (res.result.res == true) {
            setNameError(`${name} ${languageCreateTenant.nameAlreadyTaken}`);
            //alert(`Tenant name: ${name} is already taken`);
        } else {
            const params = {
                name: name,
                description: description,
                plan: 'free'
            };
            dispatch({ type: SET_LOADING, message: languageCreateTenant.loadAfterSetName });
            const res = await api.post('/tenants', params);
            setAssignedTenantId(res.result.id);
            dispatch({ type: SET_NOT_LOADING });
            handleNext();
        }
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
    };

    const handleSubmit = async () => {
        navigate('/top');
    };

    return (
        <Grid container>
            <Grid sm={2} />
            <Grid lg={8} sm={8} spacing={10} mt={6}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length ? (
                    <Grid mt={2}>
                        <CreateTenantSummaryWrapper name={name} description={description} plan={plan} />
                        <Grid container direction="column" alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <Button onClick={handleReset}>{languageCreateTenant.modify}</Button>
                                <Button variant="contained" color="primary" onClick={handleSubmit}>
                                    {languageCreateTenant.submit}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid mt={2}>
                        <StepContent
                            stepIndex={activeStep}
                            name={name}
                            description={description}
                            plan={plan}
                            setName={setName}
                            setDescription={setDescription}
                            setPlan={setPlan}
                            nameError={nameError}
                            setNameError={setNameError}
                            assignedTenantId={assignedTenantId}
                        />
                        <Grid container direction="column" alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <Button disabled={activeStep === 0} onClick={handleBack}>
                                    {languageCreateTenant.back}
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleTenantNameIsUnique} disabled={nameError != ''}>
                                    {activeStep === steps.length ? languageCreateTenant.submit : languageCreateTenant.next}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
export default CreateTenant;
