import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import TopRoutes from './TopRoutes';
import CreateClientRoutes from './CreateClientRoutes';
import TenantTopRoutes from './TenantTopRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([TopRoutes, MainRoutes, AuthenticationRoutes, TenantTopRoutes, CreateClientRoutes]);
}
