import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useLanguageQRDashboard = () => {
    const japaneseContent = {
        title: '情報提供プロセスQRコード',
        subtitle: 'スキャンして情報提供を実施しましょう。',
        titleForForm: 'GoogleフォームQRコード',
        subtitleForForm: 'Googleフォームに情報提供を付与しましょう。',
        formUrlLabel: 'Googleフォームへの公開リンク',
        step1Title: 'ステップ1',
        step1Detail: 'QRコードのスキャン',
        step2Title: 'ステップ2',
        step2Detail: 'モバイルアプリの取得',
        step3Title: 'ステップ3',
        step3Detail: '情報提供の形成',
        step4Title: 'ステップ4',
        step4Detail: 'レシートの取得',
        formStep1Title: 'ステップ1',
        formStep1Detail: 'URLの登録',
        formStep2Title: 'ステップ2',
        formStep2Detail: 'QRコードへのアクセス',
        formStep3Title: 'ステップ3',
        formStep3Detail: 'フォームへの回答',
        formStep4Title: 'ステップ4',
        formStep4Detail: '情報提供プロセスの開始',
        waitingFormLinkLabel1: 'フォームへのリンクを登録すると',
        waitingFormLinkLabel2: 'QRコードが発行されます。'
    };
    const englishContent = {
        title: 'Scan to make an agreement',
        subtitle: 'QR Code for User',
        titleForForm: 'QR Code for Google form with Agreement',
        subtitleForForm: 'Let us add agreement to your google form',
        formUrlLabel: 'Public URL for Google form',
        step1Title: 'Step 1',
        step1Detail: 'Scan Q',
        step2Title: 'Step 2',
        step2Detail: 'Get Mobile App',
        step3Title: 'Step 3',
        step3Detail: 'Make an Agreement',
        step4Title: 'Step 4',
        step4Detail: 'Receive a Receipt',
        formStep1Title: 'Step 1',
        formStep1Detail: 'Register URL',
        formStep2Title: 'Step 2',
        formStep2Detail: 'Read QR Code',
        formStep3Title: 'Step 3',
        formStep3Detail: 'Answer to the form',
        formStep4Title: 'Step 4',
        formStep4Detail: 'Start agreement process',
        waitingFormLinkLabel1: 'Once you register form url',
        waitingFormLinkLabel2: 'QR code will be issued.'
    };
    const [content, setContent] = useState(japaneseContent);
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        if (customization.language == 'japanese') {
            setContent(japaneseContent);
        } else if (customization.language == 'english') {
            setContent(englishContent);
        }
    }, [customization]);

    return content;
};

export { useLanguageQRDashboard };
