import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, Grid, Typography } from '@mui/material';

// third-party
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';

// project imports
//import chartData from './chart-data/bajaj-area-chart';
import { sumList } from 'utils/label_utils';

// ===========================|| DASHBOARD DEFAULT - BAJAJ AREA CHART CARD ||=========================== //

const DetailViewGraphCard = ({ title, chartData }) => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const [chartDataToShow, setChartDataToShow] = useState(chartData);
    const { navType } = customization;
    const orangeDark = theme.palette.secondary[800];

    useEffect(() => {
        const newChartData = {
            options: chartData.options,
            height: chartData.height,
            type: chartData.type,
            series: chartData.series,
            colors: [orangeDark],
            tooltip: {
                theme: 'light'
            }
        };
        ApexCharts.exec(`support-chart`, 'updateOptions', newChartData);
        setChartDataToShow(newChartData);
    }, [navType, orangeDark, chartData]);

    return (
        <Card sx={{ bgcolor: 'secondary.light' }}>
            <Grid container sx={{ p: 2, pb: 0, color: '#fff' }}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="subtitle1" sx={{ color: theme.palette.secondary.dark }}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" sx={{ color: theme.palette.grey[800] }}>
                                {chartDataToShow.series && sumList(chartDataToShow.series[0].data)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {/* <Typography variant="subtitle2" sx={{ color: theme.palette.grey[800] }}>
                        10% Profit
                    </Typography> */}
                </Grid>
            </Grid>
            <Chart {...chartDataToShow} />
        </Card>
    );
};

export default DetailViewGraphCard;
