import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from './customizationReducer';
import userReducer from './userReducer';
import loadingReducer from './loadingReducer';
import clientReducer from './clientReducer';
import chartDataReducer from './chartDataReducer';
import tenantReducer from './tenantReducer';
import showLogReducer from './showLogReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    user: userReducer,
    load: loadingReducer,
    client: clientReducer,
    chartData: chartDataReducer,
    tenant: tenantReducer,
    log: showLogReducer,
});

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default persistedReducer;
