import Pusher from 'pusher-js';
// project imports
import SkeletonTotalGrowthBarChart from 'ui-component/cards/Skeleton/TotalGrowthBarChart';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// chart data
import { api } from 'utils/axios';
import { useLanguageReceiptDashboard } from 'language/receiptDashboard';
import { SET_LOADING, SET_NOT_LOADING, SET_NOT_SHOW_LOG, SET_SHOW_LOG, SET_LOG_ID } from 'store/actions';
import AgreementMethodCard from './AgreementMethodCard';
import RequestingInfoCard from './RequestingInfoCard';
import ContentCard from './ContentCard';
import SubCard from 'ui-component/cards/SubCard';
import RequestingInfoCardWithData from './RequestingCardInfoWithData';

import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, MenuItem, TextField, Typography, Modal, Button, Card, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';

// third-party
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from 'react-time-ago';
import ja from 'javascript-time-ago/locale/ja.json';
import LogModal from './LogModal';
TimeAgo.addLocale(ja);



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    },
    '&:hover': {
        transform: 'scale(1.005)',
        cursor: 'pointer'
    }
}));


// 検索バーのコンポーネント
const Search = (props) => {
    const { searched, initialRows, setRows, setSearched } = props;

    // 検索文字によってテーブルの行をフィルター関数
    const requestSearch = (searchedVal) => {
        const filteredRows = initialRows.filter((row) => {
            return row.user_name.includes(searchedVal);
        });
        setRows(filteredRows);
    };

    // 検索バーの文字が変化したときにフィルターを実行する関数
    const changeSearchedHandler = (event) => {
        setSearched(event.target.value);
        requestSearch(event.target.value);
    };

    const languageReceiptDashboard = useLanguageReceiptDashboard();

    return (
        <Grid container direction="row" alignItems="center">
            <Grid item>
                <SearchIcon />
            </Grid>
            <Grid item mb={3} ml={1}>
                <TextField
                    id="standard-basic"
                    label={languageReceiptDashboard.searchTitle}
                    variant="standard"
                    value={searched}
                    onChange={(event) => changeSearchedHandler(event)}
                />
            </Grid>
        </Grid>
    );
};

const BasicTable = ({ rows }) => {
    const client = useSelector((state) => state.client);
    const showLog = useSelector((state) => state.log);

    const [open, setOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);

    const [clientId, setClientId] = useState('');
    const [logId, setLogId] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [userId, setUserId] = useState('');
    const [agreementMethod, setAgreementMethod] = useState();
    const [requestingInfo, setRequestingInfo] = useState();
    const [userData, setUserData] = useState();
    const [content, setContent] = useState('');

    const languageReceiptDashboard = useLanguageReceiptDashboard();

    const dispatch = useDispatch();


    const handleClick = async (row) => {
        // const params = { client_id: client.client.client_id, log_id: row.id };
        // const endPoint = '/logs/detail';
        // dispatch({ type: SET_LOADING });
        // const data = await api.post(endPoint, params);
        // if (data.result.signature != undefined) {
        //     const tmp = `data:image/jpg;base64,${data.result.signature}`;
        //     setImageUrl(tmp);
        // }
        // setClientId(data.result.client_id);
        // setLogId(data.result.log_id);
        // setCreatedAt(data.result.created_at);
        // setUserId(data.result.user_id);
        // setAgreementMethod(data.result.agreement_method);
        // setRequestingInfo(data.result.requesting_info);
        // setContent(data.result.content);
        // setUserData(data.result.user_info);
        dispatch({ type: SET_LOG_ID, id: row.id })
        dispatch({ type: SET_SHOW_LOG });
        dispatch({ type: SET_NOT_LOADING });
    };

    return (
        <TableContainer component={Paper}>
            <LogModal />

            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>{languageReceiptDashboard.tableHeadUserId}</StyledTableCell>
                        <StyledTableCell align="right">{languageReceiptDashboard.tableHeadDate}</StyledTableCell>
                        <StyledTableCell align="right">{languageReceiptDashboard.tableHeadReferenceCode}</StyledTableCell>
                        <StyledTableCell align="right">{languageReceiptDashboard.tableHeadContent}</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.user_name} onClick={() => handleClick(row)}>
                            <StyledTableCell component="th" scope="row">
                                {row.user_name}
                            </StyledTableCell>
                            <StyledTableCell align="right">{row.created_at}</StyledTableCell>
                            <StyledTableCell align="right">{row.id}</StyledTableCell>
                            <StyledTableCell align="right">{row.content.agreement}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const ReceiptElement = ({ isLoading }) => {
    const initialRows = [];
    const client = useSelector((state) => state.client);
    const [timeRange, setTimeRange] = useState('today');
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);

    const [rows, setRows] = useState([...initialRows]);
    // 検索バーに入力された文字
    const [searched, setSearched] = useState('');

    const getLogs = async () => {
        const params = { client_id: client.client.client_id, time_range: timeRange };
        const res = await api.post('/logs/client', params);
        setRows(res.result);
    };

    useEffect(() => {
        getLogs();
    }, [client, timeRange]);

    const handleTimeRange = (value) => {
        setTimeRange(value);
    };

    useEffect(() => {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
            cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        });

        const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_CHANNEL_NAME);

        channel.bind(process.env.REACT_APP_PUSHER_EVENT_NAME_AGREEMENT_DONE, data => {
            getLogs()
        });

        return () => {
            pusher.unsubscribe(process.env.REACT_APP_PUSHER_CHANNEL_NAME);
        };
    }, [])

    const languageReceiptDashboard = useLanguageReceiptDashboard();

    const status = [
        {
            value: 'today',
            label: languageReceiptDashboard.receiptTableToday
        },
        {
            value: 'week',
            label: languageReceiptDashboard.receiptTableThisWeek
        },
        {
            value: 'month',
            label: languageReceiptDashboard.receiptTableThisMonth
        }
    ];
    return (
        <>
            {isLoading ? (
                <SkeletonTotalGrowthBarChart />
            ) : (
                <MainCard>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Grid container direction="column" spacing={1} sx={{ mt: 1 }}>
                                        <Grid item>
                                            <Typography variant="h3">{languageReceiptDashboard.title}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle2">{languageReceiptDashboard.header}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="standard-select-currency"
                                        select
                                        value={timeRange}
                                        onChange={(e) => handleTimeRange(e.target.value)}
                                    >
                                        {status.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Search initialRows={initialRows} searched={searched} setRows={setRows} setSearched={setSearched} />
                            <BasicTable rows={rows} />
                        </Grid>
                    </Grid>
                </MainCard>
            )}
        </>
    );
};

ReceiptElement.propTypes = {
    isLoading: PropTypes.bool
};

export default ReceiptElement;
