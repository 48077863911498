import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';

import { getLabelCenter } from 'utils/label_utils';
import { useLanguageCreateClient } from 'language/create_client';

const RequestingMethodForm = ({ agreementMethodTemplate, agreementMethods, setAgreementMethods, agreementMethodError, setAgreementMethodError, isFixed = false, isShowLabel = true }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const client = useSelector((state) => state.client);
    const [fixedClient, setFixedClient] = useState(client);
    const customization = useSelector((state) => state.customization);

    const languageCreateClient = useLanguageCreateClient();

    useEffect(() => {
        setFixedClient(client);
    }, [client]);

    const [label, setLabel] = useState(languageCreateClient.agreementMethodFormLabel);
    const [label2, setLabel2] = useState(languageCreateClient.agreementMethodFormLabel2);
    useEffect(() => {
        if (!isShowLabel) {
            setLabel('');
            setLabel2('');
        }
    }, []);

    useEffect(() => {
        if (!isFixed) {
            var errorFlag = true;
            Object.keys(agreementMethods).forEach((key) => {
                if (agreementMethods[key]) {
                    errorFlag = false;
                }
            })
            if (errorFlag) {
                setAgreementMethodError(languageCreateClient.agreementMethodEmptyLabel);
            } else {
                setAgreementMethodError('')
            }
        }
    }, [agreementMethods]);

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ alignItems: 'center', display: 'flex' }}></Box>
                </Grid>
            </Grid>

            <Formik
                initialValues={agreementMethods}
                validationSchema={Yup.object().shape({
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid mt={4} container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} container alignItems="center" justifyContent="center">
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="subtitle1">{label}</Typography>
                                    <Typography variant="subtitle1">{label2}</Typography>
                                </Box>
                            </Grid>
                            <Grid container direction="column" justifyContent="center">
                                {agreementMethodTemplate.map((option) => {
                                    if (option.id != 1) {
                                        return (
                                            <FormControlLabel
                                                key={option.id}
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            isFixed
                                                                ? fixedClient.client.agreement_method[option.id_str]
                                                                : agreementMethods[option.id]
                                                        }
                                                        disabled={isFixed ? true : false}
                                                        onChange={(e) =>
                                                            setAgreementMethods((state) => {
                                                                return {
                                                                    ...state,
                                                                    [option.id]: e.target.checked
                                                                };
                                                            })
                                                        }
                                                        name={String(option.id)}
                                                    />
                                                }
                                                label={getLabelCenter(theme, option, customization.language)}
                                            />
                                        );
                                    } else {
                                        return (
                                            <></>
                                            // <FormControlLabel
                                            //     key={option.id}
                                            //     control={
                                            //         <Checkbox
                                            //             checked={
                                            //                 isFixed
                                            //                     ? fixedClient.client.agreement_method[option.id_str]
                                            //                     : agreementMethods[option.id]
                                            //             }
                                            //             disabled={true}
                                            //             onChange={(e) =>
                                            //                 setAgreementMethods((state) => {
                                            //                     return {
                                            //                         ...state,
                                            //                         [option.id]: e.target.checked
                                            //                     };
                                            //                 })
                                            //             }
                                            //             name={String(option.id)}
                                            //         />
                                            //     }
                                            //     label={getLabelCenter(theme, option, customization.language)}
                                            // />
                                        );

                                    }
                                })}
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default RequestingMethodForm;
