import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useLanguageProfile = () => {
    const japaneseContent = {
        greeting: 'こんにちは、',
        youAreIn: '閲覧中のテナント ',
        tenantsListTitle: '所属テナント一覧',
        accountSettingTitle: 'アカウント設定',
        logoutTitle: 'ログアウト'
    };
    const englishContent = {
        greeting: 'Hello, ',
        youAreIn: 'You are in',
        tenantsListTitle: 'Your Tenants',
        accountSettingTitle: 'Account Settings',
        logoutTitle: 'Logout'
    };
    const [content, setContent] = useState(japaneseContent);
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        if (customization.language == 'japanese') {
            setContent(japaneseContent);
        } else if (customization.language == 'english') {
            setContent(englishContent);
        }
    }, [customization]);

    return content;
};

export { useLanguageProfile };
