// action - state management
import * as actionTypes from './actions';

export const initialState = {
    tenant: { id: '', name: '' },
    tenants: [],
    members: []
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const tenantReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TENANT:
            return {
                ...state,
                tenant: action.tenant
            };
        case actionTypes.SET_TENANTS:
            return {
                ...state,
                tenants: action.tenants
            };
        case actionTypes.SET_MEMBERS:
            return {
                ...state,
                members: action.members
            };
        case actionTypes.SET_TENANT_RESET:
            return initialState;
        default:
            return state;
    }
};

export default tenantReducer;
