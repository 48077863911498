import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Button, CardActions, CardContent, Divider, Grid, Menu, MenuItem, Typography } from '@mui/material';

// project imports
import BajajAreaChartCard from './BajajAreaChartCard';
import MainCard from 'ui-component/cards/MainCard';
import SkeletonPopularCard from 'ui-component/cards/Skeleton/PopularCard';
import { gridSpacing } from 'store/constant';
import { api } from 'utils/axios';

// assets
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import DetailViewGraphCard from './DetailViewGraphCard';
import { capitalizeFirstLetter } from 'utils/label_utils';
import { useLanguageDashboard } from 'language/dashboard';

import Pusher from 'pusher-js';

// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const PopularCard = ({ isLoading }) => {
    const theme = useTheme();
    const chartDataDetailAgreement = useSelector((state) => state.chartData.chartDataDetailAgreement);

    const client = useSelector((state) => state.client);

    const [chartDataDetailAgreementToShow, setChartDataDetailAgreementToShow] = useState(chartDataDetailAgreement);

    const [anchorEl, setAnchorEl] = useState(null);
    const [timeRange, setTimeRange] = useState('today');

    const languageDashboard = useLanguageDashboard();

    useEffect(() => {
        setData('today');
    }, [client]);


    useEffect(() => {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
            cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        });

        const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_CHANNEL_NAME);

        channel.bind(process.env.REACT_APP_PUSHER_EVENT_NAME_AGREEMENT_DONE, data => {
            setData("today")
        });

        return () => {
            pusher.unsubscribe(process.env.REACT_APP_PUSHER_CHANNEL_NAME);
        };
    }, [])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setData = async (timeRange) => {
        setTimeRange(timeRange);
        var newChartDataDetailAgreement = {
            options: chartDataDetailAgreement.options,
            height: chartDataDetailAgreement.height,
            type: chartDataDetailAgreement.type
        };
        if (client == null || client.client == null) {
            return;
        } else {
            const params = { client_id: client.client.client_id };
            if (timeRange == 'today') {
                const endPoint = '/logs/count/day/client';
                const data = await api.post(endPoint, params);
                newChartDataDetailAgreement['series'] = [{ data: data.result }];
                setChartDataDetailAgreementToShow(newChartDataDetailAgreement);
            } else if (timeRange == 'week') {
                const endPoint = '/logs/count/week/client';
                const data = await api.post(endPoint, params);
                newChartDataDetailAgreement['series'] = [{ data: data.result }];
                setChartDataDetailAgreementToShow(newChartDataDetailAgreement);
            } else if (timeRange == 'month') {
                const endPoint = '/logs/count/month/client';
                const data = await api.post(endPoint, params);
                newChartDataDetailAgreement['series'] = [{ data: data.result }];
                setChartDataDetailAgreementToShow(newChartDataDetailAgreement);
            }
        }
    };

    const handleSelect = (value) => {
        setData(value);
        setAnchorEl(null);
    };

    return (
        <>
            {isLoading ? (
                <SkeletonPopularCard />
            ) : (
                <MainCard content={false}>
                    <CardContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Grid container alignItems="center" justifyContent="space-between" display={'flex'}>
                                    <Grid item>
                                        <Grid container alignItems={'center'}>
                                            <Grid item>
                                                <Typography variant="h4">{languageDashboard.trendViewTitle}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h5" ml={2}>
                                                    {capitalizeFirstLetter(timeRange)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <MoreHorizOutlinedIcon
                                            fontSize="small"
                                            sx={{
                                                color: theme.palette.primary[400],
                                                cursor: 'pointer',
                                                "&:hover": {
                                                    transform: 'scale(1.2)', // Increase the scale as desired
                                                    color: theme.palette.primary[600]
                                                },
                                            }}
                                            aria-controls="menu-popular-card"
                                            aria-haspopup="true"
                                            onClick={handleClick}
                                        />
                                        <Menu
                                            id="menu-popular-card"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                            variant="selectedMenu"
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right'
                                            }}
                                        >
                                            <MenuItem onClick={(e) => handleSelect('today')}>{languageDashboard.trendViewToday}</MenuItem>
                                            <MenuItem onClick={(e) => handleSelect('week')}>{languageDashboard.trendViewThisWeek}</MenuItem>
                                            <MenuItem onClick={(e) => handleSelect('month')}>
                                                {languageDashboard.trendViewThisMonth}
                                            </MenuItem>
                                        </Menu>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ pt: '16px !important' }}>
                                <DetailViewGraphCard
                                    title={languageDashboard.trendViewAgreementTitle}
                                    chartData={chartDataDetailAgreementToShow}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </MainCard>
            )}
        </>
    );
};

PopularCard.propTypes = {
    isLoading: PropTypes.bool
};

export default PopularCard;
