import { Fragment } from 'react';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    Chip,
    Grid,
    Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import GroupWorkIcon from '@mui/icons-material/GroupWork';


// assets
import { IconCalendarPlus } from '@tabler/icons';
import PaidIcon from '@mui/icons-material/Paid';

import { useLanguageProfile } from 'language/profile';
import { useLanguageHeader } from 'language/header';
import { plan_id_to_name } from 'utils/plan_utils';


// ==============================|| PROFILE MENU ||============================== //

const TypographyWrapper = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    '&:hover': {
        color: 'white'
    }
}));

const checkIfCurrentUserIsOwner = (user, members) => {
    var flag = false;
    members.forEach((member) => {
        if (member.role == 'owner') {
            if (member.email == user.email) {
                flag = true;
            }
        }
    });
    return flag;
};

const PlanSection = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);

    const [membersToShow, setMembersToShow] = useState([]);

    const [sdm, setSdm] = useState(true);
    const [value, setValue] = useState('');
    const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const [tenants, setTenants] = useState([]);
    const tenant = useSelector((state) => state.tenant);
    const [planName, setPlanName] = useState("");
    const dispatch = useDispatch();

    const [isMemberReachedMax, setIsMemberReachedMax] = useState(false);
    const [removeConfirmataionOpen, setRemoveConfirmationOpen] = useState(false);
    const [maxMemberForPlan, setMaxMemberForPlan] = useState(null);
    const [deleteTenantConfirmationOpen, setDeleteTenantConfirmationOpen] = useState(false);
    const handleDeleteTenantConfirmationClose = () => setDeleteTenantConfirmationOpen(false);
    const deleteTenantClicked = () => {
        setDeleteTenantConfirmationOpen(true);
    };

    const removeUserClicked = async (member) => {
        setRemoveConfirmationOpen(true);
    };

    const inviteMemberClicked = () => {
        setOpen(true);
    };

    const checkIfMemberExceedMax = () => {
        if (tenant != undefined) {
        }
        if (tenant.members.length >= maxMemberForPlan) {
            setIsMemberReachedMax(true);
        }
    }

    useEffect(() => {
        if (tenant != null) {
            planIdToName(tenant.tenant.plan)
            if (tenant.members) {
                setMembersToShow(tenant.members);
            }
        }
    }, [tenant]);

    const languageProfile = useLanguageProfile();
    const languageHeader = useLanguageHeader();

    const chipErrorSX = {
        height: 24,
        padding: '0 4px',
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };

    const planIdToName = (planId) => {
        const plan_name = plan_id_to_name(planId)
        setPlanName(plan_name)
    };

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    return (
        <>
            <Box sx={{ mx: 1 }}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <Fragment>
                            <Button
                                {...bindTrigger(popupState)}
                            >
                                <Chip
                                    sx={{
                                        paddingX: 2,
                                        mx: 1,
                                        height: '48px',
                                        alignItems: 'center',
                                        borderRadius: '27px',
                                        transition: 'all .2s ease-in-out',
                                        borderColor: theme.palette.primary.light,
                                        backgroundColor: theme.palette.primary.light,
                                        '&[aria-controls="menu-list-grow"], &:hover': {
                                            borderColor: theme.palette.primary.main,
                                            background: `${theme.palette.primary.main}!important`,
                                            color: theme.palette.primary.light,
                                            '& svg': {
                                                stroke: theme.palette.primary.light
                                            }
                                        },
                                        '& .MuiChip-label': {
                                            lineHeight: 0
                                        }
                                    }}
                                    icon={
                                        <GroupWorkIcon />
                                    }
                                    //label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                                    label={<Typography>{planName}</Typography>}
                                    variant="outlined"
                                    ref={anchorRef}
                                    aria-controls={open ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                    color="primary"
                                />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                    onClick={() => {
                                        popupState.close();
                                    }}
                                    disabled={checkIfCurrentUserIsOwner(user, membersToShow) ? false : true}
                                >
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Grid item sx={{ mr: 1 }}>
                                            <IconCalendarPlus sx={{ ml: 0, mr: 1, mt: 0.4 }} />
                                        </Grid>
                                        {checkIfCurrentUserIsOwner(user, membersToShow) ? (
                                            <Link to={'/plan'} style={{ textDecoration: 'none' }}>
                                                <Grid item>{languageHeader.planTableShowTitle}</Grid>
                                            </Link>
                                        ) : (
                                            <Grid item>{languageHeader.onlyOwnerCanChangePlan}</Grid>
                                        )}
                                    </Grid>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        popupState.close();
                                    }}
                                    disabled={checkIfCurrentUserIsOwner(user, membersToShow) ? false : true}
                                >
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Grid item>
                                            <PaidIcon sx={{ ml: 0, mr: 1, mt: 0.4 }} />
                                        </Grid>
                                        {checkIfCurrentUserIsOwner(user, membersToShow) ? (
                                            <a
                                                href={process.env.REACT_APP_STRIPE_BILLING_DASHBOARD_URL}
                                                target="_blank"
                                                style={{ textDecoration: 'none' }}
                                            >
                                                <Grid item>{languageHeader.billingManagement}</Grid>
                                            </a>
                                        ) : (
                                            <Grid item>{languageHeader.onlyOwnerCanSeeBilling}</Grid>
                                        )}
                                    </Grid>
                                </MenuItem>
                            </Menu>
                        </Fragment>
                    )}
                </PopupState>
            </Box >
        </>
    );
};

export default PlanSection;
