import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

import { gridSpacing } from 'store/constant';
import ClientCard from './ClientCard';
import AddClientCard from './AddClientCard';
import { api } from 'utils/axios';
import { SET_CLIENT, SET_CLIENTS, SET_LOADING, SET_LOG_ID, SET_NOT_LOADING } from 'store/actions';
import { useLanguageTenantTop } from 'language/tenantTop';
import { plan_id_to_max_client_for_plan } from 'utils/plan_utils';

const TenantTop = () => {
    const theme = useTheme();
    const [isLoading, setLoading] = useState(true);
    const [clients, setClients] = useState([]);
    const tenant = useSelector((state) => state.tenant);
    const client = useSelector((state) => state.client);
    const dispatch = useDispatch();

    const getClients = async () => {
        const params = { tenant_id: tenant.tenant.id };
        dispatch({ type: SET_LOADING });
        const res = await api.post('/clients/tenant', params);
        dispatch({ type: SET_CLIENTS, clients: res.result })
        dispatch({ type: SET_NOT_LOADING });
        dispatch({ type: SET_LOG_ID, id: null })
        setClients(res.result);
    };

    useEffect(() => {
        setLoading(false);
        if (tenant != null) {
            getClients();
        }
    }, [tenant]);

    useEffect(() => {
        setLoading(false);
        if (tenant != null) {
            getClients();
        }
    }, []);

    // check if client number and maximum client in this plan

    const [isClientsReachedMax, setIsClientsReachedMax] = useState(false);
    const [maxClientsForPlan, setMaxClientsForPlan] = useState(null);

    const checkIfClientsExceedMax = () => {
        if (client != undefined) {
        } else {
            if (client.clients != undefined && client.clients != null) {
                if (client.clients.length >= maxClientsForPlan) {
                    setIsClientsReachedMax(true);
                }
            }
        }
    };


    useEffect(() => {
        if (maxClientsForPlan != null) {
            checkIfClientsExceedMax();
        }
    }, [client.clients, maxClientsForPlan]);

    useEffect(() => {
        if (tenant != null) {
            const max_client = plan_id_to_max_client_for_plan(tenant.tenant.plan)
            setMaxClientsForPlan(max_client)
        }
    }, [tenant]);

    const clientClickedHandler = (client) => {
        dispatch({ type: SET_CLIENT, client });
    };

    const languageTenantTop = useLanguageTenantTop();

    return (
        <Grid container justifyContent="center" alignItems="center" spacing={gridSpacing} sx={{ mt: 0 }}>
            <Grid item xs={6}>
                <Typography variant="h3" sx={{ color: theme.palette.primary.main, pb: 4 }}>
                    {languageTenantTop.title}
                </Typography>
                <Grid container spacing={gridSpacing}>
                    {isClientsReachedMax ? (
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ cursor: 'not-allowed' }}>
                            <AddClientCard isLoading={isLoading} isDisable={true} />
                            {/* <AddClientCard isLoading={isLoading} /> */}
                        </Grid>

                    ) : (

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Link to={'/create/client'}>
                                <AddClientCard isLoading={isLoading} />
                            </Link>
                            {/* <AddClientCard isLoading={isLoading} /> */}
                        </Grid>
                    )}
                    {clients.map((client) => {
                        return (
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Link style={{ textDecoration: 'none' }} to={'/dashboard'} onClick={() => clientClickedHandler(client)}>
                                    <ClientCard client={client} isLoading={isLoading} />
                                </Link>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TenantTop;
