import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';

// assets
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { getAgreementMethodByIdStr, getLabel } from 'utils/label_utils';
import { useLanguageDashboard } from 'language/dashboard';

// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${theme.palette.primary[200]} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${theme.palette.primary[200]} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

// ==============================|| DASHBOARD - TOTAL INCOME DARK CARD ||============================== //

const AgreementMethodCard = ({ isLoading, agreement_method, isBorder = false, isEdit = true }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const client = useSelector((state) => state.client);

    const [agreementMethodToShow, setAgreementMethodToShow] = useState();

    const customization = useSelector((state) => state.customization);

    useEffect(() => {
        if (agreement_method) {
            setAgreementMethodToShow(agreement_method);
        } else {
            setAgreementMethodToShow(client.client.agreement_method);
        }
    }, [client]);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const languageDashboard = useLanguageDashboard();

    return (
        <>
            {isLoading ? (
                <TotalIncomeCard />
            ) : (
                <CardWrapper border={isBorder ? true : false} content={false}>
                    <Box sx={{ p: 2 }}>
                        <Grid container direction="column">
                            <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1.25, mt: 1 }}>
                                <Grid item lg={2} md={2} sm={2}>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.largeAvatar,
                                            backgroundColor: theme.palette.primary[800],
                                            color: '#fff'
                                        }}
                                    >
                                        <TableChartOutlinedIcon fontSize="inherit" />
                                    </Avatar>
                                </Grid>
                                <Grid item lg={10} md={10} sm={10} pr={2.5}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography
                                                sx={{
                                                    fontSize: '1rem',
                                                    fontWeight: 500,
                                                    color: theme.palette.grey[500]
                                                }}
                                            >
                                                {languageDashboard.agreementMethodsCardTitle}
                                            </Typography>
                                        </Grid>
                                        {isEdit && (
                                            <Grid item>
                                                <MoreHorizOutlinedIcon
                                                    fontSize="small"
                                                    sx={{
                                                        color: theme.palette.primary[400],
                                                        cursor: 'pointer',
                                                        "&:hover": {
                                                            transform: 'scale(1.2)', // Increase the scale as desired
                                                            color: theme.palette.primary[600]
                                                        },
                                                    }}
                                                    aria-controls="menu-popular-card"
                                                    aria-haspopup="true"
                                                    onClick={handleClick}
                                                />
                                                <Menu
                                                    id="menu-popular-card"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleClose}
                                                    variant="selectedMenu"
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right'
                                                    }}
                                                >
                                                    <Link to={'/dashboard/edit/agreement_method'} style={{ textDecoration: 'none' }}>
                                                        <MenuItem onClick={handleClose}>
                                                            {languageDashboard.agreementMethodsCardEdit}
                                                        </MenuItem>
                                                    </Link>
                                                </Menu>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item mt={2.0}>
                                {agreementMethodToShow &&
                                    Object.keys(agreementMethodToShow).map((key) => {
                                        if (client.client.agreement_method[key]) {
                                            const tmp = getAgreementMethodByIdStr(key);
                                            return getLabel(theme, tmp, customization.language);
                                        }
                                    })}
                            </Grid>
                        </Grid>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

AgreementMethodCard.propTypes = {
    isLoading: PropTypes.bool
};

export default AgreementMethodCard;
