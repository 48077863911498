// action - state management
import * as actionTypes from './actions';

export const initialState = {
    isShow: false,
    id: "",
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const showLogReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LOG_ID:
            return {
                ...state,
                id: action.id,
            };
        case actionTypes.SET_SHOW_LOG:
            return {
                ...state,
                isShow: true,
            };
        case actionTypes.SET_NOT_SHOW_LOG:
            return {
                ...state,
                isShow: false,
            };
        default:
            return state;
    }
};

export default showLogReducer;
