import Pusher from 'pusher-js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Buffer } from 'buffer';

// material-ui
import {
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
    TextField
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import RequestingInfoCardWithData from './RequestingCardInfoWithData';
import EarningCard from './EarningCard';
import PopularCard from './PopularCard';
import TotalOrderLineChartCard from './TotalOrderLineChartCard';
import TotalIncomeDarkCard from './TotalIncomeDarkCard';
import TotalIncomeLightCard from './TotalIncomeLightCard';
import TotalGrowthBarChart from './TotalGrowthBarChart';
import { gridSpacing } from 'store/constant';
import AgreementMethodCard from './AgreementMethodCard';
import RequestingInfoCard from './RequestingInfoCard';
import BasicInfoCard from './BasicInfoCard';
import ContentCard from './ContentCard';
import ClientServiceUrlCard from './ClientServiceUrlCard';
import CallbackUrlCard from './CallbackUrlCard';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useLanguageReceiptDashboard } from 'language/receiptDashboard';
import { SET_LOADING, SET_NOT_LOADING, SET_NOT_SHOW_LOG, SET_SHOW_LOG, SET_LOG_ID } from 'store/actions';
import { api } from 'utils/axios';
import { useDeleteClient, useLanguageDeleteClient } from 'language/deleteClient';
import CustomInfoCard from './CustomInfoCard';

// third-party
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from 'react-time-ago';
import ja from 'javascript-time-ago/locale/ja.json';
TimeAgo.addLocale(ja);

// ==============================|| DEFAULT DASHBOARD ||============================== //

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    borderRadius: '4%',
    border: 'none',
    boxShadow: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    p: 4
};

const StyledModal = styled('div')({
    color: 'darkslategray',
    backgroundColor: 'aliceblue',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    padding: 24,
    borderRadius: 4
});

const LogModal = () => {
    const client = useSelector((state) => state.client);
    const showLog = useSelector((state) => state.log);

    const [open, setOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [faceImageUrl, setFaceImageUrl] = useState(null);

    const [clientId, setClientId] = useState('');
    const [logId, setLogId] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [userId, setUserId] = useState('');
    const [agreementMethod, setAgreementMethod] = useState();
    const [requestingInfo, setRequestingInfo] = useState();
    const [userData, setUserData] = useState();
    const [content, setContent] = useState('');

    const languageReceiptDashboard = useLanguageReceiptDashboard();

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch({ type: SET_NOT_SHOW_LOG })
    };

    const getLog = async () => {
        if (client.client.client_id != null && showLog.id != null) {
            const params = { client_id: client.client.client_id, log_id: showLog.id };
            const endPoint = '/logs/detail';
            dispatch({ type: SET_LOADING });
            const data = await api.post(endPoint, params);
            if (data.result.signature != undefined) {
                const tmp = `data:image/jpg;base64,${data.result.signature}`;
                setImageUrl(tmp);
            }
            if (data.result.face_picture != undefined) {
                const tmp = `data:image/jpg;base64,${data.result.face_picture}`;
                setFaceImageUrl(tmp);
            }
            setClientId(data.result.client_id);
            setLogId(data.result.log_id);
            setCreatedAt(data.result.created_at);
            setUserId(data.result.user_id);
            setAgreementMethod(data.result.agreement_method);
            setRequestingInfo(data.result.requesting_info);
            setContent(data.result.content);
            setUserData(data.result.user_info);
            dispatch({ type: SET_NOT_LOADING });

        }

    }


    useEffect(() => {
        getLog()
    }, [showLog])


    return (
        <Modal
            sx={{ overflow: 'auto' }}
            open={showLog.isShow}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <StyledModal>
                <Grid sx={{ p: 1 }} container display="flex" direction="column">
                    <Grid item mb={1}>
                        <Button onClick={handleClose}>{languageReceiptDashboard.closeModal}</Button>
                    </Grid>
                    <MainCard title={languageReceiptDashboard.modalReceiptInfoLabel} sx={{ borderRadius: 2 }}>
                        <Grid container direction="row">
                            <Grid item xs={2} sx={{ p: 1, m: 1 }}>
                                <SubCard title={languageReceiptDashboard.modalReferenceIdLabel}>{logId}</SubCard>
                            </Grid>
                            <Grid item xs={2} sx={{ p: 1, m: 1 }}>
                                <SubCard title={languageReceiptDashboard.modalCreatedAtLabel}>
                                    <Grid container direction="column">
                                        <Grid item> {createdAt && <ReactTimeAgo date={createdAt} locale="ja" />} </Grid>
                                        <Grid item>{createdAt}</Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                            <Grid item xs={2} sx={{ p: 1, m: 1 }}>
                                <SubCard title={languageReceiptDashboard.modalClientIdLabel}>{clientId}</SubCard>
                            </Grid>
                            <Grid item xs={2} sx={{ p: 1, m: 1 }}>
                                <SubCard title={languageReceiptDashboard.modalUserIdLabel}>{userId}</SubCard>
                            </Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid item xs={3} sx={{ p: 1, m: 1 }}>
                                <AgreementMethodCard agreement_method={agreementMethod} isBorder={true} isEdit={false} />
                            </Grid>
                            <Grid item xs={3} sx={{ p: 1, m: 1 }}>
                                <RequestingInfoCardWithData
                                    requesting_info={requestingInfo}
                                    user_data={userData}
                                    isBorder={true}
                                    isEdit={false}
                                />
                            </Grid>
                            <Grid item xs={3} sx={{ p: 1, m: 1 }}>
                                <ContentCard content={content} isBorder={true} isEdit={false} />
                            </Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid item xs={3} sx={{ p: 1, m: 1 }}>
                                <SubCard sx={{ mb: 10 }} title={languageReceiptDashboard.modalPhotoLabel}>
                                    {faceImageUrl ?
                                        <img width={'40%'} src={faceImageUrl} alt="log_face_image" />
                                        :
                                        <></>
                                    }
                                </SubCard>
                            </Grid>
                            <Grid item xs={6} sx={{ p: 1, m: 1 }}>
                                <SubCard sx={{ mb: 10 }} title={languageReceiptDashboard.modalSignatureLabel}>
                                    <Grid item sx={{ boxShadow: 1, borderRadius: 2 }}>
                                        {imageUrl ?
                                            <img width={'20%'} src={imageUrl} alt="log_signature" />
                                            :
                                            <></>
                                        }
                                    </Grid>
                                </SubCard>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
            </StyledModal>
        </Modal>
    );
};

export default LogModal;
