import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';

// third-party
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';

// project imports
import SkeletonTotalGrowthBarChart from 'ui-component/cards/Skeleton/TotalGrowthBarChart';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { api } from 'utils/axios';
import { SET_CHART_DATA } from 'store/actions';
import { sumList } from 'utils/label_utils';
import { useLanguageDashboard } from 'language/dashboard';

import Pusher from 'pusher-js';

// chart data
//import chartData from './chart-data/total-growth-bar-chart';
var chartData = {
    height: 480,
    type: 'bar',
    options: {
        chart: {
            id: 'bar-chart',
            stacked: true,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%'
            }
        },
        xaxis: {
            type: 'category',
            //categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            categories: ['default']
        },
        legend: {
            show: true,
            fontSize: '14px',
            fontFamily: `'Roboto', sans-serif`,
            position: 'bottom',
            offsetX: 20,
            labels: {
                useSeriesColors: false
            },
            markers: {
                width: 16,
                height: 16,
                radius: 5
            },
            itemMargin: {
                horizontal: 15,
                vertical: 8
            }
        },
        fill: {
            type: 'solid'
        },
        dataLabels: {
            enabled: false
        },
        grid: {
            show: true
        }
    },
    series: [
        {
            name: 'Agreement',
            data: [0]
        }
        // {
        //     name: 'Signup',
        //     data: [0]
        // },
        // {
        //     name: 'Login',
        //     data: [0]
        // }
        // {
        //     name: 'Maintenance',
        //     data: [0, 0, 75, 0, 0, 115, 0, 0, 0, 0, 150, 0]
        // }
    ]
};

const TotalGrowthBarChart = ({ isLoading }) => {
    const [value, setValue] = useState('today');
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const client = useSelector((state) => state.client);
    const chartData = useSelector((state) => state.chartData.chartDataTotal);
    const [chartDataToShow, setChartDataToShow] = useState(chartData);

    const [totalAgreement, setTotalAgreement] = useState(0);
    // const [totalSignup, setTotalSignup] = useState(0);
    // const [totalLogin, setTotalLogin] = useState(0);

    const { navType } = customization;
    const { primary } = theme.palette.text;
    const darkLight = theme.palette.dark.light;
    const grey200 = theme.palette.grey[200];
    const grey500 = theme.palette.grey[500];

    const primary200 = theme.palette.primary[200];
    const primaryDark = theme.palette.primary.dark;
    const secondaryMain = theme.palette.secondary.main;
    const secondaryLight = theme.palette.secondary.light;

    const languageDashboard = useLanguageDashboard();

    const status = [
        {
            value: 'today',
            label: languageDashboard.totalChartTodayLabel
        },
        {
            value: 'week',
            label: languageDashboard.totalChartThisWeekLabel
        },
        {
            value: 'month',
            label: languageDashboard.totalChartThisMonthLabel
        }
    ];

    const configChartAxis = async () => {
        let currentDate = new Date();
        var axis = [];
        var data = [];
        if (value == 'today') {
            const params = { client_id: client.client.client_id };
            data = await api.post('/logs/count/day/client', params);
            for (var i = 1; i < 12; i++) {
                let xAgo = new Date(currentDate.getTime() - 60 * 60 * 1000 * 2 * (12 - i));
                axis.push(xAgo.getHours().toString());
            }
        } else if (value == 'week') {
            const params = { client_id: client.client.client_id };
            data = await api.post('/logs/count/week/client', params);
            for (var i = 1; i < 7; i++) {
                let xAgo = new Date(currentDate.getTime() - 60 * 60 * 1000 * 24 * (7 - i));
                axis.push(xAgo.getDate().toString());
            }
        } else if (value == 'month') {
            const params = { client_id: client.client.client_id };
            data = await api.post('/logs/count/month/client', params);
            for (var i = 0; i < 5; i++) {
                let xAgo = new Date(currentDate.getTime() - 60 * 60 * 1000 * 24 * (30 - i * 5));
                axis.push(xAgo.getDate().toString());
            }
        }
        if (chartData == undefined) {
            console.log("undefined")
            return;
        } else {
            chartData.options.xaxis.categories = axis;
            chartData.series[0].data = data.result;
            setTotalAgreement(sumList(data.result));
            setChartDataToShow(chartData);

            // do not load chart when loading
            if (!isLoading) {
                console.log("chart exec")
                ApexCharts.exec(`bar-chart`, 'updateOptions', chartDataToShow);
            }
        }
    };

    useEffect(() => {
        configChartAxis();
    }, [value]);

    useEffect(() => {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
            cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        });

        const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_CHANNEL_NAME);

        channel.bind(process.env.REACT_APP_PUSHER_EVENT_NAME_AGREEMENT_DONE, data => {
            configChartAxis()
        });

        return () => {
            pusher.unsubscribe(process.env.REACT_APP_PUSHER_CHANNEL_NAME);
        };
    }, [])


    return (
        <>
            {isLoading ? (
                <SkeletonTotalGrowthBarChart />
            ) : (
                <MainCard>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Grid container direction="column" spacing={1} justifyContent={'center'}>
                                        <Grid item mb={1}>
                                            <Typography variant="subtitle2">{languageDashboard.totalStatement}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" spacing={1}>
                                                <Grid item>
                                                    <Grid container justifyContent={'center'} alignItems={'center'}>
                                                        <Grid item>
                                                            <Typography variant="h4"> {totalAgreement}</Typography>
                                                        </Grid>
                                                        <Grid item ml={1}>
                                                            <Typography variant="subtitle2">
                                                                {languageDashboard.totalChartAgreementNumber}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="standard-select-currency"
                                        select
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                    >
                                        {status.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Chart {...chartDataToShow} />
                        </Grid>
                    </Grid>
                </MainCard>
            )}
        </>
    );
};

TotalGrowthBarChart.propTypes = {
    isLoading: PropTypes.bool
};

export default TotalGrowthBarChart;
