// action - state management
import * as actionTypes from './actions';

export const initialState = {
    isLoading: false,
    message: ''
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: true,
                message: action.message
            };
        case actionTypes.SET_NOT_LOADING:
            return {
                ...state,
                isLoading: false,
                message: ''
            };
        default:
            return state;
    }
};

export default loadingReducer;
