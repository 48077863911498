import Pusher from 'pusher-js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Grid, Skeleton, Typography } from '@mui/material';
import { SET_LOADING, SET_NOT_LOADING, SET_TENANTS, SET_LOG_ID, SET_SHOW_LOG } from 'store/actions';

// project imports
import EarningCard from './EarningCard';
import PopularCard from './PopularCard';
import TotalOrderLineChartCard from './TotalOrderLineChartCard';
import TotalIncomeDarkCard from './TotalIncomeDarkCard';
import TotalIncomeLightCard from './TotalIncomeLightCard';
import TotalGrowthBarChart from './TotalGrowthBarChart';
import { gridSpacing } from 'store/constant';
import AgreementMethodCard from './AgreementMethodCard';
import RequestingInfoCard from './RequestingInfoCard';
import BasicInfoCard from './BasicInfoCard';
import QRElement from './QRElement';
import QRForFormElement from './QRForFormElement';
import MainCard from 'ui-component/cards/MainCard';
import LogModal from './LogModal';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const QRDashboard = () => {
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();
    const client = useSelector((state) => state.client);
    const customization = useSelector((state) => state.customization);

    const [isShowForm, setIsShowForm] = useState(false);
    const showLog = useSelector((state) => state.log);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(false);
        if (client.client == null) {
            navigate('/top');
        }
    }, []);

    // Google Form
    const [qrValue, setQrValue] = useState('');

    useEffect(() => {
        if (client == null || client.client == null) {
            return;
        } else {
            var tmp = process.env.REACT_APP_DYNAMIC_LINKS_AGREEMENT;
            tmp = tmp + `?client_name=${client.client.client_name}`;
            tmp = tmp + `&client_id=${client.client.client_id}`;
            tmp = tmp + `&content=${client.client.content.agreement}`;
            const agreement_method = encodeURIComponent(JSON.stringify(client.client.agreement_method));
            tmp = tmp + `&agreement_method=${agreement_method}`;
            const requesting_info = encodeURIComponent(JSON.stringify(client.client.requesting_info));
            tmp = tmp + `&requesting_info=${requesting_info}`;
            tmp = tmp + `&uid=${crypto.randomUUID()} `;
            setQrValue(tmp);
        }
    }, [client]);

    useEffect(() => {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
            cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        });

        const channel = pusher.subscribe(client.client.id);

        channel.bind(process.env.REACT_APP_PUSHER_EVENT_NAME_AGREEMENT_DONE, data => {
            dispatch({ type: SET_LOG_ID, id: data.log_id })
            dispatch({ type: SET_SHOW_LOG });
        });

        return () => {
            pusher.unsubscribe(process.env.REACT_APP_PUSHER_CHANNEL_NAME);
        };
    }, [])

    if (showLog.isShow && customization.isPopUp) {
        return (
            <LogModal />
        )
    } else {

        return (
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Grid container direction="row" spacing={gridSpacing}>
                                <Grid item sm={4} xs={12} md={4} lg={4}>
                                    <BasicInfoCard isLoading={isLoading} isEdit={false} />
                                </Grid>
                                <Grid item sm={4} xs={12} md={4} lg={4}>
                                    <AgreementMethodCard isLoading={isLoading} isEdit={false} />
                                </Grid>
                                <Grid item sm={4} xs={12} md={4} lg={4}>
                                    <RequestingInfoCard isLoading={isLoading} isEdit={false} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={8}>
                            <QRElement isLoading={isLoading} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <PopularCard isLoading={isLoading} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        );
    }

};

export default QRDashboard;
