import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Divider, Box, Grid, List, ListItem, Stack, Typography, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';

// project imports
import { useDispatch, useSelector } from 'react-redux';
import { basicsTemplate, redirectUriTemplate, agreementMethodTemplate, requestingInfoTemplate, contentTemplate } from 'utils/label_utils';
import { CONTENT_STATE } from 'utils/label_utils';
import { api } from 'utils/axios';
import { SET_LOADING, SET_NOT_LOADING, SET_CLIENT } from 'store/actions';

// ==============================|| DEFAULT DASHBOARD ||============================== //
// styles
import MainCard from 'ui-component/cards/MainCard';
import ContentForm from 'views/create-client/ContentForm';
import { useLanguageEditClient } from 'language/editClient';
const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${theme.palette.primary[200]} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${theme.palette.primary[200]} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

// ==============================|| DASHBOARD - TOTAL INCOME DARK CARD ||============================== //

const TitleCard = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const client = useSelector((state) => state.client);

    const languageEditClient = useLanguageEditClient();

    return (
        <CardWrapper border={false} content={false}>
            <Box sx={{ p: 2 }}>
                <List sx={{ py: 0 }}>
                    <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                        <Grid container direction="column" justifyContent="center">
                            <Grid item sx={{ mb: 1.25, mt: 1 }}>
                                <Typography variant="h2">{languageEditClient.editContentTitle}</Typography>
                            </Grid>
                            <Grid container justifyContent={'space-between'} direction="row" sx={{ mb: 1.25, mt: 1, pr: 2.5 }}>
                                <Grid item>
                                    <Typography
                                        sx={{
                                            fontSize: '0.85rem',
                                            fontWeight: 500,
                                            color: theme.palette.grey[500]
                                        }}
                                    >
                                        {languageEditClient.clientIdLabel}
                                    </Typography>
                                </Grid>
                                <Grid item ml={2}>
                                    <Typography
                                        sx={{
                                            fontSize: '0.85rem',
                                            fontWeight: 400,
                                            color: theme.palette.grey[500]
                                        }}
                                    >
                                        {client.client.client_id}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItem>
                </List>
            </Box>
        </CardWrapper>
    );
};

const EditContent = () => {
    const [isLoading, setLoading] = useState(true);
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const client = useSelector((state) => state.client);
    const [content, setContent] = useState(CONTENT_STATE);
    const tenant = useSelector((state) => state.tenant);
    const [contentError, setContentError] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const languageEditClient = useLanguageEditClient();

    useEffect(() => {
        setLoading(false);
    }, []);

    const handleSubmit = async () => {
        const send_content = { agreement: content[0] };

        const params = {
            tenant_id: tenant.tenant.id,
            client_id: client.client.client_id,
            content: send_content
        };
        dispatch({ type: SET_LOADING });
        const res = await api.post('/update_client/content', params);
        dispatch({ type: SET_CLIENT, client: res.result });
        dispatch({ type: SET_NOT_LOADING });
        navigate('/dashboard');
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={6} sx={{ mt: 2 }}>
                <TitleCard></TitleCard>
            </Grid>
            <Grid item xs={10} sx={{ mt: 4 }}>

                <Stack sx={{ ml: 3 }} alignItems="start" justifyContent="center" spacing={1}>
                    <Typography
                        variant="caption"
                        fontSize="14px"
                        textAlign={matchDownSM ? 'center' : 'inherit'}
                    >
                        {languageEditClient.currentSettingTitle}
                    </Typography>
                </Stack>
                <ContentForm
                    contentTemplate={contentTemplate}
                    content={content}
                    setContent={setContent}
                    isFixed={true}
                    isShowLabel={false}
                    contentError={""}
                />
            </Grid>

            <Grid item xs={10} sx={{ mt: 4 }}>
                <Stack sx={{ mt: 4 }} alignItems="start" justifyContent="center" spacing={1}>
                    <Typography
                        variant="caption"
                        fontSize="14px"
                        textAlign={matchDownSM ? 'center' : 'inherit'}
                    >
                        {languageEditClient.changedSettingTitle}
                    </Typography>
                </Stack>
                <ContentForm
                    contentTemplate={contentTemplate}
                    content={content}
                    setContent={setContent}
                    isFixed={false}
                    isShowLabel={false}
                    contentError={contentError}
                    setContentError={setContentError}
                />

            </Grid>

            <Grid item xs={4} sx={{ mt: 4 }}>
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={contentError != ""}>
                    {languageEditClient.buttonLabel}
                </Button>
            </Grid>
        </Grid>
    );
};

export default EditContent;
