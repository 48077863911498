// project imports
import TenantTop from 'views/tenantTop';
import TenantTopLayout from 'layout/TenantTopLayout';
import Plan from 'views/plan';

// ==============================|| MAIN ROUTING ||============================== //

const TenantTopRoutes = {
    path: '/',
    element: <TenantTopLayout />,
    children: [
        {
            path: 'tenant',
            element: <TenantTop />
        },
        {
            path: 'plan',
            element: <Plan />
        }
    ]
};

export default TenantTopRoutes;
