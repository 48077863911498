import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import QRDashboard from 'views/dashboard/Default/QRDashboard';
import EditAgreementMethod from 'views/dashboard/Default/EditAgreementMethod';
import EditRequestingInfo from 'views/dashboard/Default/EditRequestingInfo';
import EditContent from 'views/dashboard/Default/EditContent';
import ReceiptDashboard from 'views/dashboard/Default/ReceiptDashboard';
import EditClientServiceUrl from 'views/dashboard/Default/EditClientServiceUrl';
import EditCallbackUrl from 'views/dashboard/Default/EditCallbackUrl';
import APIManagementDashboard from 'views/dashboard/Default/APIManagementDashboard';
import EditCustomInfo from 'views/dashboard/Default/EditCustomInfo';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/dashboard',
    element: <MainLayout />,
    children: [
        {
            path: '',
            element: <DashboardDefault />
        },
        {
            path: 'qr',
            element: <QRDashboard />
        },
        {
            path: 'receipt',
            element: <ReceiptDashboard />
        },
        {
            path: 'api_management',
            element: <APIManagementDashboard />
        },
        {
            path: 'edit/agreement_method',
            element: <EditAgreementMethod />
        },
        {
            path: 'edit/requesting_info',
            element: <EditRequestingInfo />
        },
        {
            path: 'edit/content',
            element: <EditContent />
        },
        {
            path: 'edit/client_url',
            element: <EditClientServiceUrl />
        },
        {
            path: 'edit/callback_url',
            element: <EditCallbackUrl />
        },
        {
            path: 'edit/custom_info',
            element: <EditCustomInfo />
        }
    ]
};

export default MainRoutes;
