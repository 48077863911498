import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, MenuItem, TextField, Typography, Button, useMediaQuery, Skeleton } from '@mui/material';

// third-party
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
// third party
import { Formik, setIn } from 'formik';

// project imports
import SkeletonTotalGrowthBarChart from 'ui-component/cards/Skeleton/TotalGrowthBarChart';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';

// chart data
import chartData from './chart-data/total-growth-bar-chart';

//qr
import { QRNormal } from 'react-qrbtf';

//print
import { useReactToPrint } from 'react-to-print';
import { useLanguageQRDashboard } from 'language/qrDashboard';
import { api } from 'utils/axios';
import { SET_LOADING, SET_NOT_LOADING } from 'store/actions';


// ==============================|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //

const QRForFormElement = ({ isLoading, setIsShowForm }) => {
    const componentRef = useRef();
    const client = useSelector((state) => state.client);
    const tenant = useSelector((state) => state.tenant);
    const dispatch = useDispatch();

    const [value, setValue] = useState('today');
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const { navType } = customization;
    const { primary } = theme.palette.text;
    const darkLight = theme.palette.dark.light;
    const grey200 = theme.palette.grey[200];
    const grey500 = theme.palette.grey[500];

    const primary200 = theme.palette.primary[200];
    const primaryDark = theme.palette.primary.dark;
    const secondaryMain = theme.palette.secondary.main;
    const secondaryLight = theme.palette.secondary.light;

    const [qrValue, setQrValue] = useState('');
    const [inputUrl, setInputUrl] = useState('');
    const [formRediretUrl, setFormRediretUrl] = useState('');

    const [isShowQr, setIsShowQr] = useState(false);

    useEffect(() => {
        if (client == null || client.client == null) {
            return;
        } else {
            // creating link for qr (form)
            var tmp = `${process.env.REACT_APP_FORM_REDIRECT_URL}/clients/${client.client.client_id}/form`;
            setQrValue(tmp);

            // creating link for redirect after user done with the form (dynamic link)
            tmp = process.env.REACT_APP_DYNAMIC_LINKS_AGREEMENT;
            tmp = tmp + `?client_name=${client.client.client_name}`;
            tmp = tmp + `&client_id=${client.client.client_id}`;
            tmp = tmp + `&content=${client.client.content.agreement}`;
            const agreement_method = encodeURIComponent(JSON.stringify(client.client.agreement_method));
            tmp = tmp + `&agreement_method=${agreement_method}`;
            const requesting_info = encodeURIComponent(JSON.stringify(client.client.requesting_info));
            tmp = tmp + `&requesting_info=${requesting_info}`;
            setFormRediretUrl(tmp);
        }
    }, [client]);

    useEffect(() => {
        const newChartData = {
            ...chartData.options,
            colors: [primary200, primaryDark, secondaryMain, secondaryLight],
            xaxis: {
                labels: {
                    style: {
                        colors: [primary, primary, primary, primary, primary, primary, primary, primary, primary, primary, primary, primary]
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: [primary]
                    }
                }
            },
            grid: {
                borderColor: grey200
            },
            tooltip: {
                theme: 'light'
            },
            legend: {
                labels: {
                    colors: grey500
                }
            }
        };

        // do not load chart when loading
        if (!isLoading) {
            ApexCharts.exec(`bar-chart`, 'updateOptions', newChartData);
        }
    }, [navType, primary200, primaryDark, secondaryMain, secondaryLight, primary, darkLight, grey200, isLoading, grey500]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const languageQRDashboard = useLanguageQRDashboard();

    return (
        <>
            {isLoading ? (
                <SkeletonTotalGrowthBarChart />
            ) : (
                <MainCard>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="h3">{languageQRDashboard.titleForForm}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle2">{languageQRDashboard.subtitleForForm}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container justifyContent="center" alignItems="center">
                                        <Grid item>
                                            <Button variant="outlined" onClick={handlePrint}>
                                                Print
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Formik initialValues={inputUrl}
                                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                    setIsShowQr(true);
                                    setIsShowForm(true);
                                    const params = {
                                        tenant_id: tenant.tenant.id,
                                        client_id: client.client.client_id,
                                        form_url: inputUrl,
                                        form_redirect_url: formRediretUrl,
                                    };
                                    dispatch({ type: SET_LOADING });
                                    const res = api.post('/update_client/form_url', params)
                                    dispatch({ type: SET_NOT_LOADING });
                                }}
                            >
                                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                    <form noValidate onSubmit={handleSubmit}>
                                        <Grid container spacing={matchDownSM ? 0 : 2} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                            <Grid item md={10}>
                                                <TextField
                                                    fullWidth
                                                    label={languageQRDashboard.formUrlLabel}
                                                    margin="normal"
                                                    name={"url"}
                                                    type="text"
                                                    defaultValue=""
                                                    value={inputUrl}
                                                    //value={values.clientName}
                                                    // onChange={(e) => setClientName(e.target.value)}
                                                    onChange={(e) => setInputUrl(e.target.value)}
                                                    sx={{ ...theme.typography.customInput }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Box sx={{ ml: 1 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="secondary"
                                                        >
                                                            登録
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <Grid container direction="row" justifyContent="center">
                                <Grid item xs={4} pl={4} pt={4}>
                                    <Grid container direction="column" justifyContent="center">
                                        <Grid item>
                                            <Grid container direction="column" justifyContent="center">
                                                <Grid item>
                                                    <Typography variant="subtitle2">{languageQRDashboard.formStep1Title}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h4">{languageQRDashboard.formStep1Detail}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item mt={1}>
                                            <Grid container direction="column" justifyContent="center">
                                                <Grid item>
                                                    <Typography variant="subtitle2">{languageQRDashboard.formStep2Title}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h4">{languageQRDashboard.formStep2Detail}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item mt={1}>
                                            <Grid container direction="column" justifyContent="center">
                                                <Grid item>
                                                    <Typography variant="subtitle2">{languageQRDashboard.formStep3Title}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h4">{languageQRDashboard.formStep3Detail}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item mt={1}>
                                            <Grid container direction="column" justifyContent="center">
                                                <Grid item>
                                                    <Typography variant="subtitle2">{languageQRDashboard.formStep4Title}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h4">{languageQRDashboard.formStep4Detail}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {isShowQr ? (
                                    <Grid item xs={6} ref={componentRef}>
                                        <Grid container direction="column" alignItems="center">
                                            <Grid item xs={4} mt={-2}>
                                                <QRNormal value={qrValue} size={84} posColor={'#63e2fd'} otherColor={'#63e2fd'} />
                                            </Grid>
                                            <Grid item mt={-4}>
                                                <Typography variant="subtitle2">Powered by AUTHBLUE</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid item xs={6} ref={componentRef}>
                                        <Grid container direction="column" alignItems="center">
                                            <Grid item xs={4} mt={-2}>
                                                <Skeleton variant="rounded" height={240} width={240} />
                                            </Grid>
                                            <Grid item >
                                                <Typography mt={-16} variant="subtitle2">{languageQRDashboard.waitingFormLinkLabel1}</Typography>
                                                <Typography variant="subtitle2">{languageQRDashboard.waitingFormLinkLabel2}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </MainCard>
            )}
        </>
    );
};

QRForFormElement.propTypes = {
    isLoading: PropTypes.bool
};

export default QRForFormElement;
