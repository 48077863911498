import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

import { useLanguageCreateClient } from 'language/create_client';
import { useEffect } from "react";
import { Formik } from 'formik';

function CustomInfoForm({ template, value, setValue, error = "", isFixed = false }) {
    const theme = useTheme();
    const [data, setData] = useState("");

    const customization = useSelector((state) => state.customization);
    const [language, setLanguage] = useState(customization.language);
    const languageCreateClient = useLanguageCreateClient();

    const client = useSelector((state) => state.client);
    const [fixedClient, setFixedClient] = useState(client);

    useEffect(() => {
        setFixedClient(client)

    }, [client])

    useEffect(() => {
        console.log("here template")
        console.log(template)
    }, [])

    return (
        <Formik initialValues={value}>
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Grid container direction={"column"}>
                        {template.map((el) => {
                            var valueToShow = ""
                            if (isFixed) {
                                if (el.id == 0) {
                                    valueToShow = fixedClient.client.custom_info["title"]
                                } else {
                                    valueToShow = fixedClient.client.custom_info["detail"]
                                }
                            }

                            return (
                                <Grid Grid item >
                                    <TextField
                                        InputProps={{
                                            style: { paddingTop: '20px' },
                                        }}
                                        fullWidth
                                        label={language == 'japanese' ? el.nameJp : el.name}
                                        margin="normal"
                                        name={el.name}
                                        type="text"
                                        defaultValue=""
                                        multiline
                                        maxRows={30}
                                        value={isFixed ? valueToShow : value[el.id]}
                                        error={error != ""}
                                        helperText={error}
                                        disabled={isFixed ? true : false}
                                        //value={values.clientName}
                                        // onChange={(e) => setClientName(e.target.value)}
                                        onChange={(e) =>
                                            setValue((state) => {
                                                return {
                                                    ...state,
                                                    [el.id]: e.target.value
                                                };
                                            })
                                        }
                                        sx={{ ...theme.typography.customInput }}
                                    />
                                </Grid>


                            )

                        })}

                    </Grid>
                </form>
            )
            }
        </Formik >
    );
}
export default CustomInfoForm;
