// react
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Typography, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import { drawerWidth, tmpWidth } from 'store/constant';
import Header from '../MainLayout/Header';

// assets
import { IconChevronRight } from '@tabler/icons';
import Customization from 'layout/Customization';
import { useLanguagePayment } from 'language/payment';

// ==============================|| MINIMAL LAYOUT ||============================== //
// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(tmpWidth - 20),
            width: `calc(100% - ${tmpWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${tmpWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${tmpWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${tmpWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    height: 400,
    bgcolor: 'background.paper',
    borderRadius: '4%',
    border: 'none',
    boxShadow: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    p: 4
};

const TenantTopLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const load = useSelector((state) => state.load);
    const tenant = useSelector((state) => state.tenant);
    const [tenantId, setTenantId] = useState(null);
    const [tenantPlan, setTenantPlan] = useState(null);

    const languagePayment = useLanguagePayment();

    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    useEffect(() => {
        if (user.uuid == '') {
            navigate('/login');
        }
    }, [user]);

    useEffect(() => {
        if (load.isLoading) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [load]);

    useEffect(() => {
        setTenantId(tenant?.tenant.id);
        setTenantPlan(tenant?.tenant.plan);
    }, [tenant]);

    // <Box sx={{ display: 'flex' }}>
    //     <AppBar
    //         enableColorOnDark
    //         position="fixed"
    //         color="inherit"
    //         elevation={0}
    //         sx={{
    //             bgcolor: theme.palette.background.default,
    //             transition: 'none'
    //         }}
    //     ></AppBar>
    //     {/* main content */}
    //     <Main theme={theme}>
    //         {/* breadcrumb */}
    //         <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
    //             <Grid item>
    //                 <Typography variant="h6" color="secondary">
    //                     {languagePayment.noPlanSetWarning}
    //                 </Typography>
    //                 <Typography variant="h6" color="secondary">
    //                     {languagePayment.noPlanSetWarning2}
    //                 </Typography>
    //             </Grid>
    //         </Grid>
    //         {tenantId && (
    //             <stripe-pricing-table
    //                 pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
    //                 publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
    //                 client-reference-id={tenantId}
    //             ></stripe-pricing-table>
    //         )}
    //     </Main>
    //     <Customization />
    //     <Modal
    //         open={open}
    //         onClose={handleClose}
    //         closeAfterTransition
    //         slots={{ backdrop: Backdrop }}
    //         slotProps={{
    //             backdrop: {
    //                 timeout: 500
    //             }
    //         }}
    //     >
    //         <Fade in={open}>
    //             <Box sx={modalStyle}>
    //                 <Typography variant="subtitle" sx={{ color: theme.palette.primary.main, fontWeight: 'medium', mb: 2 }}>
    //                     {load.message}
    //                 </Typography>
    //                 <CircularProgress />
    //             </Box>
    //         </Fade>
    //     </Modal>
    // </Box>
    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: 'none'
                }}
            >
                <Toolbar>
                    <Header isShowTenant={true} />
                </Toolbar>
            </AppBar>
            {/* main content */}
            <Main theme={theme}>
                {/* breadcrumb */}
                <Outlet />
            </Main>
            <Customization />
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500
                    }
                }}
            >
                <Fade in={open}>
                    <Box sx={modalStyle}>
                        <CircularProgress />
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
};

export default TenantTopLayout;
