import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useLanguageDashboard = () => {
    const japaneseContent = {
        trendViewTitle: 'トレンド推移',
        trendViewToday: '今日',
        trendViewThisWeek: '今週',
        trendViewThisMonth: '今月',
        trendViewAgreementTitle: '情報提供',
        totalStatement: '総合推移',
        totalChartAgreementNumber: '情報提供数',
        totalChartTodayLabel: '今日',
        totalChartThisWeekLabel: '今週',
        totalChartThisMonthLabel: '今月',
        contentCardTitle: '情報提供文面内容',
        customInfoCardTitle: 'ユーザから取得する追加情報',
        customInfoCardEdit: '編集',
        contentCardEdit: '編集',
        clientUrlCardTitle: 'クライアントURL',
        clientUrlCardEdit: '編集',
        callbackUrlCardTitle: 'Callback URL',
        callbackUrlCardEdit: '編集',
        agreementMethodsCardTitle: '情報提供方法',
        agreementMethodsCardEdit: '編集',
        requestingInfoCardTitle: '要求情報',
        requestingInfoCardEdit: '編集',
        basicInfoClientIdLabel: 'クライアントID',
        basicInfoClientDeleteLabel: 'クライアントの削除'
    };
    const englishContent = {
        trendViewTitle: 'Trend view',
        trendViewToday: 'Today',
        trendViewThisWeek: 'This week',
        trendViewThisMonth: 'This month',
        trendViewAgreementTitle: 'Agreement',
        totalStatement: 'Total view',
        totalChartAgreementNumber: 'Agreements',
        totalChartTodayLabel: 'Today',
        totalChartThisWeekLabel: 'This week',
        totalChartThisMonthLabel: 'This month',
        contentCardTitle: 'content',
        contentCardEdit: 'Edit',
        customInfoCardTitle: 'Custom Info Required from user',
        customInfoCardEdit: 'Edit',
        clientUrlCardTitle: 'Client Url',
        clientUrlCardEdit: 'Edit',
        callbackUrlCardTitle: 'Callback URL',
        callbackUrlCardEdit: 'Edit',
        agreementMethodsCardTitle: 'Agreement methods',
        agreementMethodsCardEdit: 'Edit',
        requestingInfoCardTitle: 'Requesting info',
        requestingInfoCardEdit: 'Edit',
        basicInfoClientIdLabel: 'Client ID',
        basicInfoClientDeleteLabel: 'Delete Client'
    };
    const [content, setContent] = useState(japaneseContent);
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        if (customization.language == 'japanese') {
            setContent(japaneseContent);
        } else if (customization.language == 'english') {
            setContent(englishContent);
        }
    }, [customization]);

    return content;
};

export { useLanguageDashboard };
