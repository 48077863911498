import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, Typography, useMediaQuery, Button, Skeleton } from '@mui/material';

// project imports
import AuthWrapper1 from '../pages/authentication/AuthWrapper1';
import AuthCardWrapper from '../pages/authentication/AuthCardWrapper';
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import LaunchIcon from '@mui/icons-material/Launch';

import { api } from 'utils/axios';
import { SET_LOADING, SET_MEMBERS, SET_NOTIFICATIONS, SET_NOT_LOADING, SET_TENANT, SET_TENANTS } from 'store/actions';
import { useLanguageTop } from 'language/top';

const Top = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [tenants, setTenants] = useState([]);
    const [userToShow, setUserToShow] = useState(null);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const languageTop = useLanguageTop();

    useEffect(() => {
        setUserToShow(user);
    }, [user]);

    const getTenants = async () => {
        setIsLoading(true);
        const res = await api.get('/tenants/joined');
        setTenants(res.result);
        dispatch({ type: SET_TENANTS, tenants: res.result });
        setIsLoading(false);
    };

    const getNotifications = async () => {
        const res_notifications = await api.get('/notifications');
        dispatch({ type: SET_NOT_LOADING });
        dispatch({ type: SET_NOTIFICATIONS, notifications: res_notifications.result });
    }

    useEffect(() => {
        getTenants();
        getNotifications();
    }, []);

    const goToTenant = async (tenant) => {
        dispatch({ type: SET_TENANT, tenant });
        const params = { tenant_id: tenant.id };
        console.log("here11111")
        console.log(params)

        dispatch({ type: SET_LOADING });
        const res = await api.post('/tenants/members', params);
        dispatch({ type: SET_NOT_LOADING });
        dispatch({ type: SET_MEMBERS, members: res.result });
        navigate('/tenant');
    };

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Link to="#">
                                            <Logo />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant={matchDownSM ? 'h3' : 'h2'}
                                                    >
                                                        {languageTop.title}
                                                    </Typography>
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant={matchDownSM ? 'h3' : 'h2'}
                                                    >
                                                        {userToShow?.email}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        fontSize="16px"
                                                        textAlign={matchDownSM ? 'center' : 'inherit'}
                                                    >
                                                        {tenants.length == 0 ? languageTop.noTenantsYet : languageTop.subtitle}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Grid container direction="column" alignItems="center">
                                            {(() => {
                                                if (!isLoading) {
                                                    return (
                                                        <>
                                                            {
                                                                tenants.map((tenant) => (
                                                                    <Grid item xs={12}>
                                                                        <Button onClick={() => goToTenant(tenant)}>
                                                                            <Grid container direction="row" justifyContent="space-between">
                                                                                <Grid item>
                                                                                    <Typography
                                                                                        variant="h5"
                                                                                        fontSize="16px"
                                                                                        textAlign={matchDownSM ? 'center' : 'inherit'}
                                                                                    >
                                                                                        {tenant.name}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <LaunchIcon />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Button>
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </>
                                                    );
                                                } else {
                                                    return (
                                                        <Skeleton variant="rounded" animation="wave" width={200} height={40} />
                                                    )
                                                }
                                            })()}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item container direction="column" alignItems="center" xs={12}>
                                            <Typography
                                                component={Link}
                                                to="/create/tenant"
                                                variant="subtitle1"
                                                sx={{ textDecoration: 'none' }}
                                            >
                                                {languageTop.footer}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default Top;
