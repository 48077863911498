// project imports
import TopLayout from 'layout/TopLayout';
import Top from 'views/top';

// ==============================|| MAIN ROUTING ||============================== //

const TopRoutes = {
    path: '/',
    element: <TopLayout />,
    children: [
        {
            path: '',
            element: <Top />
        },
        {
            path: '/top',
            element: <Top />
        }
    ]
};

export default TopRoutes;
