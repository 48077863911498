import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import EarningIcon from 'assets/images/icons/earning.svg';
import QrCodeIcon from '@mui/icons-material/QrCode';
import WcIcon from '@mui/icons-material/Wc';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CakeIcon from '@mui/icons-material/Cake';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

import FaceIcon from '@mui/icons-material/Face';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import BadgeIcon from '@mui/icons-material/Badge';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import GetAppTwoToneIcon from '@mui/icons-material/GetAppOutlined';
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyOutlined';
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveOutlined';

import Google from 'assets/images/icons/social-google.svg';
import { useLanguageCreateClient } from 'language/create_client';

function wordCount(text) {
    // Remove leading and trailing white spaces
    text = text.trim();

    // Split the text into an array of words
    let words = text.split(/\s+/);

    // Return the number of words in the array
    return words.length;
}

const ContentForm = ({ contentTemplate, content, setContent, isFixed = false, isShowLabel = true, contentError, setContentError }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const client = useSelector((state) => state.client);
    const [fixedClient, setFixedClient] = useState(client);

    const languageCreateClient = useLanguageCreateClient();

    const customization = useSelector((state) => state.customization);
    const [language, setLanguage] = useState(customization.language);

    useEffect(() => {
        setFixedClient(client);
    }, [client]);

    const defaultLabel = languageCreateClient.contentFormLabel;
    const [label, setLabel] = useState(defaultLabel);
    useEffect(() => {
        if (!isShowLabel) {
            setLabel('');
        }
    }, []);

    useEffect(() => {
        if (!isFixed) {
            var count = 0;
            var threshold = 0;
            if (language == "japanese") {
                count = content[0].length;
                threshold = 400;
            } else {
                count = wordCount(content[0]);
                threshold = 200;
            }
            if (count > threshold) {
                setContentError(languageCreateClient.contentTooLongError);
            } else {
                setContentError('');
            }
        }
    }, [content])

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ alignItems: 'center', display: 'flex' }}></Box>
                </Grid>
            </Grid>

            <Formik initialValues={content}>
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} container alignItems="center" justifyContent="center">
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="subtitle1">{label}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} ml={3}>
                                {contentTemplate.map((option) => {
                                    return (
                                        <TextField
                                            InputProps={{
                                                style: { paddingTop: '20px' },
                                            }}
                                            fullWidth
                                            label={language == 'japanese' ? option.nameJp : option.name}
                                            margin="normal"
                                            name={option.name}
                                            type="text"
                                            defaultValue=""
                                            multiline
                                            maxRows={30}
                                            value={isFixed ? fixedClient.client.content[option.id_str] : content[option.id]}
                                            disabled={isFixed ? true : false}
                                            error={contentError != ""}
                                            helperText={contentError}
                                            //value={values.clientName}
                                            // onChange={(e) => setClientName(e.target.value)}
                                            onChange={(e) =>
                                                setContent((state) => {
                                                    return {
                                                        ...state,
                                                        [option.id]: e.target.value
                                                    };
                                                })
                                            }
                                            sx={{ ...theme.typography.customInput }}
                                        />
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default ContentForm;
