import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useLanguageTenantTop = () => {
    const japaneseContent = {
        title: '情報提供クライアント',
        clientCardContentTitle: '情報提供文面内容',
        clientCardAgreementMethodTitle: '情報提供方法',
    };
    const englishContent = {
        title: 'Agreement Client',
        clientCardContentTitle: 'Content',
        clientCardAgreementMethodTitle: 'Agreement method',
    };
    const [content, setContent] = useState(japaneseContent);
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        if (customization.language == 'japanese') {
            setContent(japaneseContent);
        } else if (customization.language == 'english') {
            setContent(englishContent);
        }
    }, [customization]);

    return content;
};

export { useLanguageTenantTop };
