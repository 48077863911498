import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Grid } from '@mui/material';

// project imports
import EarningCard from './EarningCard';
import PopularCard from './PopularCard';
import TotalOrderLineChartCard from './TotalOrderLineChartCard';
import TotalIncomeDarkCard from './TotalIncomeDarkCard';
import TotalIncomeLightCard from './TotalIncomeLightCard';
import TotalGrowthBarChart from './TotalGrowthBarChart';
import { gridSpacing } from 'store/constant';
import AgreementMethodCard from './AgreementMethodCard';
import RequestingInfoCard from './RequestingInfoCard';
import BasicInfoCard from './BasicInfoCard';
import QRElement from './QRElement';
import ReceiptElement from './ReceiptElement';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const ReceiptDashboard = () => {
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();
    const client = useSelector((state) => state.client);

    useEffect(() => {
        setLoading(false);
        if (client.client == null) {
            navigate('/top');
        }
    }, []);
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container direction="row" spacing={gridSpacing}>
                            <Grid item sm={4} xs={12} md={4} lg={4}>
                                <BasicInfoCard isLoading={isLoading} isEdit={false} />
                            </Grid>
                            <Grid item sm={4} xs={12} md={4} lg={4}>
                                <AgreementMethodCard isLoading={isLoading} isEdit={false} />
                            </Grid>
                            <Grid item sm={4} xs={12} md={4} lg={4}>
                                <RequestingInfoCard isLoading={isLoading} isEdit={false} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={8}>
                        <ReceiptElement isLoading={isLoading} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PopularCard isLoading={isLoading} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ReceiptDashboard;
