import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useLanguageCreateClient = () => {
    const japaneseContent = {
        next: '次へ',
        back: '戻る',
        nameTitle: 'まず名前を登録しましょう。',
        nameSubTitle: 'クライアント名は変更することができません。',
        nameEmptyLabel: 'クライアント名は必須です。',
        agreementMethodTitle: '情報提供アクションを設定しましょう。',
        agreementMethodSubTitle: '後から変更することも可能です。',
        agreementMethodFormLabel: '情報提供アクションを以下から',
        agreementMethodFormLabel2: '一つ以上選択してください。',
        agreementMethodEmptyLabel: '情報提供アクションが設定されていません。',
        requestingInfoTitle: 'ユーザから取得する情報を設定しましょう。',
        requestingInfoSubTitle: '後から変更することが可能です。',
        requestingInfoFormLabel: '取得する情報を複数選択可能',
        customInfoTitle: 'ユーザから取得するその他の情報を設定しましょう。',
        customInfoSubTitle: '前項の基本情報以外の、サービスに特化した情報カテゴリは手入力してください。',
        contentTitle: '情報提供文面の内容を設定しましょう。',
        contentSubTitle: 'ユーザへ情報提供を求める内容です。設定が必要無いケースもあります。後から変更することが可能です。',
        contentFormLabel: 'ユーザに表示する内容を作成しましょう。',
        contentTooLongError: '情報提供内容は４００文字以内で設定してください。',
        redirectUriTitle: 'リダイレクトURIを設定しましょう。',
        redirectUriSubTitle: '設定が必要無いケースもあります。後から変更することが可能です。',
        redirectUriSubTitle2: '複数設定する際はコンマ(,)で区切って記入してください。',
        confirmationTitle: '登録の確認をしましょう。',
        confirmationSubTitle: '内容を確認して送信しましょう。',
        submit: '送信',
        modify: '修正',
        summaryBasicInfoTitle: '基本情報',
        summaryAgreementMethodTitle: '情報提供の手法',
        summaryRequestingInfoTitle: 'ユーザへの要求情報',
        summaryCustomInfoTitle: 'ユーザから取得する追加情報',
        summaryContentTitle: '情報提供文面の内容',
        summaryRedirectUriTitle: 'リダイレクトURL',
        stepTitle1: 'クライアント名の登録',
        stepTitle2: '情報提供メソッドの選択',
        stepTitle3: '取得する情報カテゴリの選択',
        stepTitle4: '要求するその他の情報の設定',
        stepTitle5: '情報提供内容の設定',
        stepTitle6: 'リダイレクトURIの設定'
    };
    const englishContent = {
        next: 'Next',
        back: 'Back',
        nameTitle: 'Set client name first.',
        nameSubTitle: 'Client name cannot be changed later.',
        nameEmptyLabel: 'Client name must not be empty',
        agreementMethodTitle: 'Set agreement request settings.',
        agreementMethodSubTitle: 'You could chage them later.',
        agreementMethodFormLabel: 'Choose one or more agreement',
        agreementMethodFormLabel2: 'methods from below',
        agreementMethodEmptyLabel: 'No agreement set',
        requestingInfoTitle: 'Set requesting info from user upon agreement.',
        requestingInfoSubTitle: 'You could change them later.',
        requestingInfoFormLabel: 'Choose requesting info from below (none, one or more)',
        customInfoTitle: 'Set the customized requesting info',
        customInfoSubTitle: 'You can set your service specific infomation that you request from your customer below',
        contentTitle: 'Set agreement content here.',
        contentSubTitle: 'This content will be displayed to your user, you could modify this later.',
        contentFormLabel: 'Write your agreement content below.',
        contentTooLongError: 'Set content is too long, please set it under 200 words.',
        redirectUriTitle: 'Set your redirect Uri below',
        redirectUriSubTitle: 'Redirect Uri is used to redirect agreement confirmation to your web service, you can modify this later.',
        redirectUriSubTitle2: 'If you have more than one, please separate them with comma.',
        confirmationTitle: 'Confirmation',
        confirmationSubTitle: 'Please double check the registration and submit',
        submit: 'Submit',
        modify: 'Modify',
        summaryBasicInfoTitle: 'Basic Info',
        summaryAgreementMethodTitle: 'Agreement Methods',
        summaryRequestingInfoTitle: 'Requesting Info',
        summaryCustomInfoTitle: 'Custom Info Requests from user',
        summaryContentTitle: 'Agreement Content',
        summaryRedirectUriTitle: 'Redirect URL',
        stepTitle1: 'Client Name',
        stepTitle2: 'Agreement Method',
        stepTitle3: 'Requesting Info',
        stepTitle4: 'Additional Info Requirement',
        stepTitle5: 'Agreement Content',
        stepTitle6: 'Redirect Uri'
    };
    const [content, setContent] = useState(japaneseContent);
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        if (customization.language == 'japanese') {
            setContent(japaneseContent);
        } else if (customization.language == 'english') {
            setContent(englishContent);
        }
    }, [customization]);

    return content;
};

export { useLanguageCreateClient };
