import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useLanguageTop = () => {
    const japaneseContent = {
        title: 'こんにちは、',
        noTenantsYet: 'どのテナントにも所属していません。',
        subtitle: '所属テナントを選択してください。',
        footer: '新しくテナントをお作りになりますか？'
    };
    const englishContent = {
        title: 'Hi,',
        noTenantsYet: 'No tenants yet',
        subtitle: 'Choose tenant below',
        footer: 'Do you want to create new tenant?'
    };
    const [content, setContent] = useState(japaneseContent);
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        if (customization.language == 'japanese') {
            setContent(japaneseContent);
        } else if (customization.language == 'english') {
            setContent(englishContent);
        }
    }, [customization]);

    return content;
};

export { useLanguageTop };
