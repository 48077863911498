import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useLanguageReceiptDashboard = () => {
    const japaneseContent = {
        title: '情報提供を管理しましょう。',
        header: 'クリックすると詳細を見ることができます。',
        tableHeadUserId: 'ユーザID',
        tableHeadDate: '情報提供日時',
        tableHeadReferenceCode: 'リファレンス番号',
        tableHeadContent: '内容',
        receiptTableToday: '今日',
        receiptTableThisWeek: '今週',
        receiptTableThisMonth: '今月',
        searchTitle: 'ユーザIDの検索',
        closeModal: '閉じる',
        modalReceiptInfoLabel: '情報提供レシート詳細',
        modalReferenceIdLabel: 'リファレンス番号',
        modalCreatedAtLabel: '情報提供日時',
        modalClientIdLabel: 'クライアントID',
        modalUserIdLabel: 'ユーザーID',
        modalSignatureLabel: '署名情報',
        modalPhotoLabel: '顔写真情報'
    };
    const englishContent = {
        title: 'Manage all the agreements for you',
        header: 'Issued Receipts',
        tableHeadUserId: 'User ID',
        tableHeadDate: 'Date',
        tableHeadReferenceCode: 'Reference Code',
        tableHeadContent: 'Content',
        receiptTableToday: 'Today',
        receiptTableThisWeek: 'This week',
        receiptTableThisMonth: 'This month',
        searchTitle: 'Search User ID',
        closeModal: 'Close',
        modalReceiptInfoLabel: 'Receipt detail',
        modalReferenceIdLabel: 'Reference ID',
        modalCreatedAtLabel: 'Agreement at',
        modalClientIdLabel: 'Client ID',
        modalUserIdLabel: 'User ID',
        modalSignatureLabel: 'Signature',
        modalPhotoLabel: 'Facial Photo'
    };
    const [content, setContent] = useState(japaneseContent);
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        if (customization.language == 'japanese') {
            setContent(japaneseContent);
        } else if (customization.language == 'english') {
            setContent(englishContent);
        }
    }, [customization]);

    return content;
};

export { useLanguageReceiptDashboard };
