import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third party
import { Formik } from 'formik';
import { useLanguageCreateClient } from 'language/create_client';

const ClientNameForm = ({ basicsTemplate, basics, setBasics, isEditMode = false, isFixed = false, nameError, setNameError }) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const customization = useSelector((state) => state.customization);
    const [language, setLanguage] = useState(customization.language);
    const languageCreateClient = useLanguageCreateClient();

    const client = useSelector((state) => state.client);
    const [fixedClient, setFixedClient] = useState(client);

    useEffect(() => {
        setFixedClient(client);
    }, [client]);

    useEffect(() => {
        console.log(customization);
        setLanguage(customization.language);
    }, [customization])

    useEffect(() => {
        if (basics[0] == "") {
            setNameError(languageCreateClient.nameEmptyLabel);
        } else {
            setNameError('');
        }
    }, [basics]);

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ alignItems: 'center', display: 'flex' }}></Box>
                </Grid>
            </Grid>

            <Formik initialValues={basics}>
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={10} sm={10} md={10} ml={3}>
                                {(() => {
                                    return basicsTemplate.map((option) => {
                                        if (option.id == 0) {
                                            return (
                                                <TextField
                                                    fullWidth
                                                    label={language == 'japanese' ? option.nameJp : option.name}
                                                    margin="normal"
                                                    name={option.name}
                                                    type="text"
                                                    defaultValue=""
                                                    value={isFixed || isEditMode ? fixedClient.client.client_name : basics[option.id]}
                                                    error={nameError != ""}
                                                    helperText={nameError}
                                                    disabled={isFixed || isEditMode ? true : false}
                                                    //value={values.clientName}
                                                    // onChange={(e) => setClientName(e.target.value)}
                                                    onChange={(e) =>
                                                        setBasics((state) => {
                                                            return {
                                                                ...state,
                                                                [option.id]: e.target.value
                                                            };
                                                        })
                                                    }
                                                    sx={{ ...theme.typography.customInput }}
                                                />
                                            )
                                        } else if (option.id == 1) {
                                            return (
                                                <TextField
                                                    fullWidth
                                                    label={language == 'japanese' ? option.nameJp : option.name}
                                                    margin="normal"
                                                    name={option.name}
                                                    type="text"
                                                    defaultValue=""
                                                    value={isFixed ? fixedClient.client.client_uri : basics[option.id]}
                                                    disabled={isFixed ? true : false}
                                                    //value={values.clientName}
                                                    // onChange={(e) => setClientName(e.target.value)}
                                                    onChange={(e) =>
                                                        setBasics((state) => {
                                                            return {
                                                                ...state,
                                                                [option.id]: e.target.value
                                                            };
                                                        })
                                                    }
                                                    sx={{ ...theme.typography.customInput }}
                                                />
                                            )
                                        }
                                    })
                                })()}
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default ClientNameForm;
