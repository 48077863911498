import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useLanguageEditClient = () => {
    const japaneseContent = {
        currentSettingTitle: '変更前の設定',
        changedSettingTitle: '変更後の設定',
        buttonLabel: '送信',
        clientIdLabel: 'クライアントID',
        editAgreementMethodTitle: '情報提供アクションの編集',
        editRequestingInfoTitle: '要求情報カテゴリの編集',
        editContentTitle: '情報提供内容の編集',
        editCallbackUrlTitle: 'Callback URL の編集',
        editClientServiceUrlTitle: 'クライアントサービスURLの編集',
        editClientCustomInfoTitle: 'ユーザからの追加取得情報の編集',
    };
    const englishContent = {
        currentSettingTitle: 'Current setting',
        changedSettingTitle: 'Changed setting',
        buttonLabel: 'Confirm',
        clientIdLabel: 'Client ID',
        editAgreementMethodTitle: 'Edit agreement method',
        editRequestingInfoTitle: 'Edit requesting info',
        editContentTitle: 'Edit content',
        editCallbackUrlTitle: 'Edit Callback URL',
        editClientServiceUrlTitle: 'Edit client service url',
        editClientCustomInfoTitle: 'Edit custom request info',
    };
    const [content, setContent] = useState(japaneseContent);
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        if (customization.language == 'japanese') {
            setContent(japaneseContent);
        } else if (customization.language == 'english') {
            setContent(englishContent);
        }
    }, [customization]);

    return content;
};

export { useLanguageEditClient };
