import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, MenuItem, TextField, Typography, Button } from '@mui/material';

// third-party
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';

// project imports
import SkeletonTotalGrowthBarChart from 'ui-component/cards/Skeleton/TotalGrowthBarChart';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// chart data
import chartData from './chart-data/total-growth-bar-chart';

//qr
import { QRNormal } from 'react-qrbtf';

//print
import { useReactToPrint } from 'react-to-print';
import { useLanguageQRDashboard } from 'language/qrDashboard';

// ==============================|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //

const QRElement = ({ isLoading }) => {
    const componentRef = useRef();
    const client = useSelector((state) => state.client);
    const [value, setValue] = useState('today');
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);

    const { navType } = customization;
    const { primary } = theme.palette.text;
    const darkLight = theme.palette.dark.light;
    const grey200 = theme.palette.grey[200];
    const grey500 = theme.palette.grey[500];

    const primary200 = theme.palette.primary[200];
    const primaryDark = theme.palette.primary.dark;
    const secondaryMain = theme.palette.secondary.main;
    const secondaryLight = theme.palette.secondary.light;

    const [qrValue, setQrValue] = useState('');

    useEffect(() => {
        if (client == null || client.client == null) {
            return;
        } else {
            var tmp = process.env.REACT_APP_DYNAMIC_LINKS_AGREEMENT;
            tmp = tmp + `?client_name=${client.client.client_name}`;
            tmp = tmp + `&client_id=${client.client.client_id}`;
            tmp = tmp + `&query_id=${client.client.id}`;
            tmp = tmp + `&uid=${crypto.randomUUID()} `;
            console.log(tmp);
            // tmp = tmp + `&content=${client.client.content.agreement}`;
            // const agreement_method = encodeURIComponent(JSON.stringify(client.client.agreement_method));
            // tmp = tmp + `&agreement_method=${agreement_method}`;
            // const requesting_info = encodeURIComponent(JSON.stringify(client.client.requesting_info));
            // tmp = tmp + `&requesting_info=${requesting_info}`;
            setQrValue(tmp);
        }
    }, [client]);

    useEffect(() => {
        const newChartData = {
            ...chartData.options,
            colors: [primary200, primaryDark, secondaryMain, secondaryLight],
            xaxis: {
                labels: {
                    style: {
                        colors: [primary, primary, primary, primary, primary, primary, primary, primary, primary, primary, primary, primary]
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: [primary]
                    }
                }
            },
            grid: {
                borderColor: grey200
            },
            tooltip: {
                theme: 'light'
            },
            legend: {
                labels: {
                    colors: grey500
                }
            }
        };

        // do not load chart when loading
        if (!isLoading) {
            ApexCharts.exec(`bar-chart`, 'updateOptions', newChartData);
        }
    }, [navType, primary200, primaryDark, secondaryMain, secondaryLight, primary, darkLight, grey200, isLoading, grey500]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const languageQRDashboard = useLanguageQRDashboard();

    return (
        <>
            {isLoading ? (
                <SkeletonTotalGrowthBarChart />
            ) : (
                <MainCard>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="h3">{languageQRDashboard.title}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle2">{languageQRDashboard.subtitle}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container justifyContent="center" alignItems="center">
                                        <Grid item>
                                            <Button variant="outlined" onClick={handlePrint}>
                                                Print
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row" justifyContent="center">
                                <Grid item xs={4} pl={4} pt={4}>
                                    <Grid container direction="column" justifyContent="center">
                                        <Grid item>
                                            <Grid container direction="column" justifyContent="center">
                                                <Grid item>
                                                    <Typography variant="subtitle2">{languageQRDashboard.step1Title}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h4">{languageQRDashboard.step1Detail}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item mt={1}>
                                            <Grid container direction="column" justifyContent="center">
                                                <Grid item>
                                                    <Typography variant="subtitle2">{languageQRDashboard.step2Title}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h4">{languageQRDashboard.step2Detail}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item mt={1}>
                                            <Grid container direction="column" justifyContent="center">
                                                <Grid item>
                                                    <Typography variant="subtitle2">{languageQRDashboard.step3Title}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h4">{languageQRDashboard.step3Detail}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item mt={1}>
                                            <Grid container direction="column" justifyContent="center">
                                                <Grid item>
                                                    <Typography variant="subtitle2">{languageQRDashboard.step4Title}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h4">{languageQRDashboard.step4Detail}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} ref={componentRef}>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item mt={-4}>
                                            <Typography fontSize={9} variant="subtitle2">{qrValue}</Typography>
                                        </Grid>
                                        <Grid item xs={4} mt={-2}>
                                            <QRNormal value={qrValue} size={84} posColor={'#000000'} otherColor={'#000000'} />
                                        </Grid>
                                        <Grid item mt={-4}>
                                            <Typography variant="subtitle2">Powered by AUTHBLUE</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MainCard>
            )}
        </>
    );
};

QRElement.propTypes = {
    isLoading: PropTypes.bool
};

export default QRElement;
