// react
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Typography, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

// project imports
import Customization from '../Customization';

// ==============================|| MINIMAL LAYOUT ||============================== //
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    height: 400,
    bgcolor: 'background.paper',
    borderRadius: '4%',
    border: 'none',
    boxShadow: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    p: 4
};

const MinimalLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const load = useSelector((state) => state.load);

    useEffect(() => {
        if (load.isLoading) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [load]);

    return (
        <>
            <Outlet />
            <Customization />
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500
                    }
                }}
            >
                <Fade in={open}>
                    <Box sx={modalStyle}>
                        <Typography variant="subtitle" sx={{ color: theme.palette.primary.main, fontWeight: 'medium', mb: 2 }}>
                            {load.message}
                        </Typography>
                        <CircularProgress />
                    </Box>
                </Fade>
            </Modal>
        </>

    )
};

export default MinimalLayout;
