// action - state management
import * as actionTypes from './actions';

export const initialState = {
    uuid: '',
    email: '',
    name: '',
    notifications: []
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_UUID:
            return {
                ...state,
                uuid: action.uuid
            };
        case actionTypes.SET_EMAIL:
            return {
                ...state,
                email: action.email
            };
        case actionTypes.SET_NAME:
            return {
                ...state,
                name: action.name
            };
        case actionTypes.SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.notifications
            };
        case actionTypes.SET_USER_RESET:
            return initialState;
        default:
            return state;
    }
};

export default userReducer;
