import { lazy } from 'react';

// project imports
import CreateClientLayout from 'layout/CreateClientLayout';
import CreateClient from 'views/create-client';
import CreateTenant from 'views/create-tenant';

// ==============================|| MAIN ROUTING ||============================== //

const CreateClientRoutes = {
    path: '/',
    element: <CreateClientLayout />,
    children: [
        {
            path: 'create/client',
            element: <CreateClient />
        },
        {
            path: 'create/tenant',
            element: <CreateTenant />
        }
    ]
};

export default CreateClientRoutes;
