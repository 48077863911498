import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useLanguageHeader = () => {
    const japaneseContent = {
        tenantLabel: 'テナント名',
        planLabel: 'プラン',
        memberLabel: 'メンバー',
        tenantManagement: 'テナントの管理',
        needToUpgradeYourPlanToInviteMember: 'メンバーの追加にはプランのアップグレードが必要です。',
        inviteMember: 'メンバーの招待',
        inviteMemberInputLabel: 'ユーザのメールアドレス',
        deleteTenant: 'テナントの削除',
        onlyOwnerCanDeleteTenant: 'テナントのオーナーのみ削除できます',
        planTableShowTitle: 'プランの変更',
        billingManagement: '請求情報管理',
        onlyOwnerCanSeeBilling: 'テナントのオーナーのみ請求を管理できます',
        onlyOwnerCanChangePlan: 'テナントのオーナーのみプランを変更できます',
        deletingTenant: 'テナントの削除',
        deletingTenantInputLabel: "テナント名",
        deletingTenantDetail1: 'テナント名 ',
        deletingTenantDetail2: 'のテナントを削除しようとしています。テナント情報は完全に消去されます。よろしいですか？',
        deletingTenantDetail3: '実行するためにテナント名 ',
        deletingTenantDetail4: ' を入力し確認してください。',
        deletingTenantConfirmation1: '下の送信ボタンを押して実行してください。',
        deletingTenantConfirmation2: 'テナント名を入力してください。',
        inviteMemberSubmit: '送信',
        removeUserItemText: 'ユーザをテナントから削除しています。',
        removeUserConfirmation: 'このユーザをテナントから削除します。よろしいですか？',
        invitationTitle: 'テナントにメンバーを追加しましょう。',
        invitationSubTitle: '追加したいメンバーのメールアドレスを入力します。',
        clientLabel: 'クライアント',
        removeClientLabel: 'ユーザの削除'
    };
    const englishContent = {
        tenantLabel: 'Tenant',
        planLabel: 'Plan',
        memberLabel: 'Member',
        tenantManagement: 'Manage tenant',
        needToUpgradeYourPlanToInviteMember: 'You need to upgrade your plan to add member.',
        inviteMember: 'Invite member',
        inviteMemberInputLabel: 'Email',
        deleteTenant: 'Delete tenant',
        deletingTenantInputLabel: "Tenant name",
        onlyOwnerCanDeleteTenant: 'Only owner is allowed to delete tenant.',
        planTableShowTitle: 'Upgrade Plan',
        billingManagement: 'Billing management',
        onlyOwnerCanSeeBilling: 'Only owner is allowed to see the billing',
        onlyOwnerCanChangePlan: 'Only owner is allowed to change plan',
        deletingTenant: 'Deleting Tenant',
        deletingTenantDetail1: 'You are deleting tenant',
        deletingTenantDetail2: 'This process is not retrievable. Are you sure?',
        deletingTenantDetail3: 'To proceed, please type tenant name',
        deletingTenantDetail4: ' below to confim.',
        deletingTenantConfirmation1: 'To proceed, click OK below',
        deletingTenantConfirmation2: 'Please type tenant name to confirm deletion',
        inviteMemberSubmit: 'Submit',
        removeUserItemText: 'Removing user from tenant',
        removeUserConfirmation: 'You are removing user from tenant. Are you sure?',
        invitationTitle: 'Add member to your tenant',
        invitationSubTitle: 'Set email for inviting user',
        removeClientLabel: 'Remove'
    };
    const [content, setContent] = useState(japaneseContent);
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        if (customization.language == 'japanese') {
            setContent(japaneseContent);
        } else if (customization.language == 'english') {
            setContent(englishContent);
        }
    }, [customization]);

    return content;
};

export { useLanguageHeader };
