import FaceIcon from '@mui/icons-material/Face';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import BadgeIcon from '@mui/icons-material/Badge';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

import WcIcon from '@mui/icons-material/Wc';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CakeIcon from '@mui/icons-material/Cake';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import MyLocationIcon from '@mui/icons-material/MyLocation';

import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

const basicsTemplate = [
    { id: 0, name: 'Client Name', nameJp: 'クライアント名' },
    { id: 1, name: 'Client Service URL', nameJp: 'クライアントサービスURL' }
];

const redirectUriTemplate = [
    // { id: 0, name: 'Redirect Uri for Signup', nameJp: 'サインアップ時のリダイレクトURL' },
    { id: 0, name: 'Redirect Uri for Agreement', nameJp: '情報提供時のリダイレクトURL' }
];

const agreementMethodTemplate = [
    { id: 0, id_str: 'face_id', name: 'FaceID', nameJp: '顔認証', icon: <FaceIcon fontSize="inherit" /> },
    // { id: 1, id_str: 'finger_print', name: 'Finger Print', nameJp: '指紋認証', icon: <FingerprintIcon fontSize="inherit" /> },
    { id: 1, id_str: 'my_number_card', name: 'My Number Card', nameJp: 'マイナンバー認証', icon: <BadgeIcon fontSize="inherit" /> },
    { id: 2, id_str: 'signature', name: 'Signature', nameJp: '署名', icon: <DriveFileRenameOutlineIcon fontSize="inherit" /> }
];

const requestingInfoTemplate = [
    { id: 0, id_str: 'name', name: 'Name', nameJp: '氏名', icon: <BadgeIcon fontSize="inherit" /> },
    { id: 1, id_str: 'sex', name: 'Sex', nameJp: '性別', icon: <WcIcon fontSize="inherit" /> },
    { id: 2, id_str: 'address', name: 'Address', nameJp: '住所', icon: <LocationOnIcon fontSize="inherit" /> },
    { id: 3, id_str: 'birthday', name: 'Birthday', nameJp: '生年月日', icon: <CakeIcon fontSize="inherit" /> },
    { id: 4, id_str: 'age', name: 'Age', nameJp: '年齢', icon: <CakeIcon fontSize="inherit" /> },
    { id: 5, id_str: 'phone', name: 'Phone', nameJp: '電話番号', icon: <PhoneIcon fontSize="inherit" /> },
    { id: 6, id_str: 'email', name: 'Email', nameJp: 'メールアドレス', icon: <EmailIcon fontSize="inherit" /> },
    { id: 7, id_str: 'picture', name: 'Facial Picture', nameJp: '顔写真', icon: <PhotoCameraIcon fontSize="inherit" /> },
    { id: 8, id_str: 'driver_license', name: 'Driver License Info', nameJp: '運転免許証のデータ', icon: <FeaturedPlayListIcon fontSize="inherit" /> },
    { id: 9, id_str: 'location', name: 'Location Info', nameJp: '位置情報', icon: <MyLocationIcon fontSize="inherit" /> },
];

const customInfoTemplate = [
    { id: 0, name: 'Info Title', nameJp: '対象となる情報　' },
    { id: 1, name: 'Info Description', nameJp: '詳細' }
];

const contentTemplate = [{ id: 0, id_str: 'agreement', name: 'Agreement Content', nameJp: '情報提供内容' }];

const BASICS_STATE = { 0: '' };

const AGREEMENT_METHODS_STATE = {
    0: false,
    1: false,
    2: false,
    // 3: false
};

const REQUESTING_INFO_STATE = {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
};

const CUSTOM_INFO_STATE = { 0: '', 1: '' };

const REDIRECT_URI_STATE = { 0: '' };

const CONTENT_STATE = { 0: '' };

const getAgreementMethodByIdStr = (id_str) => {
    for (let i = 0; i < agreementMethodTemplate.length; i++) {
        const template = agreementMethodTemplate[i];
        if (template['id_str'] == id_str) {
            return template;
        }
    }
};

const getRequestingInfoByIdStr = (id_str) => {
    for (let i = 0; i < requestingInfoTemplate.length; i++) {
        const template = requestingInfoTemplate[i];
        if (template['id_str'] == id_str) {
            return template;
        }
    }
};

const getLabel = (theme, element, language) => {
    var name = element.name;
    if (language == 'japanese') {
        name = element.nameJp;
    }
    const icon = element.icon;
    return (
        <Grid container direction="row" alignItems="center">
            <Grid item sx={{ mb: 1.25, ml: 1.25 }}>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.grey[700],
                        zIndex: 1
                    }}
                    aria-controls="menu-earning-card"
                    aria-haspopup="true"
                >
                    {icon}
                </Avatar>
            </Grid>
            <Grid item sx={{ mb: 1.25, ml: 1.25 }}>
                <Typography variant="subtitle1">{name}</Typography>
            </Grid>
        </Grid>
    );
};

const keyToJapaneseDict = {
    "personal_driver_license_number": "免許証の番号",
    "personal_driver_license_issued_date": "免許の年月日",
    "personal_driver_license_expired_date": "有効期間の末日",
    "personal_driver_license_color": "免許証の色区分",
    "personal_driver_license_condition1": "免許の条件1",
    "personal_driver_license_condition2": "免許の条件2",
    "personal_driver_license_koan_name": "公安委員会名",
    "personal_driver_license_honseki": "本籍",
    "personal_driver_license_type_date1": "二・小・原",
    "personal_driver_license_type_date2": "他",
    "personal_driver_license_type_date3": "二種",
    "personal_driver_license_type_date4": "大型",
    "personal_driver_license_type_date5": "普通",
    "personal_driver_license_type_date6": "大特",
    "personal_driver_license_type_date7": "大自二",
    "personal_driver_license_type_date8": "普自二",
    "personal_driver_license_type_date9": "小特",
    "personal_driver_license_type_date10": "原付",
    "personal_driver_license_type_date11": "け引",
    "personal_driver_license_type_date12": "大二",
    "personal_driver_license_type_date13": "普二",
    "personal_driver_license_type_date14": "大特二",
    "personal_driver_license_type_date15": "け引二",
    "personal_driver_license_type_date16": "中型",
    "personal_driver_license_type_date17": "中二",
    "personal_driver_license_type_date18": "準中型",
    "latitude": "緯度",
    "longitude": "経度"
}

const getLabelWithData = (theme, option, data, language, isContentDict = false) => {
    var name = option.name;
    if (language == 'japanese') {
        name = option.nameJp;
    }
    const icon = option.icon;

    if (isContentDict) {
        return (
            <Grid container direction="column">
                <Grid item>
                    <Grid container direction="row" alignItems="center">
                        <Grid item sx={{ mb: 1.25, ml: 1.25 }}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    backgroundColor: theme.palette.primary.light,
                                    color: theme.palette.grey[700],
                                    zIndex: 1
                                }}
                                aria-controls="menu-earning-card"
                                aria-haspopup="true"
                            >
                                {icon}
                            </Avatar>
                        </Grid>
                        <Grid item sx={{ mb: 1.25, ml: 1.25 }}>
                            <Typography variant="subtitle2">{name}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    {

                        Object.keys(data).map((el_key) => {
                            if (data[el_key] != null) {
                                return (
                                    <Grid container="row">

                                        <Grid item sx={{ mb: 1.25, ml: 1.5 }}>
                                            <Typography variant="subtitle1">{keyToJapaneseDict[el_key]}</Typography>
                                        </Grid>

                                        <Grid item sx={{ mb: 1.25, ml: 1.5 }}>
                                            <Typography variant="subtitle1">{data[el_key]}</Typography>
                                        </Grid>
                                    </Grid>


                                )
                            }

                        })
                    }

                </Grid>

            </Grid>
        );

    } else {
        return (
            <Grid container direction="row" alignItems="center">
                <Grid item sx={{ mb: 1.25, ml: 1.25 }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.grey[700],
                            zIndex: 1
                        }}
                        aria-controls="menu-earning-card"
                        aria-haspopup="true"
                    >
                        {icon}
                    </Avatar>
                </Grid>
                <Grid item sx={{ mb: 1.25, ml: 1.25 }}>
                    <Typography variant="subtitle2">{name}</Typography>
                </Grid>
                <Grid item sx={{ mb: 1.25, ml: 1.5 }}>
                    <Typography variant="subtitle1">{data}</Typography>
                </Grid>
            </Grid>
        );

    }
};

const getLabelCenter = (theme, option, language) => {
    var name = option.name;
    if (language == 'japanese') {
        name = option.nameJp;
    }
    const icon = option.icon;
    return (
        <Grid container direction="row" alignItems="center" justifyContent="center">
            <Grid item sx={{ mb: 1.25, ml: 1.25 }}>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.grey[700],
                        zIndex: 1
                    }}
                    aria-controls="menu-earning-card"
                    aria-haspopup="true"
                >
                    {icon}
                </Avatar>
            </Grid>
            <Grid item sx={{ mb: 1.25, ml: 1.25 }}>
                <Typography variant="subtitle1">{name}</Typography>
            </Grid>
        </Grid>
    );
};

const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const sumList = (xs) => {
    let sum = xs.reduce((a, b) => {
        return a + b;
    });
    return sum;
};

export {
    basicsTemplate,
    redirectUriTemplate,
    agreementMethodTemplate,
    requestingInfoTemplate,
    contentTemplate,
    customInfoTemplate,
    getAgreementMethodByIdStr,
    getRequestingInfoByIdStr,
    getLabel,
    getLabelWithData,
    getLabelCenter,
    BASICS_STATE,
    AGREEMENT_METHODS_STATE,
    REQUESTING_INFO_STATE,
    REDIRECT_URI_STATE,
    CONTENT_STATE,
    CUSTOM_INFO_STATE,
    capitalizeFirstLetter,
    sumList
};
